import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { DEFAULT_LANG, ALLOWED_LANGS } from '../constants/SiteConstants';

const debug = false;
const saveMissing = process.env.NODE_ENV === 'development';

const options = {
  debug: debug,
  // backend: {
  //   loadPath: '/locales/{{lng}}/{{ns}}.json',
  //   addPath: '/locales/{{lng}}/{{ns}}.missing.json'
  // },
  // lng: DEFAULT_LANG,
  preload: [DEFAULT_LANG],
  fallbackLng: DEFAULT_LANG,
  whitelist: ALLOWED_LANGS,
  load: 'languageOnly',
  ns: ['translations'],
  defaultNS: 'translations',
  saveMissing: saveMissing,
  detection: {
    // order and from where user language should be detected
    order: [/*'querystring', /*'localStorage', */ 'path' /*, 'cookie'*/],
    // keys or params to lookup language from
    lookupQuerystring: 'lang',
    lookupCookie: 'lang',
    lookupLocalStorage: 'lang',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
    // optional expire and domain for set cookie
    // cookieMinutes: 10,
    // cookieDomain: 'myDomain',
    // optional htmlTag with lang attribute, the default is:
    // htmlTag: document.documentElement
  },
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format, lng) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    }
  },
  wait: process && !process.release,
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  }
};

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release) {
  i18n
    .use(XHR)
    .use(initReactI18next);
    // .use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
