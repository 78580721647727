const filterFilterTable = {
  sk: {
    brand: 'znacka',
    storeLocation: 'predajna',
    priceRange: 'cena',
    availability: {
      label: 'dostupnost',
      values: {
        'true': 'skladom'
      }
    }
  },
  cz: {
    brand: 'vyrobce',
    storeLocation: 'prodejna',
    priceRange: 'cena',
    availability: {
      label: 'dostupnost',
      values: {
        'true': 'skladem'
      }
    }
  }
};

const defaultRoutes = ( layout = '_default' ) => {
  return {
    home: {
      name: 'home',
      paths: { sk: '/', en: '/en', cz: '/cz' },
      exact: true,
      cache: { route: true, markup: true },
      page: 'home/_default/index',
      layout: layout
    },
    category: {
      name: 'category',
      paths: {
        sk: '/kategoria/:id/:filters(.*)?',
        en: '/en/categories/:id/:filters(.*)?',
        cz: '/cz/kategorie/:id/:filters(.*)?'
      },
      exact: true,
      cache: { route: true, markup: true },
      filterTable: filterFilterTable,
      page: 'category/_default',
      layout: layout
    },
    product: {
      name: 'product',
      paths: {
        sk: '/produkt/:id',
        en: '/en/product/:id',
        cz: '/cz/produkt/:id'
      },
      exact: true,
      cache: { route: true, markup: true },
      page: 'product/_default',
      layout: layout
    },
    search: {
      name: 'search',
      paths: {
        sk: '/vyhladavanie/:searchPhrase/:filters(.*)?',
        en: '/en/search/:searchPhrase/:filters(.*)?',
        cz: '/cz/vyhladavani/:searchPhrase/:filters(.*)?'
      },
      exact: true,
      cache: { route: true, markup: true },
      filterTable: filterFilterTable,
      page: 'category/_default',
      layout: layout
    },
    blogDetail: {
      name: 'blog-detail',
      paths: {
        sk: '/blog/:id',
        en: '/en/blog/:id',
        cz: '/cz/blog/:id'
      },
      exact: true,
      cache: { route: true, markup: true },
      page: 'blog/_detail',
      layout: layout
    },
    blog: {
      name: 'blog',
      paths: {
        sk: '/blog',
        en: '/en/blog',
        cz: '/cz/blog'
      },
      exact: true,
      cache: { route: true, markup: true },
      page: 'blog/_default',
      layout: layout
    },
    static: {
      name: 'static',
      paths: {
        sk: '/stranka/:urlTitle',
        en: '/en/page/:urlTitle',
        cz: '/cz/stranka/:urlTitle'
      },
      exact: true,
      cache: { route: true, markup: true },
      page: 'Static',
      layout: layout
    },
    cart: {
      name: 'cart',
      paths: {
        sk: '/kosik',
        en: '/en/cart',
        cz: '/cz/kosik'
      },
      exact: true,
      page: 'cart/_default',
      layout: layout
    },
    checkout: {
      name: 'checkout',
      paths: {
        sk: '/objednavka',
        en: '/en/checkout',
        cz: '/cz/objednavka'
      },
      exact: true,
      page: 'Checkout',
      layout: layout
    },
    thanks: {
      name: 'thanks',
      paths: {
        sk: '/dakujeme',
        en: '/en/thankyou',
        cz: '/cz/dekujeme',
      },
      exact: true,
      cache: { route: false, markup: false },
      page: 'thanks/_default',
      layout: layout
    },
    // user profile
    profile: {
      name: 'profile',
      paths: {
        sk: '/ucet',
        en: '/en/profile',
        cz: '/cz/profil'
      },
      exact: true,
      page: 'profile/_default',
      layout: layout
    },
    passwordForgot: {
      name: 'password-forgot',
      paths: {
        sk: '/zabudnute-heslo/:hashId?',
        en: '/en/password-forgot/:hashId?',
        cz: '/cz/zapomenuty-heslo/:hashId?'
      },
      exact: true,
      page: 'PasswordForgot',
      layout: layout
    },
    oauthLogin: {
      name: 'oauth-login',
      paths: {
        sk: '/oauth-prihlasenie',
        en: '/en/oauth-auth',
        cz: '/cz/oauth-prihlaseni'
      },
      exact: true,
      page: 'OAuthLogin',
      layout: layout
    },
    login: {
      name: 'login',
      paths: {
        sk: '/prihlasenie',
        en: '/en/login',
        cz: '/cz/prihlaseni'
      },
      exact: true,
      page: 'login/_default',
      layout: layout
    },
    wishlist: {
      name: 'wishlist',
      paths: {
        sk: '/oblubene',
        en: '/en/wishlist',
        cz: '/cz/oblibene'
      },
      exact: true,
      page: 'wishlist/_default',
      layout: layout
    },
    compare: {
      name: 'compare',
      paths: {
        sk: '/porovnanie',
        en: '/en/compare',
        cz: '/cz/srovnani'
      },
      exact: true,
      cache: { route: false, markup: false },
      page: 'compare/_default',
      layout: layout
    },
    // B2B routes
    orders: {
      name: 'orders',
      paths: {
        sk: '/objednavky',
        en: '/en/orders',
        cz: '/cz/objednavky'
      },
      exact: true,
      page: 'b2b/_default/order/listing',
      layout: layout
    },
    order: {
      name: 'order',
      paths: {
        sk: '/objednavka-detail/:id',
        en: '/en/order/:id',
        cz: '/cz/objednavka-detail/:id'
      },
      exact: true,
      page: 'b2b/_default/order/detail',
      layout: layout
    },
    invoices: {
      name: 'invoices',
      paths: {
        sk: '/faktury',
        en: '/en/invoices',
        cz: '/cz/faktury'
      },
      exact: true,
      page: 'b2b/_default/invoice/listing',
      layout: layout
    },
    invoice: {
      name: 'invoice',
      paths: {
        sk: '/faktura/:id',
        en: '/en/invoice/:id',
        cz: '/cz/faktura/:id'
      },
      exact: true,
      page: 'b2b/_default/invoice/detail',
      layout: layout
    },
    deliveryNotes: {
      name: 'delivery-notes',
      paths: {
        sk: '/dodacie-listy',
        en: '/en/delivery-notes',
        cz: '/cz/dodaci-listy'
      },
      exact: true,
      page: 'b2b/_default/deliveryNotes/listing',
      layout: layout
    },
    error404: {
      priority: 999,
      name: 'error404',
      paths: {
        sk: '(.*)?',
        en: '(.*)?',
        cz: '(.*)?'
      },
      exact: true,
      cache: { route: false, markup: false },
      responseCode: 404,
      page: '404',
      layout: layout
    }
  };
}

export default defaultRoutes;
