import _ from 'lodash';

export const cartInteraction = (cart, onOrderPage = false) => {
  if ((typeof _yottlyOnload) === 'undefined') {
    return;
  }
  let cartItems = [];
  if (!_.isEmpty(cart)) {
    cartItems = _.map(_.get(cart, 'items', {}), (item) => {
      return {
        'productId': _.get(item, 'product.id'),
        'amount': _.get(item, 'count')
      }
    })
  }
  try {
    _yottlyOnload.push(function () {
      diffAnalytics.cartInteraction({
        content: cartItems,
        onOrderPage: onOrderPage
      })
    })
  } catch(err) {}
}

export const order = (order) => {
  if ((typeof _yottlyOnload) === 'undefined') {
    return;
  }
  const orderItems = _.map(_.get(order, 'orderItems', {}), (item) => {
    return {
      'productId': _.get(item, 'productId'),
      'price': _.get(item, 'totalPriceData.price')
    }
  })
  try {
    _yottlyOnload.push(function () {
      diffAnalytics.order({ content: orderItems });
    })
  } catch(e) {}
}
