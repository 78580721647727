const filterFilterTable = {
  sk: {
    brand: 'znacka',
    storeLocation: 'predajna',
    priceRange: 'cena'
  }
};

const mobilOnlineRoutes = {
  home:{
    name: 'home',
    paths: { sk: '/', en: '/en' },
    exact: true,
    cache: { route: true, markup: true },
    page: 'home/mobilonline',
    layout: 'mobilonline'
  },
  login:{
    name: 'login',
    paths: { sk: '/prihlasenie', en: '/en/login' },
    exact: true,
    page: 'login/mobilonline',
    layout: 'mobilonline'
  },
  profile:{
    name: 'profile',
    paths: { sk: '/ucet/:tab([a-z-_]*)?', en: '/en/profile/:tab([a-z-_]*)?' },
    exact: true,
    page: 'profile/mobilonline',
    layout: 'mobilonline'
  },
  passwordForgot:{
    name: 'password-forgot',
    paths: {
      sk: '/zabudnute-heslo/:hashId?',
      en: '/en/password-forgot/:hashId?'
    },
    exact: true,
    page: 'PasswordForgot',
    layout: 'mobilonline'
  },
  category:{
    name: 'category',
    paths: {
      sk: '/kategoria/:id/:filters(.*)?',
      en: '/en/categories/:id/:filters(.*)?'
    },
    exact: true,
    cache: { route: true, markup: true },
    filterTable: filterFilterTable,
    page: 'category/mobilonline',
    layout: 'mobilonline'
  },
  search:{
    name: 'search',
    paths: {
      sk: '/vyhladavanie/:searchPhrase/:filters(.*)?',
      en: '/en/search/:searchPhrase/:filters(.*)?'
    },
    exact: true,
    cache: { route: true, markup: true },
    filterTable: filterFilterTable,
    page: 'category/mobilonline',
    layout: 'mobilonline'
  },
  product:{
    name: 'product',
    paths: { sk: '/produkt/:id', en: '/en/product/:id' },
    exact: true,
    cache: { route: true, markup: true },
    page: 'product/mobilonline',
    layout: 'mobilonline'
  },
  wishlist:{
    name: 'wishlist',
    paths: { sk: '/oblubene', en: '/en/wishlist' },
    exact: true,
    page: 'wishlist/mobilonline',
    layout: 'mobilonline'
  },
  compare:{
    name: 'compare',
    paths: { sk: '/porovnanie', en: '/en/compare' },
    exact: true,
    cache: { route: false, markup: false },
    page: 'compare/mobilonline',
    layout: 'mobilonline'
  },
  operationUnits: {
    name: 'operationUnits',
    paths: { sk: '/predajne', en: '/en/stores' },
    exact: true,
    cache: { route: true, markup: true },
    page: 'Stores',
    layout: 'mobilonline'
  },
  operationUnit: {
    name: 'operationUnit',
    paths: { sk: '/predajna/:urlName', en: '/en/store/:urlName' },
    exact: true,
    cache: { route: true, markup: true },
    page: 'Store',
    layout: 'mobilonline'
  },

  // B2B routes
  orders:{
    name: 'orders',
    paths: { sk: '/objednavky', en: '/en/orders' },
    exact: true,
    page: 'b2b/order/listing',
    layout: 'mobilonline'
  },
  order:{
    name: 'order',
    paths: { sk: '/objednavka-detail/:id', en: '/en/order/:id' },
    exact: true,
    page: 'b2b/order/detail',
    layout: 'mobilonline'
  },
  invoices:{
    name: 'invoices',
    paths: { sk: '/faktury', en: '/en/invoices' },
    exact: true,
    page: 'b2b/invoice/listing',
    layout: 'mobilonline'
  },
  invoice:{
    name: 'invoice',
    paths: { sk: '/faktura/:id', en: '/en/invoice/:id' },
    exact: true,
    page: 'b2b/invoice/detail',
    layout: 'mobilonline'
  },
  cart:{
    name: 'cart',
    paths: { sk: '/kosik', en: '/en/cart' },
    exact: true,
    page: 'cart/mobilonline',
    layout: 'mobilonline'
  },
  checkoutContact:{
    name: 'checkoutContact',
    paths: { sk: '/objednavka/kontakt', en: '/en/checkout/contact' },
    exact: true,
    page: 'checkoutSteps/Contact',
    layout: 'mobilonline'
  },
  checkoutShipping:{
    name: 'checkoutShipping',
    paths: { sk: '/objednavka/dodanie', en: '/en/checkout/shipping' },
    exact: true,
    page: 'checkoutSteps/Shipping',
    layout: 'mobilonline'
  },
  thanks:{
    name: 'thanks',
    paths: { sk: '/dakujeme', en: '/en/thankyou' },
    exact: true,
    cache: { route: false, markup: false },
    page: 'thanks/mobilonline',
    layout: 'mobilonline'
  },
  omnichannel:{
    name: 'omnichannel',
    paths: { sk: '/omnichannel', en: '/en/omnichannel' },
    exact: true,
    cache: { route: false, markup: false },
    page: 'omnichannel/mobilonline',
    layout: '_default'
  },
  omnichannelHeight:{
    name: 'omnichannelHeight',
    paths: { sk: '/omnichannelNaVysku', en: '/en/omnichannelHeight' },
    exact: true,
    cache: { route: false, markup: false },
    page: 'omnichannel/mobilonline/height',
    layout: '_default'
  }
};

export default mobilOnlineRoutes;
