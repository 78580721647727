import _ from 'lodash';
import Cookies from 'js-cookie';
import TagManager from 'react-gtm-module';

import { COOKIE_THEME } from 'common/constants/SiteConstants';
import { CHUNK_SIZE } from 'common/constants/GTMConstants';

const tryRequireGTMForTheme = (theme) => {
  try {
    return require(`common/utils/gtm/${theme}`);
  } catch (err) {
    return false;
  }
};

const getGTM = () => {
  const activeTheme = Cookies.get(COOKIE_THEME);
  return import(`./_default`).then((defaultModule) => {
    const themeGTM = tryRequireGTMForTheme(activeTheme);
    if (activeTheme !== 'default' && themeGTM) {
      return themeGTM;
    }
    return defaultModule;
  });
};

export const fireGTMEvent = (
  event = null,
  data = {},
  list = null,
  additionalData = {}
) => {
  if (!event) {
    return null;
  }
  getGTM().then(gtm => {
    const dataToProcess = _.chunk(data, CHUNK_SIZE);
    if (!_.isEmpty(data)) {
      _.each(dataToProcess, (chunk, chunkIndex) => {
        const pushData = gtm.preparePushData(
          event,
          chunk,
          list,
          additionalData,
          chunkIndex
        );
        if (!_.isEmpty(pushData)) {
          TagManager.dataLayer({
            dataLayer: pushData
          });
        }
      });
    } else {
      const pushData = gtm.preparePushData(event, {}, list, additionalData);
      TagManager.dataLayer({
        dataLayer: pushData
      });
    }
  });
};

export const getClientIdFromCookie = () => {
  let clientId = '0000000.0000000';
  const gacookie = Cookies.get('_ga');
  if (gacookie) {
    const gacookieParts = _.drop(_.split(gacookie, '.'), 2);
    clientId = _.join(gacookieParts, '.');
  }
  return clientId;
};

export const getGTMCategoryList = (
  categoryData = {},
  gotActiveFilters = false
) => {
  let list = gotActiveFilters ? 'CategoryFilter' : 'Category';
  if (_.get(categoryData, 'search', '')) {
    list = 'Search';
  }
  return list;
};
