import React from 'react';
import { toastr } from 'react-redux-toastr';
import { capfl } from 'common/utils/index';
import i18n from 'common/i18n';

import Icon from 'common/components/styleguide/Icon';
import {
  ICON_NOTIF_OK,
  ICON_NOTIF_NOK,
  ICON_NOTIF_WARNING,
  ICON_LOADING
} from 'common/constants/IconConstants';

export const message = {
  success: (title, message, options) => {
    const newOptions = Object.assign(
      {},
      { icon: <Icon size={'2.5em'} className={ICON_NOTIF_OK} /> },
      options
    );
    toastr.success(capfl(i18n.t(title)), capfl(i18n.t(message)), newOptions);
  },
  error: (title, message, options) => {
    const newOptions = Object.assign(
      {},
      { icon: <Icon size={'2.5em'} className={ICON_NOTIF_NOK} /> },
      options
    );
    toastr.error(capfl(i18n.t(title)), capfl(i18n.t(message)), newOptions);
  },
  warning: (title, message, options) => {
    const newOptions = Object.assign(
      {},
      { icon: <Icon size={'2.5em'} className={ICON_NOTIF_WARNING} /> },
      options
    );
    toastr.warning(capfl(i18n.t(title)), capfl(i18n.t(message)), newOptions);
  },
  info: (title, message, options) => {
    const newOptions = Object.assign(
      {},
      { icon: <Icon size={'2.5em'} className={ICON_LOADING} />, timeOut: 1500 },
      options
    );
    toastr.info(capfl(i18n.t(title)), capfl(i18n.t(message)), newOptions);
  }
};
