import chroma from 'chroma-js';
import _ from 'lodash';

const COLOR_PRIMARY = '#66cdf7';
const COLOR_SECONDARY = '#535b6b';
const COLOR_DANGER = '#dc3545';
const COLOR_SUCCESS = '#28a745';
const COLOR_WARNING = '#ffc107';
const COLOR_INFO = '#17a2b8';
const COLOR_WHITE = '#fff';
const COLOR_OFFWHITE = '#efefef';
const COLOR_BLACK = '#000';
const COLOR_LIGHT_GRAY = '#aaa';
const COLOR_EXTRA_LIGHT_GRAY = '#cfcfcf';
const COLOR_LIGHT_GREEN = '#64cd78';
const COLOR_LIGHT_BLUE = '#66cdf7';
const COLOR_ORANGE = 'orange';
const COLOR_RED = '#FF4444';
const COLOR_GRAY = '#C5C5C5';
const COLOR_YELLOW = '#ffdf71';
const COLOR_HELL = '#666';
const COLOR_DARK_GRAY = '#999';
const COLOR_DISABLED = '#ccc';
const COLOR_FACEBOOK = '#3b5998';
const BREAKPOINT_XS = 0;
const BREAKPOINT_SM = 480;
const BREAKPOINT_MD = 780;
const BREAKPOINT_LG = 1280;
const BREAKPOINT_XL = 1440;
const FONT_FAMILY_HELVETICA = "'Helvetica Neue', Helvetica, sans-serif";
const COLOR_EEEZ = '#eee';

export default (colors = {}) => {
  const defaultColors = {
    primary: COLOR_PRIMARY,
    secondary: COLOR_SECONDARY,
    white: COLOR_WHITE,
    black: COLOR_BLACK,
    danger: COLOR_DANGER,
    warning: COLOR_WARNING,
    info: COLOR_INFO,
    success: COLOR_SUCCESS,
    disabled: COLOR_DISABLED,
    lgray: COLOR_LIGHT_GRAY,
    xlgray: COLOR_EXTRA_LIGHT_GRAY,
    facebook: '#3B5998',
    twitter: '#1da1f2',
    instagram: '#fcaf45',
    offwhite: COLOR_OFFWHITE,
    hell: COLOR_HELL
  };
  const finalColors = _.merge({}, defaultColors, colors);
  return {
    fontFamily: "'Raleway', sans-serif",
    fallbackFontFamily: FONT_FAMILY_HELVETICA,
    borderRadius: 0,
    maxWidth: BREAKPOINT_XL,
    headerTopBarHeight: 35,
    // grid
    gutterWidth: 20,
    breakpoints: {
      xs: BREAKPOINT_XS,
      sm: BREAKPOINT_SM,
      md: BREAKPOINT_MD,
      lg: BREAKPOINT_LG,
      xl: BREAKPOINT_XL
    },
    containerWidth: {
      sm: BREAKPOINT_SM,
      md: BREAKPOINT_MD,
      lg: BREAKPOINT_LG,
      xl: BREAKPOINT_XL
    },
    // colors
    colors: finalColors,
    // z-index
    zIndex: {
      overlay: 9000,
      modal: 8000,
      cartDropdown: 7500,
      megamenu: 7000,
      autoComplete: 6000,
      filters: 5000,
      dropdown: 4000,
      header: 10
    },
    // header
    header: {
      height: '105px',
      background: COLOR_WHITE
    },
    // topBar
    topBar: {
      background: 'linear-gradient(to right, #2e3031 0%, #393d3f 100%)',
      height: '35px',
      borderBottom: 'none',
      link: {
        color: COLOR_WHITE,
        hover: {
          color: COLOR_LIGHT_GRAY
        },
        icon: {
          color: COLOR_WHITE
        }
      },
      listItemSeparator: '1px solid rgba(255,255,255,.4)'
    },
    // link
    link: {
      color: {
        primary: finalColors.primary,
        secondary: finalColors.secondary,
        opposite: COLOR_WHITE
      },
      hoverColor: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        secondary: chroma(finalColors.secondary)
          .darken(0.5)
          .hex(),
        opposite: COLOR_LIGHT_GRAY
      },
      activeColor: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        secondary: chroma(finalColors.secondary)
          .darken(0.5)
          .hex(),
        opposite: COLOR_LIGHT_GRAY
      }
    },
    // button
    button: {
      color: {
        primary: COLOR_WHITE,
        facebook: COLOR_WHITE,
        success: COLOR_WHITE,
        lgray: COLOR_WHITE
      },
      hoverColor: {
        primary: COLOR_WHITE,
        facebook: COLOR_WHITE,
        success: COLOR_WHITE,
        lgray: COLOR_WHITE
      },
      background: {
        primary: finalColors.primary,
        facebook: COLOR_FACEBOOK,
        success: COLOR_SUCCESS,
        lgray: COLOR_EXTRA_LIGHT_GRAY
      },
      hoverBackground: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        facebook: chroma(COLOR_FACEBOOK)
          .darken(0.5)
          .hex(),
        success: chroma(COLOR_SUCCESS)
          .darken(0.5)
          .hex(),
        lgray: chroma(COLOR_EXTRA_LIGHT_GRAY)
          .darken(0.2)
          .hex()
      },
      height: {
        big: '52px',
        normal: '44px',
        small: '32px'
      },
      padding: {
        big: '0 1.6em',
        normal: '0 1.2em',
        small: '0 0.6em'
      }
    },
    // button outline
    buttonOutline: {
      border: '2px solid',
      color: {
        primary: finalColors.primary,
        facebook: COLOR_FACEBOOK,
        success: COLOR_SUCCESS,
        lgray: COLOR_EXTRA_LIGHT_GRAY
      },
      hoverColor: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        facebook: chroma(COLOR_FACEBOOK)
          .darken(0.5)
          .hex(),
        success: chroma(COLOR_SUCCESS)
          .darken(0.5)
          .hex(),
        lgray: chroma(COLOR_EXTRA_LIGHT_GRAY)
          .darken(0.2)
          .hex()
      },
      borderColor: {
        primary: finalColors.primary,
        facebook: COLOR_FACEBOOK,
        success: COLOR_SUCCESS,
        lgray: COLOR_EXTRA_LIGHT_GRAY
      },
      hoverBorderColor: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        facebook: chroma(COLOR_FACEBOOK)
          .darken(0.5)
          .hex(),
        success: chroma(COLOR_SUCCESS)
          .darken(0.5)
          .hex(),
        lgray: chroma(COLOR_EXTRA_LIGHT_GRAY)
          .darken(0.2)
          .hex()
      }
    },
    // dropdown
    dropdown: {
      background: COLOR_WHITE,
      border: 'none',
      shadow: '0 0 2px 0 rgba(0,0,0,.2)',
      padding: '10px'
    },
    // input
    input: {
      padding: {
        small: '0.25em 0.8em',
        normal: '0.45em 0.8em',
        big: '0.65em 1.2em'
      },
      fontSize: {
        small: '0.86em',
        normal: '1em',
        big: '1.1em'
      },
      color: '#495057',
      border: '1px solid',
      borderColor: '#ced4da',
      borderFocusColor: chroma('#ced4da')
        .darken(0.5)
        .hex()
    },
    //searchbar
    searchBar: {
      height: '44px',
      borderRadius: '2px',
      boxShadowFocus: '0px 0px 2px 0px rgba(0,0,0,0.75)',
      icon: {
        background: '#f2f2f2',
        color: COLOR_HELL,
        border: '1px solid #e1e1e1',
        fontSize: '22px'
      },
      input: {
        border: '1px solid #eee'
      },
      button: {
        fontSize: '1em',
        fontWeight: 600,
        background: finalColors.primary,
        color: COLOR_WHITE,
        hover: {
          background: chroma(finalColors.primary)
            .darken(0.5)
            .hex(),
          color: COLOR_WHITE
        }
      }
    },
    //userDropdown
    userDropdown: {
      iconColor: '#222',
      iconFontSize: '1.6em',
      textTop: {
        color: '#222',
        fontSize: '0.9em',
        fontWeight: '400'
      },
      textBottom: {
        color: '#aaa',
        fontSize: '0.7em',
        fontWeight: '400'
      },
      dropdown: {
        background: COLOR_WHITE,
        border: '1px solid ' + COLOR_LIGHT_GRAY,
        boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75)'
      }
    },
    //cartButton
    cartButton: {
      border: '1px solid #eee',
      background: '#fff',
      totalColor: COLOR_BLACK,
      totalFontWeight: 'bold',
      totalFontFamily: FONT_FAMILY_HELVETICA,
      borderRadius: '3px',
      bagSize: '45px',
      bagBackground: '#f1f0f0',
      bagColor: COLOR_BLACK,
      bagIconSize: '24px',
      countBackground: '#ffdf71',
      countColor: COLOR_BLACK,
      dropdownBackground: COLOR_WHITE,
      dropdownShadow: '-2px 2px 6px 1px rgba(0,0,0,.05)'
    },
    //navigation
    pagenav: {
      background: COLOR_WHITE,
      borderTop: '1px solid ' + COLOR_EEEZ,
      borderBottom: '1px solid ' + COLOR_EEEZ,
      item: {
        fontSize: '0.8em',
        height: '50px',
        color: COLOR_HELL,
        hover: {
          color: finalColors.secondary,
          background: '#fafafa'
        },
        categoriesColor: COLOR_WHITE,
        categoriesBackground: finalColors.primary,
        categoriesHoverColor: COLOR_WHITE,
        categoriesHoverBackground: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),

        linkTitle: {
          color: finalColors.secondary,
          fontSize: '1rem'
        }
      },
      dropdown: {
        background: COLOR_WHITE,
        border: '1px solid  #dfdfdf',
        shadow: '1px 1px 2px 0 rgba(0, 0, 0, 0.1)'
      },
      btnShowSideMenu: {
        color: COLOR_BLACK,
        background: 'transparent',
        hover: {
          color: COLOR_BLACK,
          background: 'transparent'
        }
      }
    },
    // panel
    panel: {
      background: COLOR_WHITE,
      border: '1px solid #eee',
      background: COLOR_WHITE,
      borderRadius: '3px',
      boxShadow: '0 0 5px rgba(0,0,0,.1)',
      margin: '0 0 20px 0',
      padding: '20px',
      title: {
        fontSize: '1em',
        color: COLOR_HELL,
        margin: '0 0 10px 0'
      }
    },
    // page
    page: {
      background: COLOR_WHITE,
      backgroundSecondary: '#f4f4f4',
      padding: '40px 0',
      overlay: {
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.8)'
      },
      empty: {
        icon: {
          fontSize: '38px',
          color: '#ccc'
        },
        text: {
          margin: '5px 0 0 0',
          fontSize: '1em',
          fontWeight: '600'
        }
      }
    },
    // cart
    cart: {
      background: '#f7f7f7',
      title: {
        fontSize: '1em',
        color: '#666'
      },
      section: {
        border: '1px solid #eee',
        background: COLOR_WHITE,
        borderRadius: '3px',
        boxShadow: '0 0 5px rgba(0,0,0,.1)'
      }
    },
    // productCard
    productCard: {
      width: '100%',
      name: {
        color: '#5f636c'
      },
      brand: {
        color: '#aaa',
        borderColor: '#eee'
      },
      hover: {
        boxShadow: '0 0 10px rgba(0,0,0,.1)'
      }
    },
    // labels
    labels: {
      new: {
        background: COLOR_LIGHT_GREEN
      },
      favourite: {
        background: COLOR_LIGHT_BLUE
      },
      status: {
        background: COLOR_ORANGE
      },
      discount: {
        background: COLOR_RED
      }
    },
    // price
    price: {
      finalPrice: {
        color: COLOR_RED,
        fontFamily: FONT_FAMILY_HELVETICA
      },
      finalPriceWithoutVAT: {
        color: COLOR_GRAY,
        fontFamily: FONT_FAMILY_HELVETICA
      },
      discount: {
        fontFamily: FONT_FAMILY_HELVETICA
      },
      price: {
        fontFamily: FONT_FAMILY_HELVETICA
      }
    },
    // rating
    rating: {
      fullStarColor: COLOR_YELLOW
    },
    // carousels
    newArrivalsCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 6,
      initialSlide: 0,
      dots: true,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_XL
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    },
    favoritesCarouselSettings: {
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      dots: true,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    },
    crosssaleCarouselSettings: {
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    },
    relatedProductsCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 1,
      initialSlide: 0,
      dots: false,
      infinite: true,
      autoplay: true,
      responsive: [
        {
          breakpoint: BREAKPOINT_XL
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    },
    carousels: {
      arrows: {
        color: finalColors.xlgray,
        border: 'none',
        background: 'transparent',
        marginTop: '-18px',
        width: '36px',
        height: '36px',
        fontSize: '22px',
        hover: {
          color: finalColors.primary,
          border: 'none',
          background: 'transparent'
        },
        md: {
          color: finalColors.xlgray,
          border: 'none',
          background: 'transparent',
          marginTop: '-18px',
          width: '36px',
          height: '36px',
          fontSize: '22px',
          hover: {
            color: finalColors.primary,
            border: 'none',
            background: 'transparent'
          }
        }
      },
      dots: {
        width: '10px',
        height: '10px',
        background: 'rgba(0, 0, 0, 0.4)',
        borderRadius: '100%',
        isActive: {
          background: finalColors.black
        }
      }
    },
    productCarrousels: {
      marginBottom: '90px',
      arrows: {
        color: finalColors.xlgray,
        border: 'none',
        background: 'transparent',
        margin: '-20px 0 0 0',
        width: '40px',
        height: '40px',
        fontSize: '36px',
        borderRadius: '0',
        top: '50%',
        hover: {
          color: finalColors.primary,
          border: 'none',
          background: 'transparent'
        },
        md: {
          color: finalColors.xlgray,
          border: '1px solid ' + finalColors.xlgray,
          background: finalColors.white,
          margin: '0',
          width: '36px',
          height: '36px',
          fontSize: '22px',
          borderRadius: '0',
          top: '-52px',
          hover: {
            color: finalColors.primary,
            border: '1px solid ' + finalColors.xlgray,
            background: finalColors.white
          }
        }
      },
      dots: {
        width: '10px',
        height: '10px',
        background: 'rgba(255, 255, 255, 0.4)',
        borderRadius: '100%',
        isActive: {
          background: finalColors.white
        }
      }
    },
    // cart
    cart: {
      background: '#f7f7f8',
      title: {
        fontSize: '1.1em',
        fontWeight: 400,
        color: '#969999'
      },
      section: {
        border: '1px solid ' + COLOR_EEEZ,
        background: COLOR_WHITE
      }
    },
    //section
    section: {
      background: COLOR_WHITE,
      backgroundSecond: '#f7f7f8',
      title: {
        fontSize: '1.375em',
        fontWeight: 400,
        color: '#969999'
      },
      subtitle: {
        fontSize: '0.9em',
        color: '#c7c7c7'
      }
    },
    //label
    label: {
      color: COLOR_HELL
    },
    checkbox: {
      indicatorColor: COLOR_HELL
    },
    radio: {
      indicatorColor: COLOR_HELL
    },
    rangeCarousel: {
      rail: {
        height: '7px'
      },
      track: {
        backgroundColor: COLOR_WHITE,
        height: '7px'
      },
      handle: {
        backgroundColor: finalColors.secondary,
        width: '17px',
        height: '17px'
      },
      mobile: {
        rail: {
          height: '7px'
        },
        track: {
          backgroundColor: COLOR_GRAY,
          height: '7px'
        },
        handle: {
          backgroundColor: finalColors.secondary,
          width: '17px',
          height: '17px'
        }
      }
    },
    footer: {
      background: COLOR_WHITE,
      borderTop: '1px solid #d6d6d6',
      title: {
        fontSize: '1em',
        color: '#737373'
      },
      text: {
        color: '#b4b4b4'
      },
      bottomBar: {
        background: '#363636',
        color: COLOR_WHITE
      },
      link: {
        color: finalColors.primary,
        hover: {
          color: chroma(finalColors.primary)
            .darken(0.5)
            .hex()
        }
      }
    },
    // active filter
    activeFilter: {
      margin: '0 10px 10px 0',
      border: '1px solid #eee',
      remove: {
        color: '#222',
        background: '#efefef',
        hover: {
          color: COLOR_WHITE,
          background: COLOR_DANGER
        }
      }
    },
    // breadcrumbs
    breadcrumbs: {
      listItem: {
        margin: '0 8px 0 0'
      },
      separator: {
        fontSize: '0.8em',
        color: COLOR_LIGHT_GRAY,
        margin: '0 0 0 8px'
      }
    },
    // gallery
    gallery: {
      width: {
        xs: '100%',
        md: '80%'
      },
      padding: '0 25px 0 0',
      border: {
        color: '#eee',
        style: 'solid',
        width: '1px'
      },
      mainImage: {
        background: COLOR_WHITE,
        border: 'none',
        padding: '40px 0',
        border: {}
      },
      thumbnail: {
        background: COLOR_WHITE,
        border: {
          color: '#eee',
          style: 'solid',
          width: '0 1px 0 0'
        }
      }
    },
    // numstepper
    numstepper: {
      height: '44px',
      prev: {
        width: '28px',
        fontSize: '14px',
        border: {
          radius: '2px',
          color: '#ACACAC',
          style: 'solid',
          width: '2px'
        }
      },
      next: {
        width: '28px',
        fontSize: '14px',
        border: {
          radius: '2px',
          color: '#ACACAC',
          style: 'solid',
          width: '2px'
        }
      },
      input: {
        background: '#f0f0f0',
        width: '45px',
        color: '#acacac',
        border: {
          radius: 0,
          width: 0
        }
      }
    },
    //toastr
    toastr: {
      position: 'top-right',
      transitionIn: 'fadeIn',
      transitionOut: 'fadeOut',
      marginTop: '20px',
      title: {
        fontSize: '16px'
      },
      text: {
        fontSize: '15px'
      },
      progressbar: {
        height: '3px'
      },
      success: {
        background: 'rgba(175, 202, 5, 0.9)',
        boxShadow: 'none',
        border: 'none',
        progressbarColor: 'rgba(0,0,0,0.06)',
        titleColor: COLOR_WHITE,
        textColor: COLOR_WHITE,
        closeButtonColor: 'rgba(0, 0, 0, 0.25)'
      },
      error: {
        background: 'rgba(230, 59, 69, 0.9)',
        boxShadow: 'none',
        border: 'none',
        progressbarColor: 'rgba(0,0,0,0.06)',
        titleColor: COLOR_WHITE,
        textColor: COLOR_WHITE,
        closeButtonColor: 'rgba(0, 0, 0, 0.25)'
      }
    }
  };
};
