export const PRODUCT_VIEW = 'products_view_impressions';
export const PRODUCT_CLICK = 'product_click_impression';
export const PRODUCT_DETAIL = 'product_detail';
export const PRODUCT_RESERVATION = 'product_reservation';
export const RETARGETING_CATEGORIES = 'retargeting_categories';
export const PAGE_VIEW = 'page_view';
export const CART_ADD = 'cart_add';
export const CART_REMOVE = 'cart_remove';
export const CHECKOUT = 'checkout';
export const PURCHASE = 'purchase';
export const FB_PURCHASE = 'fb_purchase';
export const NEWSLETTER_SUBSCRIBE = 'newsletter_subscribe';

export const CHUNK_SIZE = 30;

export const FBDATA = {
  CONTENT_TYPE_PRODUCT: 'product',
  CONTENT_TYPE_PRODUCT_GROUP: 'product_group'
};

export const EVENTS = {
  [PRODUCT_VIEW]: 'eec.impressionView',
  [PRODUCT_CLICK]: 'eec.impressionClick',
  [PRODUCT_DETAIL]: 'eec.detail',
  [PRODUCT_RESERVATION]: 'evnt.reserve',
  [RETARGETING_CATEGORIES]: 'retargeting.categories',
  [PAGE_VIEW]: 'eec.pageview',
  [CART_ADD]: 'eec.add',
  [CART_REMOVE]: 'eec.remove',
  [CHECKOUT]: 'eec.checkout',
  [PURCHASE]: 'eec.purchase',
  [FB_PURCHASE]: 'fb.purchase',
  [NEWSLETTER_SUBSCRIBE]: 'nl.subscribe'
};
