import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import Modal from '../_partials/modal/mobilonline/index';
import Icon from '../styleguide/Icon';
import { capfl } from '../../utils/index';
import ButtonRouteLink from '../_base/ButtonRouteLink';

const S = {};
S.ModalContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

S.TopSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

S.ButtonSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
`;

S.Icon = styled(Icon)`
  margin-bottom: 20px;
  &.icon {
    font-size: 38px;
    color: ${({ theme }) => _.get(theme, 'colors.primary')};
  }
`;

S.Text = styled.span`
  font-weight: 600;
  color: ${({ theme }) => _.get(theme, 'colors.primary')};
`;

S.Button = styled(ButtonRouteLink)`
  && {
    min-width: 200px;
  }
`;

const AppModals = ({ t }) => {
  return (
    <section>
      <Modal modalId="nologged_wishlist">
        <S.ModalContent>
          <S.TopSection>
            <S.Icon className="nc-mobilonline-oblubene" />
            <S.Text>{capfl(t('please, log in for this action'))}</S.Text>
          </S.TopSection>
          <S.ButtonSection>
            <S.Button route="login" buttonType="secondary">
              {capfl(t('login'))}
            </S.Button>
          </S.ButtonSection>
        </S.ModalContent>
      </Modal>
      <Modal modalId="full_compare">
        <S.ModalContent>
          <S.TopSection>
            <S.Icon className="nc-mobilonline-porovnanie" />
            <S.Text>{capfl(t('you can compare max 3 products'))}</S.Text>
          </S.TopSection>
        </S.ModalContent>
      </Modal>
    </section>
  );
};

export default withTranslation()(AppModals);
