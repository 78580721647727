import _ from 'lodash';
import Cookies from 'js-cookie';
import * as GTM from 'common/constants/GTMConstants';

const getSecondLevelCategory = (product) => {
  const categories = _.get(product, 'categories', {});
  let category = _.first(categories);
  // elastic categories
  if (_.has(category, 'name')) {
    category = _.get(categories, '1', category);
  }
  if (!_.get(category, 'name', null)) {
    category = _.get(category, 1, category);
  }
  return category;
}

const productsProcessor = (
  products,
  list = null,
  additionalData = {},
  chunkIndex = 0
) => {
  const productsObject = [];
  if (_.isEmpty(products)) {
    return productsObject;
  }
  let productsArray = products;
  const fixedPosition = _.get(additionalData, 'position', null);
  const position = chunkIndex * GTM.CHUNK_SIZE;
  _.each(productsArray, (product, index) => {
    const category = getSecondLevelCategory(product);
    const productData = {
      id: _.get(product, 'id'),
      name: _.get(product, 'name'),
      price: _.get(product, 'finalPriceData.price'),
      brand: _.get(product, 'brand.name'),
      category: _.get(category, 'name', ''),
      position: fixedPosition ? fixedPosition : position + (index + 1)
    };
    if (list) {
      productData.list = list;
    }
    productsObject.push(productData);
  });
  return productsObject;
};

const productsProcessorCart = (items = [], additionalData = {}) => {
  const productsObject = [];
  if (_.isEmpty(items)) {
    return productsObject;
  }
  _.each(items, item => {
    if (!_.isEmpty(_.get(item, 'product', null))) {
      const { product, count } = item;
      // use second level category
      const category = getSecondLevelCategory(product);
      let quantity = _.get(additionalData, 'count', null);
      if (!quantity) {
        quantity = count ? count : 1;
      }
      const productData = {
        id: _.get(product, 'id'),
        name: _.get(product, 'name'),
        price: _.get(product, 'finalPriceData.price'),
        brand: _.get(product, 'brand.name'),
        category: _.get(category, 'name', ''),
        quantity: quantity
      };
      productsObject.push(productData);
    }
  });
  return productsObject;
};

export const preparePushData = (
  event,
  data,
  list,
  additionalData = {},
  chunkIndex = 0
) => {
  const pushData = {
    event: GTM.EVENTS[event]
  };
  switch (event) {
    case GTM.PRODUCT_VIEW:
      pushData.ecommerce = {};
      pushData.ecommerce.impressions = productsProcessor(
        data,
        list,
        additionalData,
        chunkIndex
      );
      break;
    case GTM.PRODUCT_DETAIL:
      pushData.ecommerce = {};
      pushData.ecommerce.detail = {
        products: productsProcessor(data)
      };
      break;
    case GTM.PRODUCT_RESERVATION:
      const reservation = _.first(data);
      pushData.value = reservation.value;
      break;
    case GTM.PRODUCT_CLICK:
      pushData.ecommerce = {};
      pushData.ecommerce.click = {
        actionField: list
      };
      pushData.ecommerce.products = productsProcessor(
        data,
        list,
        additionalData
      );
      break;
    case GTM.CART_ADD:
      pushData.ecommerce = {};
      pushData.ecommerce.add = {
        products: productsProcessorCart(data, additionalData)
      };
      break;
    case GTM.CART_REMOVE:
      pushData.ecommerce = {};
      pushData.ecommerce.remove = {
        products: productsProcessorCart(data, additionalData)
      };
      break;
    case GTM.CHECKOUT:
      const cart = _.first(data);
      pushData.ecommerce = {};
      pushData.ecommerce.checkout = {
        actionField: {
          step: _.get(additionalData, 'step')
        }
      };
      if (!_.isEmpty(cart)) {
        pushData.ecommerce.checkout.products = productsProcessorCart(
          _.get(cart, 'items', []),
          additionalData
        );
      }
      break;
    case GTM.PURCHASE:
      const order = _.first(data);
      pushData.ecommerce = {};
      pushData.ecommerce.purchase = {
        actionField: {
          id: _.get(order, 'orderNumber'),
          revenue: parseFloat(_.get(order, 'totalSumData.price', 0)),
          tax: parseFloat(_.get(order, 'totalSumData.vatFraction', 0)),
          shipping: (parseFloat(_.get(order, 'deliveryPrice', 0)) + parseFloat(_.get(order, 'paymentPrice', 0))),
          coupon: ''
        }
      };
      if (!_.isEmpty(order)) {
        pushData.ecommerce.purchase.products = productsProcessorCart(
          _.get(order, 'orderItems', []),
          additionalData
        );
      }
      break;
    case GTM.RETARGETING_CATEGORIES:
      pushData.page = {
        Category: {}
      };
      pushData.page.Category = processRetargetingCategories(data);
      break;
    default:
      return pushData;
  }
  return pushData;
};

export const getClientIdFromCookie = () => {
  let clientId = '0000000.0000000';
  const gacookie = Cookies.get('_ga');
  if (gacookie) {
    const gacookieParts = _.drop(_.split(gacookie, '.'), 2);
    clientId = _.join(gacookieParts, '.');
  }
  return clientId;
};

export const getGTMCategoryList = (
  categoryData = {},
  gotActiveFilters = false
) => {
  let list = gotActiveFilters ? 'CategoryFilter' : 'Category';
  if (_.get(categoryData, 'search', '')) {
    list = 'Search';
  }
  return list;
};
