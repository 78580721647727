import {
  ICON_LISTING_GRID,
  ICON_LISTING_ROWS
} from 'common/constants/IconConstants';

export const DEFAULT_LANG = 'sk';
export const ALLOWED_LANGS = ['sk', 'en', 'cz'];
export const BANNERS_TYPE_HOMEPAGE = 2;
export const BANNERS_TYPE_CATEGORY = 3;
export const BANNERS_TYPE_CUSTOMBANNER_1 = 5;
export const BANNERS_TYPE_CUSTOMBANNER_2 = 6;
export const BANNERS_TYPE_INFOBOX = 10;
export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_LIMIT = 20;
export const DEFAULT_BLOG_PAGE_LIMIT = 12;
export const DELIVERY_CUSTOM_TYPE_PERSONAL_PICKUP = 1;
export const RESERVATION_PAYMENT_TYPE = 22;
export const DEFAULT_NUMERAL_PRICE_FORMAT = '0[.]00';


export const DEFAULT_SORT_OPTION = 'time-desc';

export const DEFAULT_SORT_OPTIONS = [
  { label: 'price-asc', value: 'price-asc' },
  { label: 'price-desc', value: 'price-desc' },
  { label: 'name-asc', value: 'name-asc' },
  { label: 'name-desc', value: 'name-desc' },
  { label: 'time-asc', value: 'time-asc' },
  { label: 'time-desc', value: 'time-desc' }
];

export const LISTING_LAYOUT_CARDS = 1;
export const LISTING_LAYOUT_ROWS = 2;

export const DEFAULT_LISTING_LAYOUT = 1;
export const DEFAULT_LISTING_LAYOUTS = [
  { id: LISTING_LAYOUT_CARDS, value: 'cards', label: 'cards', icon: ICON_LISTING_GRID},
  { id: LISTING_LAYOUT_ROWS, value: 'rows', label: 'rows', icon: ICON_LISTING_ROWS}
];

export const FILTERS_LABEL_KEY = 'v2-label';
export const FILTERS_NAME_KEY = 'v2-name';
export const FITLER_VALUE_KEY = 'v2-value';

export const COOKIE_PRODUCTS_IDS_TO_COMPARE = '_PRODUCTS_IDS_TO_COMPARE';

export const COOKIE_CONSENT = '_CONSENT';
export const COOKIE_AGE_VERIFIED = '_AGE_VERIFIED';
export const COOKIE_LISTING_LAYOUT = '_LISTING_LAYOUT';
export const COOKIE_FONTS = '_FONTS_LOADED';
export const COOKIE_THEME = '_THEME';
export const COOKIE_EXPIRE_TIME = 30; //in days
export const COOKIE_EXPIRE_TIME_IN_MS = COOKIE_EXPIRE_TIME * 24 * 60 * 60 * 1000; //in miliseconds

export const GENERATED_IMAGE_DENSITIES = [1, 2];

export const ESHOP_TYPE_B2B = 1;
export const ESHOP_TYPE_B2C = 2;
export const ESHOP_TYPE_B2B_LOCKED = 3;
export const ESHOP_TYPE_MIXED = 4;

export const ADDITIONAL_INFOS_TYPE_PERSON = 2;
export const ADDITIONAL_INFOS_STORE_MANAGER_HASH = 'store_manager';

export const ALLOW_ORDER_OUT_OF_STOCK = 'order_out_of_stock_products';

export const ATTACHMENT_TYPE_FILE = 1;
export const ATTACHMENT_TYPE_URL = 2;

export const ATTACHMENT_EXTENSION_PDF = 'pdf';
