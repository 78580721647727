import _ from 'lodash';

import { setObjProp } from 'common/utils/index';

import { SET_PAGE_DATA, SET_PAGE_LOADING } from '../actions/PageActions';

const initialState = {};

const PageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_LOADING:
      return { ...state, ...{ isLoading: action.payload.isLoading } };
    case SET_PAGE_DATA:
      const pageName = action.payload.pageName;
      const propName = action.payload.propName;
      if (state[pageName]) {
        if (!_.isEmpty(propName)) {
          if (_.isArray(propName)) {
            return setObjProp([].concat([pageName], propName), action.payload.data, state);
          } else {
            return setObjProp([pageName, propName], action.payload.data, state);
          }
        } else {
          return setObjProp([pageName], action.payload.data, state);
        }
      }
      return state;
    default:
      return state;
  }
};

export default PageReducer;
