export const PRODUCT_HARD_HOOK_TYPE = 1;
export const PRODUCT_SOFT_HOOK_TYPE = 2;
export const PRODUCT_DICOUNT_HOOK_TYPE = 3;
export const PRODUCT_SET_HOOK_TYPE = 4;
export const COOKIE_LAST_SEEN = 'PRODUCTS_LAST_SEEN';
export const FREE_DELIVERY_KIND_ID = 10;
export const CENTRAL_STORE = 'Central store'; //Centrálny sklad
export const SUPPLIER_STORE = 'Supplier store'; //Dodavatelský sklad
export const PRODUCT_ADDITIONAL_INFO_TYPE_SIZE = 1;
export const PRODUCT_ADDITIONAL_INFO_TYPE_CUSTOM = 0;

export const PARAM_MODEL_ID = 2878;
export const PARAM_BRAND_ID = 2778;
export const PARAM_PRODUCT_TYPE = 2883;

// export const CENTRAL_STORE = 'Centrálny sklad';
// export const SUPPLIER_STORE = 'Dodavatelský sklad'

export const TAB_PARAMETERS = 1;
export const TAB_DESCRIPTION = 2;
