import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { withTheme, createGlobalStyle } from 'styled-components';
import PubSub from 'pubsub-js';
import loadable from '@loadable/component';
import TagManager from 'react-gtm-module';

import AppModals from 'common/components/appModals/index';
import { ReduxToastrTheme } from 'common/components/message/_styles';
import getRoutes from 'common/routes/getRoutes';
import Fonts, { fontClass, fontCss } from 'common/components/_base/Fonts';
import { canUseDOM } from 'common/utils';
import { withCssUnit } from 'common/components/styleguide/utils';

import 'assets/scss/app.scss';

if (canUseDOM()) {
  const activeTheme = window.__ACTIVE_THEME__;
  if (activeTheme === 'mobilonline' || activeTheme === 'murat' || activeTheme === 'freshbox') {
    import(`assets/icons/${activeTheme}/scss/nucleo.scss`);
  }
}

const AsyncLayout = loadable(props => import(`common/layouts/${props.layout}`));
const AsyncPage = loadable(props => import(`common/pages/${props.page}`));

const GlobalStyle = createGlobalStyle`
  #root {
    background-color: ${({ theme }) =>
      _.get(theme, 'colors.background', 'white')};
  }
  body {
    font-family: sans-serif;
    overflow-x: hidden;
    @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
      overflow-x: auto;
    }
  }
  ${({ activeTheme }) => `
    .${fontClass(activeTheme)} {
      ${fontCss(activeTheme)}
    }
  `}
  body.no-scroll{
    overflow: hidden;
  }
  body.fixed{
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.listeners = {
      documentMouseDown: e => {
        PubSub.publish('MOUSEDOWN', e);
      },
      documentEscapePressed: e => {
        if (e.keyCode === 27) {
          PubSub.publish('ESCPRESSED', e);
        }
      }
    };
    if (canUseDOM()) {
      const { settings = {} } = props;
      const gtmCode = _.get(settings, 'google_tag_manager_code', null);
      if (gtmCode) {
        TagManager.initialize({
          gtmId: gtmCode
        });
      }
    }
  }
  componentDidMount() {
    const { activeTheme } = this.props;
    Fonts(activeTheme);
    // if (canUseDOM()) {

      // document.addEventListener(
      //   'mousedown',
      //   this.listeners.documentMouseDown,
      //   true
      // );
      // document.addEventListener(
      //   'keydown',
      //   this.listeners.documentEscapePressed,
      //   true
      // );
    // }
  }
  // componentWillUnmount() {
  //   document.removeEventListener(
  //     'mousedown',
  //     this.listeners.documentMouseDown,
  //     true
  //   );
  //   document.addEventListener(
  //     'keydown',
  //     this.listeners.documentEscapePressed,
  //     true
  //   );
  // }
  render() {
    const { activeTheme, theme } = this.props;
    const routes = getRoutes(activeTheme);
    return (
      <Fragment>
        <GlobalStyle activeTheme={activeTheme} />
        <Switch>
          {routes.map(({ paths, exact, layout, ...rest }) => {
            if (_.isObject(paths)) {
              return _.map(paths, path => {
                return (
                  <Route
                    key={path}
                    path={path}
                    exact={exact}
                    render={props => {
                      return (
                        <AsyncLayout layout={layout}>
                          <AsyncPage {...props} {...rest} />
                        </AsyncLayout>
                      );
                    }}
                  />
                );
              });
            }
            return null;
          })}
          <Route
            render={() => (
              <AsyncLayout layout="mobilonline">
                <AsyncPage page="404" />
              </AsyncLayout>
            )}
          />
        </Switch>
        <AppModals />
        <ReduxToastrTheme
          timeOut={3000}
          newestOnTop={true}
          position={_.get(theme, 'toastr.position', 'top-left')}
          transitionIn={_.get(theme, 'toastr.transitionIn', 'fadeIn')}
          transitionOut={_.get(theme, 'toastr.transitionOut', 'fadeOut')}
          progressBar
        />
      </Fragment>
    );
  }
}

export default withTheme(App);
