import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'common/utils/polyfills';
import React from 'react';
import _ from 'lodash';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSSR } from 'react-i18next';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';
import configureStore from '../common/store/configureStore';
import runtimeConfig from 'common/config/runtime';
import '../common/i18n/index';

import getTheme from 'server/utils/getTheme';

import App from '../common/containers/App';

import history from './history';

const preloadState = window.__PRELOAD_STATE__;
const store = configureStore(preloadState);
const activeTheme = window.__ACTIVE_THEME__;

// Allow the passed state to be garbage-collected
delete window.__PRELOAD_STATE__;

const theme = getTheme(
  activeTheme,
  _.pickBy(
    {
      primary: _.get(
        preloadState,
        'app.webInstance.globalSettings.color_main',
        null
      ),
      secondary: _.get(
        preloadState,
        'app.webInstance.globalSettings.color_secondary',
        null
      )
    },
    _.identity
  )
);
const initialI18nStore = window.__I18N_STORE__;
const currentLanguage = window.__CURRENT_LANGUAGE__;

const settings = _.get(preloadState, 'app.webInstance.globalSettings', {});

if (_.get(runtimeConfig, 'sentryDNS', false)) {

  let sentryInitObject = {
    dsn: _.get(runtimeConfig, 'sentryDNS', '')
  }

  if (_.get(runtimeConfig, 'reactiveVersion')) {
    sentryInitObject['release'] = 'reactive@' + _.get(runtimeConfig, 'reactiveVersion');
  }

  if (_.get(runtimeConfig, 'reactiveEnvironment')) {
    sentryInitObject['environment'] = _.get(runtimeConfig, 'reactiveEnvironment');
  }

  Sentry.init(sentryInitObject);
}

const Main = () => {
  useSSR(initialI18nStore, currentLanguage);
  return (
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <App activeTheme={activeTheme} settings={settings} />
        </ThemeProvider>
      </Router>
    </Provider>
  );
};

loadableReady(() => {
  hydrate(<Main />, document.getElementById('root'));
});

if (module.hot) {
  module.hot.accept();
}
