import { createSelector } from 'reselect';
import _ from 'lodash';
import { DEFAULT_LISTING_LAYOUT } from 'common/constants/SiteConstants';

const getPage = state => {
  const { page = {} } = state;
  return page;
};

const getApp = state => {
  const { app = {} } = state;
  return app;
};

export const getPageSelector = () =>
  createSelector(
    [getPage],
    page => page
  );

const getPageData = state => {
  const { app = {}, page = {} } = state;
  const routeName = _.get(app, 'activeRoute.name', null);
  const pageData = _.get(page, [routeName], null);
  return pageData;
};

export const getPageDataSelector = () =>
  createSelector(
    [getPageData],
    pageData => pageData
  );

export const getListingLayoutSelector = () =>
  createSelector(
    [getApp],
    appData => _.get(appData, 'listingLayout', DEFAULT_LISTING_LAYOUT)
  );

export const getCategoriesSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'categories', null)
  );

export const getTopBarMenuSelector = () =>
  createSelector(
    [getApp],
    appData => _.get(appData, 'webInstance.pagesHeader', {})
  );

export const getTopFooterMenuSelector = () =>
  createSelector(
    [getApp],
    appData => _.get(appData, 'webInstance.pages', {})
  );

export const getFooterSelector = () =>
  createSelector(
    [getPage],
    pageData => _.get(pageData, 'footer', null)
  );

export const getProductsSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'products', null)
  );

export const getPageIsLoadingSelector = () =>
  createSelector(
    [getPage],
    page => _.get(page, 'isLoading', false)
  );

export const getLastSeenProductsSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'lastSeenProducts', null)
  );

export const getBannersSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'banners', null)
  );

export const getHomepageArticlesSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'articles', null)
  );

export const getLatestProductsSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'productsLatest', null)
  );

export const getFavoritesProductsSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'productsFavorite', null)
  );

export const getPagePaginationSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'pagination', null)
  );

export const getNewProductsSelector = () =>
  createSelector(
    [getPageData],
    pageData => _.get(pageData, 'productsNew', null)
  );
