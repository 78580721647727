const routes = {
  product: {
    name: 'product',
    paths: {
      sk: '/produkt/:id',
      en: '/en/product/:id',
      cz: '/cz/produkt/:id'
    },
    exact: true,
    cache: { route: true, markup: true },
    page: 'product/murat',
    layout: 'murat'
  }
}

export default routes;
