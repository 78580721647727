import _ from 'lodash';
import Cookies from 'js-cookie';
import { message } from 'common/components/message/_default';

import apiFront from 'common/utils/apiFront';
import { fireGTMEvent } from 'common/utils/gtm';
import { cartInteraction } from 'common/utils/smartPoint';
import { METHOD } from 'common/constants/HttpConstants';
import { COOKIE_CART } from 'common/constants/CartConstants';
import { CART_ADD, CART_REMOVE } from 'common/constants/GTMConstants';
import { setLoading } from './PageActions';

export const SET_CART = 'SET_CART';
export const UNSET_CART = 'UNSET_CART';
export const SET_CART_DELIVERY = 'SET_CART_DELIVERY';
export const SET_CART_PAYMENT = 'SET_CART_PAYMENT';
export const SET_CART_LOADING = 'SET_CART_LOADING';
export const SET_CART_COUPON_RESULT = 'SET_CART_COUPON_RESULT';

export const setCartLoading = isLoading => {
  return { type: SET_CART_LOADING, payload: { isLoading } };
};

export const setCart = cart => {
  return { type: SET_CART, payload: { cart } };
};

export const unsetCart = () => {
  return { type: UNSET_CART };
};

export const setCartPayment = paymentId => {
  return { type: SET_CART_PAYMENT, payload: { paymentId } };
};

export const setCartDelivery = deliveryId => {
  return { type: SET_CART_DELIVERY, payload: { deliveryId } };
};

export const setCartCouponResult = couponResult => {
  return { type: SET_CART_COUPON_RESULT, payload: { couponResult } };
};

export const addToCart = items => async (dispatch, getState) => {
  message.info('cart', 'inserting item into cart');
  const { app } = getState();
  const cartAccessToken = Cookies.get(COOKIE_CART);
  const { webInstance } = app;
  if (webInstance) {
    dispatch(setLoading(true));
    let endpointObj = {
      endpoint: 'carts'
    };
    if (cartAccessToken) {
      endpointObj = Object.assign(
        {},
        {
          endpoint: 'cart_items',
          endpointParams: { accessToken: cartAccessToken }
        }
      );
    }
    const apiParams = Object.assign({}, endpointObj, {
      method: METHOD.POST,
      options: {
        query: {
          expand: 'items.product.categories,items.product.hookedProducts'
        },
        data: { items }
      },
      showToastr: true,
      toastrParts: {
        title: 'cart'
      }
    });
    const newCart = await dispatch(apiFront(apiParams));
    if (!_.isEmpty(newCart)) {
      const insertedItem = _.find(_.get(newCart, 'items', []), item => {
        if (item.product.id === items.productId) {
          return item;
        }
      });
      if (insertedItem) {
        fireGTMEvent(CART_ADD, [insertedItem], null, { count: items.count });
      }
      cartInteraction(newCart);
      dispatch(setCart(newCart));
      Cookies.set(COOKIE_CART, newCart.accessToken, { expires: 1 });
      message.success(
        _.get(insertedItem, 'product.name', 'cart'),
        'item was successfully added!'
      );
    }
    dispatch(setLoading(false));
  }
};

export const removeFromCart = id => async (dispatch, getState) => {
  message.info('cart', 'removing item from cart');
  const { app, cart } = getState();
  const { webInstance } = app;
  if (webInstance && cart.accessToken) {
    dispatch(setLoading(true));
    const newCart = await dispatch(
      apiFront({
        method: METHOD.DELETE,
        endpoint: 'cart_item',
        options: { data: { itemId: id } },
        endpointParams: { accessToken: cart.accessToken, itemId: id },
        toastrParts: {
          title: 'cart'
        },
        showToastr: true
      })
    );
    if (!_.isEmpty(newCart)) {
      const { items = [] } = cart;
      const removedItem = _.find(items, { id: _.toInteger(id) });
      if (removedItem) {
        fireGTMEvent(CART_REMOVE, [removedItem]);
      }
      cartInteraction(newCart);
      dispatch(setCart(newCart));
      Cookies.set(COOKIE_CART, newCart.accessToken, { expires: 1 });
      message.success(
        _.get(removedItem, 'product.name', 'cart'),
        'item was successfully removed!'
      );
    }
    dispatch(setLoading(false));
  }
};

export const updateCart = (
  cartParams,
  successMessage = '',
  showMessage = true
) => async (dispatch, getState) => {
  message.info('cart', 'updating cart');
  const { app, cart } = getState();
  const { webInstance } = app;
  if (webInstance && cart.accessToken) {
    dispatch(setLoading(true));
    const newCart = await dispatch(
      apiFront({
        method: METHOD.PUT,
        endpoint: 'cart',
        options: {
          data: {
            cart: cartParams
          }
        },
        endpointParams: { accessToken: cart.accessToken },
        toastrParts: {
          title: 'cart'
        },
        showToastr: showMessage
      })
    );
    if (!_.isEmpty(newCart)) {
      dispatch(setCart(newCart));
      Cookies.set(COOKIE_CART, newCart.accessToken, { expires: 1 });
      if (showMessage) {
        message.success(
          'cart',
          successMessage ? successMessage : 'cart was successfully updated!'
        );
      }
    }
    dispatch(setLoading(false));
  }
};

export const updateCartItemCount = (id, sizeId, value) => async (
  dispatch,
  getState
) => {
  message.info('cart', 'updating item count');
  const { app, cart } = getState();
  const { webInstance } = app;
  if (webInstance && cart.accessToken) {
    dispatch(setLoading(true));
    const newCart = await dispatch(
      apiFront({
        method: METHOD.PUT,
        endpoint: 'cart_item',
        options: {
          data: {
            item: {
              sizeId: sizeId,
              count: value
            }
          },
          query: {
            expand: 'items.product.categories'
          }
        },
        endpointParams: { accessToken: cart.accessToken, itemId: id },
        toastrParts: {
          title: 'cart'
        },
        showToastr: true
      })
    );
    if (!_.isEmpty(newCart)) {
      dispatch(setCart(newCart));
      cartInteraction(newCart);
      Cookies.set(COOKIE_CART, newCart.accessToken, { expires: 1 });
      message.success('cart', 'item was successfully updated!');
    }
    dispatch(setLoading(false));
  }
};

export const fetchCart = () => async (dispatch, getState) => {
  const { app, cart } = getState();
  const { webInstance } = app;
  let cartAccessToken = Cookies.get(COOKIE_CART)
    ? Cookies.get(COOKIE_CART)
    : null;
  if (!_.isEmpty(cart)) {
    cartAccessToken = cart.accessToken;
  }
  if (webInstance && cartAccessToken) {
    dispatch(setCartLoading(true));
    const userCart = await dispatch(
      apiFront({
        endpoint: 'cart',
        endpointParams: { accessToken: cartAccessToken },
        toastrParts: {
          title: 'cart'
        },
        showToastr: true
      })
    );
    if (!_.isEmpty(userCart)) {
      dispatch(setCart(userCart));
      cartInteraction(userCart);
      Cookies.set(COOKIE_CART, userCart.accessToken, { expires: 1 });
    }
    dispatch(setCartLoading(false));
  }
};

export const applyCoupon = couponHash => async (dispatch, getState) => {
  const { app, cart } = getState();
  const { webInstance } = app;
  if (webInstance) {
    dispatch(setLoading(true));
    let endpointObj = {
      endpoint: 'carts'
    };
    if (cart && cart.accessToken) {
      endpointObj = Object.assign(
        {},
        {
          endpoint: 'cart_coupon',
          endpointParams: { accessToken: cart.accessToken }
        }
      );
    }
    const apiParams = Object.assign({}, endpointObj, {
      method: METHOD.POST,
      options: {
        data: { couponHash }
      },
      showToastr: true,
      toastrParts: {
        title: 'cart'
      }
    });
    const response = await dispatch(apiFront(apiParams));
    if (!_.isEmpty(response)) {
      const responseMessage = _.get(response, 'message', '');
      dispatch(setCartCouponResult({
        success: _.get(response, 'status', false),
        message: _.get(response, 'message', ''),
      }));
      const newCart = _.get(response, 'data', {});
      if (!_.isEmpty(newCart)) {
        cartInteraction(newCart);
        dispatch(setCart(newCart));
        Cookies.set(COOKIE_CART, newCart.accessToken, { expires: 1 });
        if (_.get(response, 'status', false) === true) {
          message.success('cart', responseMessage);
        } else {
          message.error('cart', responseMessage);
        }
      }
    }
    dispatch(setLoading(false));
  }
};

export const removeCoupon = () => async (dispatch, getState) => {
  const { app, cart } = getState();
  const { webInstance } = app;
  if (webInstance) {
    dispatch(setLoading(true));
    let endpointObj = {
      endpoint: 'carts'
    };
    if (cart && cart.accessToken) {
      endpointObj = Object.assign(
        {},
        {
          endpoint: 'cart_coupon',
          endpointParams: { accessToken: cart.accessToken }
        }
      );
    }
    const apiParams = Object.assign({}, endpointObj, {
      method: METHOD.DELETE,
      showToastr: true,
      toastrParts: {
        title: 'cart'
      }
    });
    const response = await dispatch(apiFront(apiParams));
    if (!_.isEmpty(response)) {
      dispatch(setCartCouponResult({}));
      const responseMessage = _.get(response, 'message', '');
      const newCart = _.get(response, 'data', {});
      if (!_.isEmpty(newCart)) {
        cartInteraction(newCart);
        dispatch(setCart(newCart));
        Cookies.set(COOKIE_CART, newCart.accessToken, { expires: 1 });
        if (_.get(response, 'status', false) === true) {
          message.success('cart', responseMessage);
        } else {
          message.error('cart', responseMessage);
        }
      }
    }
    dispatch(setLoading(false));
  }
};
