const filterFilterTable = {
  sk: {
    brand: 'znacka',
    storeLocation: 'predajna',
    priceRange: 'cena'
  },
  cz: {
    brand: 'vyrobce',
    storeLocation: 'prodejna',
    priceRange: 'cena'
  }
};

const routes = {
  home: {
    name: 'home',
    paths: { sk: '/', en: '/en' },
    exact: true,
    cache: { route: true, markup: true },
    page: 'home/freshbox-expres',
    layout: 'freshbox'
  },
  category: {
    name: 'category',
    paths: {
      sk: '/kategoria/:id/:filters(.*)?',
      en: '/en/categories/:id/:filters(.*)?',
      cz: '/cz/kategorie/:id/:filters(.*)?'
    },
    exact: true,
    cache: { route: true, markup: true },
    filterTable: filterFilterTable,
    page: 'category/freshbox',
    layout: 'freshbox'
  },
  product: {
    name: 'product',
    paths: {
      sk: '/produkt/:id',
      en: '/en/product/:id',
      cz: '/cz/produkt/:id'
    },
    exact: true,
    cache: { route: true, markup: true },
    page: 'product/freshbox',
    layout: 'freshbox'
  },
};

export default routes;
