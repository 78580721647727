import _ from 'lodash';
import chroma from 'chroma-js';
import merge from 'deepmerge';
import defaultTheme from './default';

const freshboxFormControl = {
  transition: '0.3s linear',
  state: {
    focus: originalColor => {
      return `border-color: ${originalColor};
        -webkit-box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        -moz-box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        outline: none !important;`;
    },
    hover: originalColor => {
      return `border-color: ${originalColor};
        outline: none !important;`;
    },
    active: originalColor => {
      return `border-color: ${originalColor};
        -webkit-box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        -moz-box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        outline: none !important;`;
    },
    focusObject: originalColor => ({
      borderColor: originalColor,
      boxShadow: `0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)}`,
      outline: 'none !important'
    }),
    hoverObject: originalColor => ({
      borderColor: originalColor,
      outline: 'none !important'
    }),
    activeObject: originalColor => ({
      borderColor: originalColor,
      boxShadow: `0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)}`,
      outline: 'none !important'
    })
  }
};

const fonts = {
  primary: 'Merriweather, sans-serif',
  secondary: '"Nunito Sans", sans-serif'
}

const freshboxBorder = {
  basic: {
    radius: 0,
    color: '#ced4da',
    style: 'solid',
    width: '1px'
  },
  panel: {
    radius: 0,
    color: '#ced4da',
    style: 'solid',
    width: '1px'
  },
  interactive: {
    radius: 0,
    color: '#f3f3f3',
    style: 'solid',
    width: '1px'
  }
};

const freshboxShadow = {
  basic: '0 10px 30px 0 rgba(0,0,0,0.1)',
  active: '0 10px 60px 0 rgba(0,0,0,0.15)',
  text: '0 3px 10px rgba(0,0,0,0.5);'
};

export default (colors = {}) => {
  const executedDefaultTheme = defaultTheme(colors, freshboxShadow, freshboxBorder, freshboxFormControl);
  const customColors = {
    // custom theme specific colors
    success: colors.primary,
    // facebook: '#395995',

    // backgrounds
    // background: '#FCFCF0',
    backgroundDarker: 'transparent',
    backgroundSecondary: 'transparent'
  };
  const finalColors = _.merge({}, executedDefaultTheme.colors, customColors, colors);

  const result = {
    fontFamily: fonts.primary,
    fontFamilySecondary: fonts.secondary,
    maxWidth: 1220,
    colors: finalColors,
    // button
    button: {
      border: 'none',
      enableDefaultShadow: false,
      textDecoration: 'none',
      height: {
        big: 'auto',
        normal: 'auto',
        small: 'auto'
      },
      padding: {
        big: '0 1.6em',
        normal: '12px 14px',
        small: '5px 10px'
      },
      fontSize: {
        big: '1.1em',
        normal: '14px',
        small: '10px'
      },
      hover: {
        textDecoration: 'none',
        border: 'none',
      }
    },
    logo: {
      size: '110x60.png',
      srcSet: {
        '780px': '80x40.png',
        '1240px': '110x60.png'
      }
    },
    // page
    page: {
      background: 'transparent',
      backgroundSecondary: 'transparent'
    },
    //label
    label: {
      color: finalColors.text
    },
    // input
    input: {
      background: freshboxBorder.interactive.color
    },
    //selectbox
    selectbox: {
      option: {
        active: {
          backgroundColor: `${chroma(finalColors.primary).alpha(0.1)}`
        }
      }
    },
    // price
    price: {
      fontFamily: fonts.secondary,
      discount: {
        fontWeight: 'bold'
      },
      price: {
        fontWeight: 'bold'
      },
      finalPriceWithoutVAT: {
        fontWeight: 'bold'
      },
      finalPrice: {
        fontWeight: 'bold'
      }
    },
    // pagenav
    pagenav: {
      item: {
        height: '60px',
        linkTitle: {
          color: colors.primary,
          fontFamily: fonts.secondary,
          fontSize: '1.1rem'
        },
        link: {
          fontSize: '0.85rem'
        }
      },
      showMore: {
        color: colors.primary
      },
      dropdown: {
        top: 'calc(100% - 10px)',
        left: 0,
        right: 0
      }
    },
    // banner
    banner: {
      BannerHomepageMainVariant: {
        padding: '20px',
        background: 'transparent'
      },
      content: {
        background: 'rgba(17, 17, 17, 0.3)',
      },
      text: {
        fontFamily: fonts.secondary
      },
      subtitle: {
        fontFamily: fonts.primary
      }
    },
    // productCard
    productCard: {
      border: 'none',
      background: finalColors.white,
      boxShadow: freshboxShadow.basic,
      hover: {
        border: 'none',
        background: finalColors.white,
        boxShadow: freshboxShadow.active
      },
      image: {
        background: finalColors.white
      },
      name: {
        fontFamily: fonts.secondary,
        fontSize: '1.2em'
      }
    },
    //cart
    cart: {
      background: 'transparent',
      title: {
        fontWeight: 800,
        color: finalColors.text
      },
    },
    // numstepper
    numstepper: {
      height: '40px',
      background: freshboxBorder.interactive.color,
      prev: {
        border: {
          width: 0
        }
      },
      next: {
        border: {
          width: 0
        }
      },
      input: {
        background: freshboxBorder.interactive.color,
        border: {
          radius: 0,
          width: '1px'
        }
      }
    },
    // pagination
    pagination: {
      basic: {
        radius: 0,
        color: finalColors.primary,
        border:
          freshboxBorder.interactive.width +
          ' ' +
          freshboxBorder.interactive.style +
          ' ' +
          'transparent',
        background: 'transparent'
      },
      hover: {
        radius: freshboxBorder.interactive.radius,
        color: finalColors.primary,
        border:
          freshboxBorder.interactive.width +
          ' ' +
          freshboxBorder.interactive.style +
          ' ' +
          finalColors.primary,
        background: 'transparent'
      },
      active: {
        radius: freshboxBorder.interactive.radius,
        color: finalColors.primary,
        border:
          freshboxBorder.interactive.width +
          ' ' +
          freshboxBorder.interactive.style +
          ' ' +
          finalColors.primary,
        background: 'transparent'
      },
      activeHover: {
        radius: freshboxBorder.interactive.radius,
        color: finalColors.text,
        border:
          freshboxBorder.interactive.width +
          ' ' +
          freshboxBorder.interactive.style +
          ' ' +
          finalColors.text,
        background: 'transparent'
      }
    },
    // breadcrumbs
    breadcrumbs: {
      link: {
        fontSize: '0.9em',
        fontWeight: 'bold',
        color: finalColors.primary,
      },
      separator: {
        fontSize: '0.9em',
        color: finalColors.primary,
        fontWeight: 'bold',
        margin: '0 0 0 8px'
      },
      text: {
        fontSize: '0.8em',
        color: finalColors.text,
        fontWeight: 'normal'
      }
    },
    headline: {
      fontFamily: fonts.secondary,
      h1: {
        customStyle: `
          font-weight: 900;
          margin: 0 0 20px 0;
        `
      },
      h2: {
        customStyle: `
          display: block;
          text-align:center;
          font-weight: bolder;
          line-height: 2rem;
          margin: 0 !important;
          font-size: 24px;
          padding: 15px 0 ;
          @media (min-width: ${_.get(
            executedDefaultTheme,
            'breakpoints.md'
          )}px) {
            padding: 20px 0;
            font-size: 1.9rem;
          }
        `
      },
      h3: {
        customStyle: `
          font-weight: bolder;
        `
      }
    },
  };

  return merge(executedDefaultTheme, result);
};
