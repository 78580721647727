import _ from 'lodash';
import mobilonlineRoutes from 'common/routes/mobilonline/index';
import defaultRoutes from 'common/routes/_default/index';
import foodfactoryRoutes from 'common/routes/foodfactory/index';
import freshboxRoutes from 'common/routes/freshbox/index';
import freshboxExpresRoutes from 'common/routes/freshbox-expres/index'
import muratRoutes from 'common/routes/murat/index';

const getRoutes = activeTheme => {
  const routes = {
    mobilonline: {
      routes: mobilonlineRoutes
    },
    foodfactory: {
      routes: foodfactoryRoutes
    },
    freshbox: {
      routes: freshboxRoutes
    },
    'freshbox-expres': {
      routes: freshboxExpresRoutes,
      layout: 'freshbox'
    },
    murat: {
      routes: muratRoutes
    }

    // define other routes
  };

  if (activeTheme !== 'default') {

    return _.values(
      _.reverse(
        _.sortBy(_.merge(defaultRoutes(_.get(routes, [activeTheme, 'layout'], activeTheme)), _.get(routes, [activeTheme, 'routes'])), [
          'priority'
        ])
      )
    );

  } else {

    return _.values(
      _.reverse(
        _.sortBy(
          defaultRoutes(), [
            'priority'
          ]
        )
      )
    );

  }
};

export default getRoutes;
