import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Modal from 'react-modal';
import styled, { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import Icon from 'common/components/styleguide/Icon';
import { withCssUnit } from 'common/components/styleguide/utils';
import { togglePageModal } from 'common/actions/PageActions';

import rightBorderBottomImage from 'assets/images/mobilonline/modal-right-bottom.png';

import {
  customStyles,
  StyledModal,
  StyledClose,
  StyledHeader,
  StyledTitle
} from '../_styles';

Modal.setAppElement('#root');

const S = {};
S.CustomModal = styled(StyledModal)`
  position: relative;
  align-self: start;
  padding: 20px 20px 40px 20px;
  overflow: visible;
  max-height: none;
  margin: 50px 0;
  -webkit-overflow-scrolling: touch;
  &:after {
    content: '';
    background: ${({ backgroundImage }) => 'url(' + backgroundImage + ')'};
    display: block;
    width: 83px;
    height: 58px;
    position: absolute;
    bottom: -31px;
    right: -43px;
  }

  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    border-radius: ${({ theme }) => _.get(theme, 'modal.mobile.borderRadius')};
    box-shadow: ${({ theme }) => _.get(theme, 'modal.mobile.shadow')};
    border: 0;
    width: 90%;
    max-height: 95%;
    margin: auto;
    align-self: center;
    overflow: auto;
    &:after {
      display: none;
    }
  }
`;

S.CustomTitle = styled(StyledTitle)`
  color: ${({ theme }) => _.get(theme, 'modal.title.color')} !important;
  font-size: ${({ theme }) => _.get(theme, 'modal.title.fontSize')} !important;
`;

S.CustomHeader = styled(StyledHeader)`
  border-bottom: 1px solid #D0D0D0;
  padding: 0 0 20px;
  margin-bottom: 20px;
`;

const PageModal = ({
  theme,
  closeModal,
  children,
  title,
  width,
  isOpen = false
}) => {
  customStyles.overlay.zIndex = _.get(theme, 'zIndex.modal', 999);
  return (
    <S.CustomModal
      isOpen={isOpen}
      contentLabel="Modal"
      width={width}
      style={customStyles}
      onRequestClose={() => {
        closeModal();
      }}
      onAfterOpen={() =>
        (document.body.style.cssText = 'overflow: hidden; width: 100%;')
      }
      onAfterClose={() => document.body.removeAttribute('style')}
      backgroundImage={rightBorderBottomImage}
    >
      <S.CustomHeader>
        <S.CustomTitle>{title}</S.CustomTitle>
        <StyledClose
          href="#"
          onClick={e => {
            e.preventDefault();
            closeModal();
          }}
        >
          <Icon className="nc-bold-remove" />
        </StyledClose>
      </S.CustomHeader>
      <section>{children}</section>
    </S.CustomModal>
  );
};

const mapStateToProps = (state, props) => {
  const { app, page } = state;
  const { modalId } = props;
  const activePage = _.get(app, 'activeRoute.name', null);
  return { isOpen: _.get(page, [activePage, 'modal', modalId], false) };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    closeModal: () => {
      const { modalId } = props;
      dispatch(togglePageModal(modalId, false));
    }
  };
};

const PageModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageModal);

export default withTranslation()(withTheme(PageModalConnected));
