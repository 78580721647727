import { get } from 'lodash';
import { COOKIE_FONTS } from 'common/constants/SiteConstants';
import Cookies from 'js-cookie';

const FontsConfig = {
  mobilonline: {
    name: 'Quicksand',
    class: 'ff-quicksand',
    link:
      'https://fonts.googleapis.com/css?family=Quicksand:400,700&display=swap&subset=latin-ext',
  },
  murat: {
    name: 'Open Sans',
    class: 'ff-opensans',
    link:
      'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap&subset=latin-ext',
  },
  freshbox: {
    name: 'Merriweather',
    class: 'ff-merriweather',
    link:
      'https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;1,400&family=Nunito+Sans:wght@300;400;600;700&display=swap',
  },
  default: {
    name: 'Roboto',
    class: 'ff-roboto',
    link:
      'https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap&subset=latin-ext',
  },
};

const Fonts = (activeTheme) => {
  const FontFaceObserver = require('fontfaceobserver');

  document.head.appendChild(fontLink(activeTheme, 3));

  if (parseInt(Cookies.get(COOKIE_FONTS), 10) || get(sessionStorage, 'fontsLoaded', false)) {
    if (!parseInt(Cookies.get(COOKIE_FONTS), 10)) {
      Cookies.set(COOKIE_FONTS, 1, { expires: 1/48 }); //30 minutes expiration
    }
    document.documentElement.classList.add(fontClass(activeTheme));
    return;
  }

  const font = new FontFaceObserver(fontName(activeTheme));

  font
    .load(null, 5000)
    .then(() => {
      document.documentElement.classList.add(fontClass(activeTheme));
      Cookies.set(COOKIE_FONTS, 1, { expires: 1/48 }); //30 minutes expiration
      sessionStorage.fontsLoaded = true;
    })
    .catch(() => {
      Cookies.set(COOKIE_FONTS, 0, { expires: 1/48 }); //30 minutes expiration
      sessionStorage.fontsLoaded = false;
    });
};

const fontLink = (activeTheme, returnElementType = 1) => {

  const link = (url, returnElementType) => {
    switch (returnElementType) {
      case 1:
        return url;
      case 2:
        return `<link href="${url}" rel="stylesheet">`;
      case 3:
        const element = document.createElement('link');
        element.href = url;
        element.rel = 'stylesheet';
        return element;
      default:
        return url;
    }
  }

  return link(
    get(FontsConfig, [activeTheme, 'link'], get(FontsConfig, 'default.link')),
    returnElementType
  );
};

const getFontClassServer = (activeTheme, cookies) => {
  if (parseInt(get(cookies, COOKIE_FONTS, 0))) {
    return fontClass(activeTheme);
  }
  return '';
}

const fontClass = (activeTheme) => {
  return get(FontsConfig, [activeTheme, 'class'], get(FontsConfig, 'default.class'));
};

const fontName = (activeTheme) => {
  return get(FontsConfig, [activeTheme, 'name'], get(FontsConfig, 'default.name'));
};

const fontCss = (activeTheme) => {
  return `
    body {
      font-family: '${fontName(activeTheme)}', sans-serif;
    }
  `;
};

export { fontLink, fontClass, fontName, fontCss, getFontClassServer };
export default Fonts;
