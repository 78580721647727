import chroma from 'chroma-js';
import _ from 'lodash';

const COLOR_TEXT = 'rgb(102, 102, 102)';
const COLOR_BORDER = '#e2e2e2';

const COLOR_PRIMARY = '#50237a';
const COLOR_SECONDARY = '#afca05';
const COLOR_PURPLE = '#50237a';
const COLOR_DANGER = '#dc3545';
const COLOR_SUCCESS = '#28a745';
const COLOR_WARNING = '#ffc107';
const COLOR_INFO = '#17a2b8';
const COLOR_WHITE = '#fff';
const COLOR_OFFWHITE = '#efefef';
const COLOR_BLACK = '#000';
const COLOR_LIGHT_GRAY = '#aaa';
const COLOR_EXTRA_LIGHT_GRAY = '#cfcfcf';
const COLOR_LIGHT_GREEN = '#b3ce00';
const COLOR_LIGHT_BLUE = '#00b5ff';
const COLOR_BLUE = '#0059ff';
const COLOR_ORANGE = '#ff7b00';
const COLOR_RED = '#e63b45';
const COLOR_GRAY = '#C5C5C5';
const COLOR_YELLOW = '#ffdf71';
const COLOR_HELL = '#666';
const COLOR_DARKER_GRAY = '#ACACAC';
const COLOR_DARK_GRAY = '#999';
const COLOR_EXTRA_DARK_GRAY = '#535353';
const COLOR_DISABLED = '#ccc';
const COLOR_FACEBOOK = '#3b5998';
const COLOR_EEEZ = '#eee';
const COLOR_PINK = '#ec3eac';
const BREAKPOINT_XS = 0;
const BREAKPOINT_SM = 480;
const BREAKPOINT_MD = 900;
const BREAKPOINT_LG = 1280;
const BREAKPOINT_XL = 1400;
const FONT_FAMILY_HELVETICA = "'Helvetica Neue', Helvetica, sans-serif";
const FONT_DEFAULT_THEME = "'Quicksand', sans-serif";

export default (colors = {}) => {
  const defaultColors = {
    text: COLOR_TEXT,
    primary: COLOR_PRIMARY,
    secondary: COLOR_SECONDARY,
    white: COLOR_WHITE,
    black: COLOR_BLACK,
    danger: COLOR_DANGER,
    warning: COLOR_WARNING,
    info: COLOR_INFO,
    success: COLOR_SUCCESS,
    disabled: COLOR_DISABLED,
    gray: COLOR_GRAY,
    lgray: COLOR_LIGHT_GRAY,
    xlgray: COLOR_EXTRA_LIGHT_GRAY,
    dgray: COLOR_DARK_GRAY,
    darkerGray: COLOR_DARKER_GRAY,
    xdgray: COLOR_EXTRA_DARK_GRAY,
    facebook: '#3B5998',
    twitter: '#1da1f2',
    instagram: '#fcaf45',
    offwhite: COLOR_OFFWHITE,
    hell: COLOR_HELL,
    blue: COLOR_BLUE,
    background: COLOR_WHITE,
    backgroundDarker: COLOR_EXTRA_LIGHT_GRAY,
    backgroundInverted: COLOR_PRIMARY,
    pink: COLOR_PINK,
    red: COLOR_RED
  };
  const defaultBorder = {
    radius: '5px',
    color: COLOR_BORDER,
    style: 'solid',
    width: '2px'
  }
  const finalColors = _.merge({}, defaultColors, colors);
  return {
    fontFamily: "'Quicksand', sans-serif",
    borderRadius: defaultBorder.radius,
    maxWidth: BREAKPOINT_XL,
    gutterWidth: 20,
    breakpoints: {
      xs: BREAKPOINT_XS,
      sm: BREAKPOINT_SM,
      md: BREAKPOINT_MD,
      lg: BREAKPOINT_LG,
      xl: BREAKPOINT_XL
    },
    // link
    link: {
      textDecoration: 'underline',
      active: {
        alpha: 0.1
      },
      focus: {
        alpha: 0.1
      },
      color: {
        primary: finalColors.primary,
        secondary: finalColors.secondary,
        opposite: COLOR_WHITE
      },
      hoverColor: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        secondary: chroma(finalColors.secondary)
          .darken(0.5)
          .hex(),
        opposite: COLOR_LIGHT_GRAY
      },
      activeColor: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        secondary: chroma(finalColors.secondary)
          .darken(0.5)
          .hex(),
        opposite: COLOR_LIGHT_GRAY
      }
    },
    // colors
    colors: finalColors,
    // border / line
    border: {
      default: defaultBorder
    },
    // shadow
    shadow: {
      basic: '0 0 10px rgba(0,0,0,0.15)',
    },
    // z-index
    zIndex: {
      overlay: 9000,
      modal: 8000,
      cartDropdown: 7500,
      autoComplete: 7000,
      megamenu: 6000,
      filters: 5000,
      dropdown: 4000,
      header: 10
    },
    // table
    table: {
      header: {
        color: COLOR_BLACK
      }
    },
    headline: {
      h1 : {
        customStyle: `
          font-size: 2.3rem;
        `
      },
      h2: {
        customStyle: `
          font-weight: bold;
        `
      },
      h3: {
        customStyle: `
          font-weight: bold;
        `
      }
    },
    // panel
    panel: {
      border: '1px solid ' + COLOR_EEEZ,
      background: COLOR_WHITE,
      borderRadius: '3px',
      boxShadow: '0 0 5px rgba(0,0,0,.1)',
      margin: '0 0 20px 0',
      padding: '20px',
      title: {
        fontSize: '1em',
        color: finalColors.secondary,
        margin: '0 0 10px 0'
      }
    },
    // pagination
    pagination: {
      basic: {
        radius: defaultBorder.radius,
        color: finalColors.lgray,
        border: defaultBorder.width + ' ' + defaultBorder.style + ' ' + defaultBorder.color,
        background: finalColors.background
      },
      hover: {
        radius: defaultBorder.radius,
        color: finalColors.lgray,
        border: defaultBorder.width + ' ' + defaultBorder.style + ' #ccc',
        background: finalColors.backgroundDarker
      },
      active: {
        radius: defaultBorder.radius,
        color: finalColors.primary,
        border: defaultBorder.width + ' ' + defaultBorder.style + ' ' + finalColors.primary,
        background: finalColors.background
      },
      activeHover: {
        radius: defaultBorder.radius,
        color: finalColors.primary,
        border: defaultBorder.width + ' ' + defaultBorder.style + ' ' + defaultBorder.color,
        background: finalColors.backgroundDarker
      }
    },
    // page
    page: {
      background: COLOR_WHITE,
      backgroundSecondary: '#f4f4f4',
      padding: '40px 0',
      margin: {
        top: '165px' //120px
      },
      overlay: {
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.8)'
      },
      empty: {
        icon: {
          fontSize: '38px',
          color: '#ccc'
        },
        text: {
          margin: '35px 0 0 0',
          fontSize: '35px',
          fontWeight: '700'
        }
      },
      mobile: {
        margin: {
          top: '155px' //115px
        }
      }
    },
    // cart
    cart: {
      background: COLOR_WHITE,
      title: {
        fontSize: '1em',
        color: '#666'
      },
      section: {
        border: '1px solid ' + COLOR_EEEZ,
        background: COLOR_WHITE,
        borderRadius: '3px',
        boxShadow: '0 0 5px rgba(0,0,0,.1)'
      }
    },
    //cartButton
    cartButton: {
      border: 'none',
      background: 'transparent',
      transition: 'none',
      borderRadius: '0',
      bagBackground: 'transparent',
      bagColor: COLOR_WHITE,
      bagIconSize: '30px',
      height: '75px',
      width: '60px',
      padding: '15px 10px',
      indicator: {
        fontSize: '0.75em',
        fontWeight: 'bold',
        background: finalColors.secondary,
        color: '#222222',
        width: '22px',
        height: '22px',
        bottom: '10px',
        left: '5px'
      },
      hover: `
        background: ${COLOR_WHITE};
        color: ${finalColors.primary};
        .icon {
          color: ${finalColors.primary};
        }
      `,
      dropdown: {
        background: COLOR_WHITE,
        total: {
          background: COLOR_OFFWHITE
        },
        customStyle: `
          margin-top: 0;
          margin-right: 0;
          top: 100%;
          border-radius: 0;
        `
      },
      mobile: {
        bagColor: finalColors.primary,
        padding: '10px',
        indicator: {
          bottom: '5px',
          left: '0'
        },
      },
      customStyle: `
       text-decoration: none;
      `
    },
    //menuButton
    menuButton: {
      customStyle: `
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 55px;
        padding: 5px 10px;
        text-decoration: none;
        .icon {
          color: ${finalColors.primary}
        }
        &:hover {
          background: transparent;
        }
        @media (min-width: ${BREAKPOINT_MD}px) {
          padding: 10px;
          width: 60px;
          height: 75px;
          & > .icon {
            color: ${COLOR_WHITE};
          }
          &:hover {
            background: ${COLOR_WHITE};
            .icon {
              color: ${finalColors.primary};
            }
          }
        }
      `
    },
    //wishlistButton
    wishlistButton: {
      indicator: {
        fontSize: '0.75em',
        fontWeight: 'bold',
        background: finalColors.secondary,
        color: '#222222',
        width: '22px',
        height: '22px',
        bottom: '10px',
        left: '5px'
      },
      customStyle: `
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 55px;
        padding: 5px 10px;
        text-decoration: none;
        .icon {
          color: ${finalColors.primary}
        }
        &:hover {
          background: ${finalColors.primary};
          .icon {
            color: ${COLOR_WHITE};
          }
        }
        @media (min-width: ${BREAKPOINT_MD}px) {
          height: 75px;
          width: 60px;
          padding: 10px;
          & > .icon {
            color: ${COLOR_WHITE};
          }
          &:hover {
            background: ${COLOR_WHITE};
            .icon {
              color: ${finalColors.primary};
            }
          }
        }
      `,
      mobile: {
        indicator: {
          bottom: '5px',
          left: '10px'
        },
      }
    },
    //compareButton
    compareButton: {
      indicator: {
        fontSize: '0.75em',
        fontWeight: 'bold',
        background: finalColors.secondary,
        color: '#222222',
        width: '22px',
        height: '22px',
        bottom: '10px',
        left: '5px'
      },
      customStyle: `
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 55px;
        padding: 5px 10px;
        text-decoration: none;
        .icon {
          color: ${finalColors.primary}
        }
        &:hover {
          background: ${finalColors.primary};
          .icon {
            color: ${COLOR_WHITE};
          }
        }
        @media (min-width: ${BREAKPOINT_MD}px) {
          padding: 10px;
          width: 60px;
          height: 75px;
          & > .icon {
            color: ${COLOR_WHITE};
          }
          &:hover {
            background: ${COLOR_WHITE};
            .icon {
              color: ${finalColors.primary};
            }
          }
        }
      `,
      mobile: {
        indicator: {
          bottom: '5px',
          left: '10px'
        },
      }
    },
    //userButton
    userButton: {
      indicator: {
        fontSize: '0.75em',
        fontWeight: 'bold',
        background: finalColors.secondary,
        color: '#222222',
        width: '22px',
        height: '22px',
        bottom: '10px',
        left: '5px'
      },
      customStyle: `
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 55px;
        padding: 5px 10px;
        text-decoration: none;
        .icon {
          color: ${finalColors.primary}
        }
        &:hover {
          background: ${finalColors.primary};
          .icon {
            color: ${COLOR_WHITE};
          }
        }
        @media (min-width: ${BREAKPOINT_MD}px) {
          padding: 10px;
          width: 60px;
          height: 75px;
          & > .icon {
            color: ${COLOR_WHITE};
          }
          &:hover {
            background: ${COLOR_WHITE};
            .icon {
              color: ${finalColors.primary};
            }
          }
        }
      `,
      mobile: {
        indicator: {
          bottom: '5px',
          left: '10px'
        },
      }
    },
    //storesButton
    storesButton: {
      indicator: {
        fontSize: '0.7em',
        fontWeight: 'bold',
        background: finalColors.secondary,
        color: '#222222',
        width: '22px',
        height: '22px',
        left: '0',
        bottom: '10px'
      },
      customStyle: `
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55px;
        padding: 5px 10px;
        text-decoration: none;
        section {
          height: 45px;
          width: 45px;
        }
        .icon {
          color: ${finalColors.primary};
        }
        &:hover {
          background: ${finalColors.primary};
          .icon {
            color: ${COLOR_WHITE};
          }
        }
        @media (min-width: ${BREAKPOINT_MD}px) {
          padding: 10px;
          height: 75px;
          section {
            height: 75px;
          }
          .icon {
            color: ${COLOR_WHITE};
          }
          &:hover {
            background: ${COLOR_WHITE};
            color: ${finalColors.primary};
            .icon {
              color: ${finalColors.primary};
            }
          }
        }
      `,
      mobile: {
        indicator: {
          bottom: '0',
          left: '0'
        },
      }
    },
    //searchButton
    searchButton: {
      linkType: 'white',
      customStyle: `
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 55px;
        padding: 5px 10px;
        text-decoration: none;
        .icon {
          color: ${finalColors.primary}
        }
        &:hover {
          background: transparent;
          .icon {
            color: ${finalColors.primary};
          }
        }
        @media (min-width: ${BREAKPOINT_MD}px) {
          padding: 10px;
          height: 75px;
          & > .icon {
            color: ${COLOR_WHITE};
          }
          &:hover {
            background: ${COLOR_WHITE};
            .icon {
              color: ${finalColors.primary};
            }
          }
        }
      `
    },
    // button
    button: {
      fontWeight: 'bolder',
      borderRadius: '100px',
      enableDefaultShadow: false,
      color: {
        primary: COLOR_WHITE,
        secondary: COLOR_PURPLE,
        facebook: COLOR_WHITE,
        success: COLOR_WHITE,
        lgray: COLOR_WHITE
      },
      hoverColor: {
        primary: COLOR_WHITE,
        secondary: COLOR_PURPLE,
        facebook: COLOR_WHITE,
        success: COLOR_WHITE,
        lgray: COLOR_WHITE
      },
      background: {
        primary: finalColors.primary,
        secondary: finalColors.secondary,
        facebook: COLOR_FACEBOOK,
        success: COLOR_SUCCESS,
        lgray: COLOR_LIGHT_GRAY
      },
      hoverBackground: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        secondary: chroma(finalColors.secondary)
          .darken(0.5)
          .hex(),
        facebook: chroma(COLOR_FACEBOOK)
          .darken(0.5)
          .hex(),
        success: chroma(COLOR_SUCCESS)
          .darken(0.5)
          .hex(),
        lgray: chroma(COLOR_LIGHT_GRAY)
          .darken(0.2)
          .hex()
      },
      height: {
        big: '52px',
        normal: '44px',
        small: '24px'
      },
      padding: {
        big: '0 1.6em',
        normal: '0 1.2em',
        small: '0 0.6em'
      }
    },
    // button outline
    buttonOutline: {
      borderRadius: '100px',
      border: '1px solid',
      enableDefaultShadow: false,
      color: {
        primary: finalColors.primary,
        secondary: COLOR_PURPLE,
        facebook: COLOR_FACEBOOK,
        success: COLOR_SUCCESS,
        lgray: COLOR_LIGHT_GRAY,
        purpleGray: COLOR_PURPLE
      },
      hoverColor: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        secondary: chroma(COLOR_PURPLE)
          .darken(0.5)
          .hex(),
        facebook: chroma(COLOR_FACEBOOK)
          .darken(0.5)
          .hex(),
        success: chroma(COLOR_SUCCESS)
          .darken(0.5)
          .hex(),
        lgray: chroma(COLOR_EXTRA_LIGHT_GRAY)
          .darken(0.2)
          .hex(),
        purpleGray: chroma(COLOR_PURPLE)
          .darken(0.5)
          .hex()
      },
      borderColor: {
        primary: finalColors.primary,
        secondary: finalColors.secondary,
        facebook: COLOR_FACEBOOK,
        success: COLOR_SUCCESS,
        lgray: COLOR_EXTRA_LIGHT_GRAY,
        purpleGray: COLOR_EXTRA_LIGHT_GRAY
      },
      hoverBorderColor: {
        primary: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        secondary: chroma(finalColors.secondary)
          .darken(0.5)
          .hex(),
        facebook: chroma(COLOR_FACEBOOK)
          .darken(0.5)
          .hex(),
        success: chroma(COLOR_SUCCESS)
          .darken(0.5)
          .hex(),
        lgray: chroma(COLOR_LIGHT_GRAY)
          .darken(0.2)
          .hex(),
        purpleGray: chroma(COLOR_LIGHT_GRAY)
          .darken(0.2)
          .hex()
      }
    },
    // dropdown
    dropdown: {
      borderRadius: '0'
    },
    newsletter: {
      background: finalColors.primary,
      title: {
        color: COLOR_LIGHT_GREEN,
        fontSize: '1.15em'
      },
      text: {
        color: COLOR_WHITE,
        fontSize: '1em'
      },
      icon: {
        color: COLOR_LIGHT_GREEN,
        fontSize: '1.3em'
      },
      linkButton: {
        background: COLOR_LIGHT_GREEN,
        color: finalColors.primary,
        hover: {
          background: COLOR_LIGHT_GREEN,
          color: finalColors.primary
        }
      }
    },
    footer: {
      background: chroma(finalColors.primary)
        .darken(0.5)
        .hex(),
      color: COLOR_WHITE,
      borderTop: '1px solid #d6d6d6',
      title: {
        fontSize: '1em',
        color: COLOR_LIGHT_GREEN
      },
      text: {
        color: COLOR_WHITE
      },
      bottomBar: {
        background: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),
        color: COLOR_WHITE
      },
      link: {
        color: COLOR_WHITE,
        hover: {
          color: COLOR_LIGHT_GREEN
        },
        inverted: {
          color: COLOR_LIGHT_GREEN,
          hover: {
            color: COLOR_WHITE
          }
        }
      },
      social: {
        text: {
          fontSize: '1.1em'
        },
        icon: {
          color: COLOR_WHITE,
          border: '2px solid ' + COLOR_LIGHT_GREEN,
          fontSize: '22px',

          hover: {
            color: COLOR_LIGHT_GREEN,
            border: '2px solid ' + COLOR_WHITE
          }
        }
      }
    },
    //searchbar
    searchBar: {
      height: '35px',
      borderRadius: '30px',
      boxShadowFocus: 'none',
      icon: {
        background: '#f2f2f2',
        color: COLOR_PURPLE,
        border: '1px solid #e1e1e1',
        fontSize: '20px'
      },
      input: {
        border: '1px solid #e1e1e1',
        background: '#f2f2f2'
      },
      button: {
        fontSize: '1em',
        fontWeight: 600,
        background: finalColors.primary,
        color: COLOR_WHITE,
        hover: {
          background: chroma(finalColors.primary)
            .darken(0.5)
            .hex(),
          color: COLOR_WHITE
        }
      }
    },
    // header
    header: {
      height: '65px',
      heightMD: '75px',
      background: COLOR_WHITE,
      contact: {
        customStyle: `
          font-size: 0.8em;
          line-height: 1.5em;
          color: ${COLOR_WHITE};
          a {
            color: ${COLOR_WHITE};
            text-decoration: none;
            &:hover {
              color: ${COLOR_WHITE};
              text-decoration: underline;
            }
          }
        `
      },
      mobile: {
        marginBottom: '55px'
      }
    },
    //navigation
    pagenav: {
      background: COLOR_WHITE,
      borderTop: '1px solid ' + COLOR_EEEZ,
      borderBottom: 'none',
      width: '100%',
      justifyContent: 'space-around',
      item: {
        fontSize: '0.9em',
        fontWeight: 'bold',
        height: '50px',
        color: finalColors.primary,
        hover: {
          color: finalColors.secondary,
          background: '#fafafa'
        },
        categoriesColor: COLOR_WHITE,
        categoriesBackground: finalColors.primary,
        categoriesHoverColor: COLOR_WHITE,
        categoriesHoverBackground: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),

        linkTitle: {
          color: finalColors.secondary,
          fontSize: '0.9rem',
          fontWeight: 'normal',
          mobile: {
            fontWeight: 'bold',
            fontSize: '1em'
          }
        },
        mobile: {
          fontSize: '1.2em',
          fontWeight: 'bold'
        }
      },
      dropdown: {
        background: COLOR_WHITE,
        border: '1px solid  #dfdfdf',
        shadow: '1px 1px 2px 0 rgba(0, 0, 0, 0.1)'
      },
      btnShowSideMenu: {
        color: COLOR_BLACK,
        background: 'transparent',
        hover: {
          color: COLOR_BLACK,
          background: 'transparent'
        }
      },
      overlay: {
        background: 'transparent',
      },
      mobile: {
        background: COLOR_WHITE,
        width: '100%',
        height: '100%',
        shadow: 'none',
        position: 'fixed',
        top: '104px' //66px
      },
      wrapper: `
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        & nav > ul > li > a:nth-child(2) {
          font-size: 1.2em;
        }
      `,
      inner: {
        customStyle: {
          desktop: `
            padding: 0;
            position: relative;
            scroll: auto;
          `,
          mobile: `
            padding: 60px 0 80px 0;
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: scroll;
          `,
        }
      }
    },
    //sidemenu
    sidemenu: {
      background: finalColors.primary,
      item: {
        color: COLOR_WHITE,
        hover: {
          color: COLOR_WHITE
        }
      },
      close: {
        color: COLOR_EXTRA_LIGHT_GRAY,
        hover: {
          color: COLOR_WHITE
        }
      }
    },
    // carousels
    newArrivalsCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 6,
      initialSlide: 0,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_XL,
          settings: {
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    },
    favoritesCarouselSettings: {
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    },
    alternativeCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 6,
      initialSlide: 0,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_XL,
          settings: {
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    },
    crosssaleCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 6,
      initialSlide: 0,
      dots: false,
      infinite: true,
      autoplaySpeed: 8000,
      responsive: [
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    },
    articlesCarouselSettings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    },
    carousels: {
      arrows: {
        color: finalColors.xlgray,
        border: 'none',
        background: 'transparent',
        marginTop: '-20px',
        width: '40px',
        height: '40px',
        fontSize: '22px',
        left: '15px',
        right: '15px',
        hover: {
          color: finalColors.white,
          border: 'none',
          background: 'transparent'
        },
        md: {
          color: finalColors.xlgray,
          border: 'none',
          background: 'transparent',
          marginTop: '-20px',
          width: '40px',
          height: '40px',
          fontSize: '26px',
          hover: {
            color: finalColors.white,
            border: 'none',
            background: 'transparent'
          }
        }
      },
      dots: {
        width: '10px',
        height: '10px',
        background: 'rgba(255, 255, 255, 0.4)',
        borderRadius: '100%',
        isActive: {
          background: finalColors.white
        }
      }
    },
    productCarrousels: {
      arrows: {
        color: finalColors.xlgray,
        border: 'none',
        background: 'transparent',
        margin: '-20px 0 0 0',
        width: '40px',
        height: '40px',
        fontSize: '32px',
        borderRadius: '0',
        top: '50%',
        hover: {
          color: finalColors.black,
          border: 'none',
          background: 'transparent'
        },
        md: {
          color: finalColors.xlgray,
          border: '1px solid rgb(207, 207, 207)',
          background: 'transparent',
          margin: '0 5px 0 0',
          width: '28px',
          height: '28px',
          fontSize: '22px',
          borderRadius: '100%',
          top: '-28px',
          prevRight: '32px',
          hover: {
            color: finalColors.primary,
            border: '1px solid rgb(207, 207, 207)',
            background: 'transparent'
          }
        }
      },
      dots: {
        width: '10px',
        height: '10px',
        background: 'rgba(255, 255, 255, 0.4)',
        borderRadius: '100%',
        isActive: {
          background: finalColors.white
        }
      }
    },
    // productCard
    productCard: {
      width: '100%',
      border: '2px solid white',
      name: {
        color: finalColors.primary
      },
      brand: {
        color: '#aaa',
        borderColor: COLOR_EEEZ
      },
      hover: {
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
        border: '2px solid rgba(0,0,0,0.05)'
      },
      actionLink: {
        hover: {
          background: finalColors.secondary
        }
      }
    },
    // labels
    labels: {
      options: {
        fontSize: '0.7em',
        fontWeight: 'bolder'
      },
      new: {
        background: COLOR_BLUE
      },
      favourite: {
        background: COLOR_LIGHT_BLUE
      },
      status: {
        background: COLOR_ORANGE,
        customStyle: '&[data-value~=Servisný] {background-color: #e5e5e6; color: black}'
      },
      preorder: {
        background: COLOR_PINK
      },
      discount: {
        background: COLOR_RED
      },
      delivery: {
        background: COLOR_DARKER_GRAY
      },
      gift: {
        background: '#F2C900'
      }
    },
    // price
    price: {
      finalPrice: {
        color: finalColors.secondary,
        fontFamily: FONT_DEFAULT_THEME,
        fontWeight: 'bolder'
      },
      finalPriceWithoutVAT: {
        color: COLOR_GRAY,
        fontFamily: FONT_DEFAULT_THEME
      },
      discount: {
        fontFamily: FONT_DEFAULT_THEME
      },
      price: {
        fontFamily: FONT_DEFAULT_THEME
      }
    },
    // input
    input: {
      borderRadius: '10px',
      padding: {
        small: '0 0.8em',
        normal: '0 0.8em',
        big: '0 1.2em'
      },
      height: {
        small: '28px',
        normal: '40px',
        big: '46px'
      },
      fontSize: {
        small: '0.86em',
        normal: '1em',
        big: '1.1em'
      },
      color: '#495057',
      border: '1px solid',
      borderColor: '#ced4da',
      borderFocusColor: chroma('#ced4da')
        .darken(0.5)
        .hex()
    },
    //selectbox
    selectbox: {
      borderRadius: '10px'
    },
    // checkbox
    checkbox: {
      borderRadius: '4px',
      indicatorColor: COLOR_HELL
    },
    // Carousel
    rangeCarousel: {
      rail: {
        height: '7px'
      },
      track: {
        backgroundColor: COLOR_WHITE,
        height: '7px'
      },
      handle: {
        backgroundColor: finalColors.secondary,
        width: '17px',
        height: '17px'
      },
      mobile: {
        rail: {
          height: '7px'
        },
        track: {
          backgroundColor: COLOR_GRAY,
          height: '7px'
        },
        handle: {
          backgroundColor: finalColors.secondary,
          width: '17px',
          height: '17px'
        }
      }
    },
    //section
    section: {
      background: COLOR_WHITE,
      backgroundSecond: '#f7f7f8',
      title: {
        fontSize: '1.2em',
        fontWeight: 400,
        color: finalColors.secondary,
        margin: '0 0 4px 5px',
        textAlign: 'left',
        textTransform: 'none'
      },
      subtitle: {
        fontSize: '0.9em',
        color: '#c7c7c7'
      },
      lg: {
        padding: '40px 0'
      }
    },
    // filters
    filters: {
      color: finalColors.primary,
      padding: '20px',
      paddingMD: '15px',
      background: COLOR_WHITE,
      backgroundMD: COLOR_OFFWHITE,
      boxShadow: '0 0 10px 0px rgba(0, 0, 0, 0.05)',
      fontWeight: 'bold',
      section: {
        marginBottom: '15px',
        paddingBotton: '10px',
        borderBottom: '1px solid ' + COLOR_WHITE,
        title: {
          color: COLOR_BLACK,
          fontSize: '0.9em'
        }
      },
      item: {
        marginBottom: '3px'
      },
      count: {
        color: COLOR_BLACK
      },
      showFiltersButton: {
        background: COLOR_OFFWHITE,
        fontWeight: 'bold',
        fontSize: '16px',
        color: COLOR_BLACK,
        borderRadius: '5px',
        padding: '5px 10px',
        textAlign: 'left'
      },
      mobile: {
        color: COLOR_HELL,
        fontWeight: 'normal',
        item: {
          marginBottom: '10px'
        },
        section: {
          borderBottom: '1px solid ' + COLOR_OFFWHITE,
          title: {
            color: COLOR_PURPLE,
            fontSize: '0.9em',
            marginBottom: '5px'
          },
          icon: {
            color: COLOR_GRAY,
            size: '24px'
          }
        }
      }
    },
    // active filter
    activeFilter: {
      margin: '0 10px 10px 0'
    },
    // breadcrumbs
    breadcrumbs: {
      listItem: {
        margin: '0 8px 0 0'
      },
      separator: {
        fontSize: '0.8em',
        color: COLOR_LIGHT_GRAY,
        margin: '0 0 0 8px'
      },
      text: {
        fontWeight: 'bolder',
        color: '#b7b7b7'
      },
      dropdown: {
        color: COLOR_PURPLE,
        textDecoration: 'underline',
        fontWeight: '400'
      },
      dropdownItem: {
        color: COLOR_DARK_GRAY,
        textDecoration: 'none',
        fontWeight: '400',
        borderBottom: '1px solid ' + COLOR_LIGHT_GRAY
      },
      link: {
        color: COLOR_PURPLE,
        textDecoration: 'underline',
        fontWeight: '400'
      }
    },
    // gallery
    gallery: {
      width: {
        xs: '100%',
        md: '100%'
      },
      padding: '0 25px 0 0',
      border: {
        color: '#eee',
        style: 'solid',
        width: '1px'
      },
      mainImage: {
        background: COLOR_WHITE,
        border: 'none',
        padding: '40px 0',
        margin: '0 0 40px 0',
        border: {
          color: '#eee',
          style: 'solid',
          width: '1px'
        }
      },
      thumbnail: {
        background: COLOR_WHITE,
        maxWidth: '135px',
        border: {
          color: '#eee',
          style: 'solid',
          width: '0 1px 0 0'
        }
      }
    },
    // numstepper
    numstepper: {
      height: '44px',
      prev: {
        width: '28px',
        fontSize: '14px',
        border: {
          radius: '2px',
          color: COLOR_DARKER_GRAY,
          style: 'solid',
          width: '2px'
        }
      },
      next: {
        width: '28px',
        fontSize: '14px',
        border: {
          radius: '2px',
          color: COLOR_DARKER_GRAY,
          style: 'solid',
          width: '2px'
        }
      },
      input: {
        background: '#f0f0f0',
        width: '45px',
        color: COLOR_DARKER_GRAY,
        border: {
          radius: 0,
          width: 0
        }
      }
    },
    // modal
    modal: {
      borderRadius: '20px',
      border: '10px solid ' + finalColors.primary,
      title: {
        color: finalColors.primary,
        fontSize: '17px'
      },
      notice: {
        color: COLOR_DARKER_GRAY
      },
      mobile: {
        borderRadius: '5px',
        shadow: '0px 0px 10px 0px rgba(0,0,0,0.75)'
      }
    },
    paymentOptions: {
      activeStateColor: finalColors.secondary
    },
    //toastr
    toastr: {
      position: 'top-right',
      transitionIn: 'fadeIn',
      transitionOut: 'fadeOut',
      marginTop: '80px',
      width: '400px',
      holder: {
        color: COLOR_WHITE
      },
      title: {
        fontSize: '17px',
        fontWeight: 'bold'
      },
      text: {
        fontSize: '16px',
        fontWeight: 'bold'
      },
      progressbar: {
        height: '3px'
      },
      success: {
        background: 'rgba(175, 202, 5, 0.9)',
        boxShadow: 'none',
        border: 'none',
        progressbarColor: 'rgba(0,0,0,0.06)',
        iconColor: COLOR_WHITE,
        titleColor: COLOR_PRIMARY,
        textColor: COLOR_WHITE,
        closeButtonColor: 'rgba(0, 0, 0, 0.25)'
      },
      error: {
        background: 'rgba(230, 59, 69, 0.9)',
        boxShadow: 'none',
        border: 'none',
        progressbarColor: 'rgba(0,0,0,0.06)',
        iconColor: COLOR_WHITE,
        titleColor: COLOR_WHITE,
        textColor: COLOR_WHITE,
        closeButtonColor: 'rgba(0, 0, 0, 0.25)'
      },
      info: {
        background: 'rgba(175, 202, 5, 0.7)',
        boxShadow: 'none',
        border: 'none',
        progressbarColor: 'rgba(0,0,0,0.06)',
        iconColor: COLOR_WHITE,
        titleColor: COLOR_PRIMARY,
        textColor: COLOR_WHITE,
        closeButtonColor: 'rgba(0, 0, 0, 0.25)'
      },
    },
    // thanks
    thanks: {
      borderRadius: '5px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      margin: '120px 20px 50px 20px',
      padding: '30px',
      mainTitle: {
        color: COLOR_PRIMARY,
        fontSize: '1.6em',
        margin: '0 0 50px 0'
      },
      title: {
        color: COLOR_BLACK,
        fontSize: '0.9em'
      },
      label: {
        color: COLOR_GRAY,
        fontSize: '1em',
        fontWeight: 600
      },
      value: {
        color: COLOR_PRIMARY,
        fontSize: '1em',
        fontWeight: 600,
      },
      address: {
        color: COLOR_GRAY,
        fontSize: '1em',
        fontWeight: 600,
        lineHeight: '1.5em',
      },
      product: {
        marginBottom: '10px',
        color: COLOR_GRAY,
        fontSize: '0.8em',
        fontWeight: 600
      },
      totalPrice: {
        color: COLOR_PRIMARY,
        fontSize: '1.1em',
        fontWeight: 600,
        textAlign: 'right',
      }
    },
    // reservation
    reservation: {
      success: {
        heading: {
          fontSize: '35px',
          imgMaxWidth: '45px'
        },
        visitUs: {
          color: COLOR_SECONDARY,
          fontSize: '20px',
          borderRight: '1px solid ' + COLOR_GRAY
        }
      }
    },
    // calculator buttons
    calculatorButton: {
      minWidth: '141px',
      width: '141px',
      height: '30px',
      border: '1px solid ' + COLOR_EXTRA_LIGHT_GRAY,
      hover: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px'
      }
    },

    // storeCard
    storeCard: {
      customStyle: `
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        margin: 10px;
        img {
          margin-bottom: 5px;
        }
        h3 {
          font-weight: bold;
        }
        span {
          font-size: 1em;
          display: block;
          margin-top: 5px;
        }
        hr {
          border-bottom-color: ${defaultBorder.color};
          margin: 0.7em 0 0.5em 0;
        }
        div {
          display: flex;
          justify-content: space-between;
        }
        a {
          font-weight: bold;
          font-size: 0.95em;
        }
        @media (min-width: ${BREAKPOINT_MD}px) {
          margin: 20px;
        }
      `
    },
  };
};
