import _ from 'lodash';
import { sprintf } from 'sprintf-js';
import pathToRegexp from 'path-to-regexp';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import numeral from 'numeral';
import "numeral/locales";

import apiEndpoints from 'common/api';

import { HEADER, METHOD } from 'common/constants/HttpConstants';
import {
  DEFAULT_LANG,
  DEFAULT_NUMERAL_PRICE_FORMAT
} from 'common/constants/SiteConstants';
import { COOKIE_USER } from 'common/constants/UserConstants';
import { COOKIE_CART } from 'common/constants/CartConstants';
import { SUPPLIER_STORE } from 'common/constants/ProductConstants';
import { COOKIE_ORDER_CONTACT_STEP } from 'common/constants/OrderConstants';

import { setUser } from 'common/actions/UserActions';
import { unsetCart } from 'common/actions/CartActions';

import getRoutes from 'common/routes/getRoutes';

const CACHE_KEY_SUFFIX = process.env.RAZZLE_CACHE_KEY_SUFFIX || '';

numeral.locale('sk');

/**
 * Custom log function.
 *
 * @param message
 * @param optionalParams
 */
export const log = (message, ...optionalParams) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(message, ...optionalParams);
  }
};

/**
 * Custom warn function.
 *
 * @param message
 * @param optionalParams
 */
export const warn = (message, ...optionalParams) => {
  if (process.env.NODE_ENV === 'development') {
    console.warn(message, ...optionalParams);
  }
};

/**
 * Custom error function.
 *
 * @param message
 * @param optionalParams
 */
export const error = (message, ...optionalParams) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(message, ...optionalParams);
  }
};

/**
 * Locahost with used port
 */
// const getLocalHostname = (path = '') => {
//   const parts = [];
//   parts.push(sprintf('%1$s:%2$s', 'http://127.0.0.1', process.env.PORT));
//   if (path) {
//     parts.push(path);
//   }
//   return parts.join('');
// };

/**
 * Get meta property from data object
 *
 * @param {object} data
 */
export const getMetaFromResponse = data => {
  return _.get(data, 'meta', {});
};

/**
 * Endpoint
 *
 * @param {string} endpoint
 * @param {string} otherHost
 */
export const getApiEndpoint = endpoint => {
  return apiEndpoints[endpoint] ? apiEndpoints[endpoint] : '';
};

/**
 * getApiEndpointWithHost
 *
 * @param {string} endpoint
 * @param {string} otherHost
 */
export const getApiEndpointWithHost = (endpoint, host) => {
  const apiEndpoint = getApiEndpoint(endpoint);
  return sprintf('%1$s%2$s', host, apiEndpoint);
};

/**
 * Alias for getImagePath
 */
export const getLogoPath = (logo, size = false) => {
  return getImagePath(logo, size);
};

/**
 * Get full image path
 * @param {string} image.path
 * @param {string} image.id
 * @param {string} size
 */
export const getImagePath = (image, size = false) => {
  if (!_.isEmpty(image)) {
    if (size !== false) {
      return sprintf('%1$s/%2$s/%3$s', image.path, image.id, size);
    }
    else {
      return sprintf('%1$s/%2$s', image.path, image.id);
    }
  }
  return '';
};

/**
 *
 */
export const getImageSrc = (imgPath, size, domain = '') => {
  if (domain) {
    const mask = _.endsWith(imgPath, '_') ? '%1$s/%2$s%3$s' : '%1$s/%2$s/%3$s';
    return sprintf(
      mask,
      _.trimEnd(domain, '/'),
      _.trim(imgPath, '/'),
      size
    );
  }
  const maskWithoutDomain = _.endsWith(imgPath, '_') ? '%1$s%2$s' : '%1$s/%2$s';
  return sprintf(maskWithoutDomain, _.trimEnd(imgPath, '/'), size);
};

/**
 * Prepare superagent request
 *
 * @param {mixed}
 * @param string endpoint
 * @param string method
 * @param object options
 * @param object webInstance
 */
export const prepareApiRequest = ({
  endpoint,
  method,
  options,
  webInstance,
  user,
  endpointParams = {},
  uri = ''
}) => {
  const { domain } = webInstance;
  const methodLower = method.toLowerCase();
  let data = options.data ? options.data : {};
  let headers = options.headers ? options.headers : {};
  let queryRaw = options.query ? options.query : {};
  let query = {};
  let checkUser = false;
  if (webInstance) {
    if (webInstance.webInstanceToken) {
      headers = Object.assign({}, headers, {
        [HEADER.WEBINSTANCE_TOKEN]: webInstance.webInstanceToken
      });
    }
    if (webInstance.ownerToken) {
      headers = Object.assign({}, headers, {
        [HEADER.OWNER_TOKEN]: webInstance.ownerToken
      });
    }
  }
  if (!_.isEmpty(uri)) {
    headers = Object.assign({}, headers, { [HEADER.URI]: uri });
  }
  if (user) {
    if (user.accessToken) {
      headers = Object.assign({}, headers, {
        [HEADER.AUTHORIZARION]: 'Bearer ' + user.accessToken
      });
      checkUser = true;
    }
  }
  if (queryRaw) {
    for (let key in queryRaw) {
      let item = queryRaw[key];
      if (_.isArray(item)) {
        query[key + '[]'] = item;
      } else {
        query[key] = item;
      }
    }
  }

  let url = _.indexOf(endpoint, 'http://') !== -1 ? endpoint : '';
  if (url === '') {
    const apiEndpoint = getApiEndpoint(endpoint);
    let path = '';
    if (apiEndpoint) {
      if (!_.isEmpty(endpointParams)) {
        const toPath = pathToRegexp.compile(apiEndpoint);
        path = toPath({ ...endpointParams });
      } else {
        path = apiEndpoint;
      }
    } else {
      path = endpoint;
    }
    url = sprintf('%1$s%2$s', _.trimEnd(domain, '/'), path);
  }
  const noRequestBody = _.includes([METHOD.HEAD, METHOD.GET], method);
  if (!noRequestBody) {
    headers = Object.assign({}, headers, {
      'Content-Type': 'application/json'
    });
  }
  return {
    url,
    method: methodLower,
    noRequestBody,
    headers,
    data,
    query,
    checkUser
  };
};

/**
 * Log api call
 *
 * @param {}
 */
export const logApiCall = ({
  url,
  method,
  headers = {},
  data = {},
  query = {}
}) => {
  log('====== API CALL ======');
  log('Calling api call: ', url);
  log('Calling api with method: ', method);
  // log('Calling api with headers: ', headers);
  log('Calling api with data: ', data);
  log('Calling api with query: ', query);
  log('==== END API CALL ====');
};

/**
 * Remove User from cookie and state
 *
 * @param {fun} dispatch
 */
export const removeUser = (dispatch = null) => {
  Cookies.remove(COOKIE_USER);
  Cookies.remove(COOKIE_CART);
  if (dispatch) {
    dispatch(setUser(null));
    dispatch(unsetCart());
  }
};

/**
 * Goto named route
 * @param {string} name
 */
export const gotoRoute = (name, params, toPathOptions = {}, options = {}) => (
  dispatch,
  getState
) => {
  const state = getState();
  const target = _.get(options, 'target', null);
  let currentLang = _.get(state, 'app.currentLanguage', DEFAULT_LANG);
  let activeTheme = _.get(state, 'app.activeTheme', 'default');
  const routes = getRoutes(activeTheme);
  if (!_.isEmpty(routes)) {
    const route = _.find(routes, route => {
      return route.name === name && route.paths[currentLang];
    });
    if (route) {
      let link = '';
      if (!_.isEmpty(params)) {
        link = setRouteParams(
          name,
          params,
          currentLang,
          activeTheme,
          toPathOptions
        );
      } else {
        link = route.paths[currentLang];
      }
      if (target) {
        window.open(link, target);
      } else {
        window.location.href = link;
      }
    }
  }
};

export const gotoUrl = (url, name = null) => {
  if (name) {
    window.open(url, name);
  } else {
    window.location.href = url;
  }
};

/**
 * capitalize first letter
 * @param {string} text
 */
export const capfl = text => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Helper for formik chekboxes
 *
 * @param {object} target
 * @param {object} values
 * @param {func} setFieldValue
 */
export const setFormikMultipleValues = (target, values, setFieldValue) => {
  const { name = '', checked: isChecked = false, value = '' } = target;
  if (name) {
    let checkboxValues = _.cloneDeep(_.get(values, name, []));
    if (!_.isEmpty(checkboxValues)) {
      if (isChecked) {
        if (_.isArray(checkboxValues)) {
          checkboxValues.push(value);
        } else {
          checkboxValues = [checkboxValues, value];
        }
      } else {
        if (_.isArray(checkboxValues)) {
          checkboxValues = _.pull(checkboxValues, value);
          if (checkboxValues.length === 1) {
            checkboxValues = checkboxValues[0];
          }
          if (checkboxValues.length === 0) {
            checkboxValues = '';
          }
        } else {
          checkboxValues = '';
        }
      }
      setFieldValue(name, checkboxValues);
    } else {
      setFieldValue(name, value);
    }
  }
};

/**
 *
 * Headers pagination
 *
 * @param {object} headers
 */
export const setPaginationFromHeaders = headers => {
  return {
    totalCount: headers['x-pagination-total-count'],
    pageCount: headers['x-pagination-page-count'],
    currentPage: headers['x-pagination-current-page'],
    perPage: headers['x-pagination-per-page']
  };
};

/**
 * remove diacritics
 *
 * @param {string} string
 * @param {string} replaceBlanks
 * @param toLower
 */
export const normalizeString = (string, replaceBlanks = '', toLower = true) => {
  if (_.isString(string)) {
    let finalString = '';
    if (replaceBlanks) {
      finalString = _.replace(
        _.deburr(string),
        new RegExp(' ', 'g'),
        replaceBlanks
      );
    } else {
      finalString = _.deburr(string);
    }
    if (toLower) {
      finalString = _.toLower(finalString);
    }
    return finalString;
  }
  return string;
};

/**
 * hash string
 *
 * @param {s} string
 */
export const hashCode = (s = '') => {
  let i = 0,
    h = 0;
  for (; i < s.length; i++) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
};

/**
 * Can I Use Dom
 */
export const canUseDOM = () => {
  return !!(typeof window !== 'undefined' && window.document);
};

/**
 * yup Errors to Formik form errors
 *
 * @param {mixed} yupError
 */
export const yupToFormErrors = yupError => {
  let errors = {};
  for (let _i = 0, _a = yupError.inner; _i < _a.length; _i++) {
    let err = _a[_i];
    if (!errors[err.path]) {
      errors = setIn(errors, err.path, err.message);
    }
  }
  return errors;
};

const getIn = (obj, key, def, p) => {
  if (p === void 0) {
    p = 0;
  }
  let path = _.toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj === undefined ? def : obj;
};

const setIn = (obj, path, value) => {
  let res = {};
  let resVal = res;
  let i = 0;
  let pathArray = _.toPath(path);
  for (; i < pathArray.length - 1; i++) {
    let currentPath = pathArray[i];
    let currentObj = getIn(obj, pathArray.slice(0, i + 1));
    if (resVal[currentPath]) {
      resVal = resVal[currentPath];
    } else if (currentObj) {
      resVal = resVal[currentPath] = _.cloneDeep(currentObj);
    } else {
      let nextPath = pathArray[i + 1];
      resVal = resVal[currentPath] =
        _.isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }
  resVal[pathArray[i]] = value;
  return _.assign({}, obj, res);
};

/**
 * Set params for route
 *
 * @param {string} routeName
 * @param {Object} routeParams
 * @param {string} currentLang
 * @param {string} activeTheme
 * @param toPathOptions
 */
export const setRouteParams = (
  routeName,
  routeParams = {},
  currentLang = '',
  activeTheme = '',
  toPathOptions = {}
) => {
  let newHref = '';
  const routes = getRoutes(activeTheme);
  if (!_.isEmpty(routes)) {
    const routeConfig = _.find(routes, obj => obj.name === routeName);
    if (routeConfig && routeConfig.paths) {
      const paths = _.cloneDeep(routeConfig.paths);
      if (currentLang && paths[currentLang]) {
        const toPath = pathToRegexp.compile(paths[currentLang]);
        newHref = toPath({ ...routeParams }, toPathOptions);
      }
    }
  }
  return newHref;
};

/**
 *
 * @param {object} param0
 * @param {props} param1
 */
export const getMapSizesToPropsCustom = () => ({ width }, { theme }) => {
  const { breakpoints } = theme;
  return {
    isLG: width >= breakpoints.lg,
    isMD: width >= breakpoints.md,
    isSM: width >= breakpoints.sm,
    isXS: width < breakpoints.sm,
    isSmallScreen: width < breakpoints.md
  };
};

/**
 *  Quick set obj prop according path
 *
 * @param {array} path
 * @param {*} value
 * @param {*} obj
 */
const setArrayPath = ([key, ...next], value, obj) => {
  if (next.length === 0) {
    return { ...obj, [key]: value };
  }
  return { ...obj, [key]: setArrayPath(next, value, obj[key]) };
};

export const setObjProp = (pathArr, value, obj) =>
  setArrayPath(pathArr, value, obj);

/**
 * Fortmat discount helper
 *
 * @param {string} discount
 */
export const formatDiscount = discount => '-' + discount.split(' ')[0];

/**
 * Format price
 * @param price
 * @param format
 * @returns {string|*}
 */
export const formatPrice = (price, format = DEFAULT_NUMERAL_PRICE_FORMAT) => {
  const priceInNumberFormatted = price && _.isString(price) ? price.replace('.', ',') : price;

  const num = numeral(priceInNumberFormatted);
  if (!_.isNil(num.value())) {
    return num.format(format);
  }
  return '';
};

/**
 * Generate unified cache KEY
 *
 * @param {string} key
 * @param {string} params
 */
export const getCacheKey = ({
  key,
  params = [],
  suffix = CACHE_KEY_SUFFIX
}) => {
  return sprintf(
    'grandus-front-%1$s%2$s%3$s',
    key,
    !_.isEmpty(params) ? '-'.concat(_.join(params, '-')) : '',
    suffix ? '-'.concat(suffix) : ''
  );
};

/**
 * get route parts from matchParams
 *
 * @param {string, int} id
 * @param {string} filters
 */
export const getRouteParts = (id, filters) => {
  const result = {};
  if (filters) {
    const parts = _.split(filters, '?');
    result.id = id;
    result.filtersString = parts[0] ? parts[0] : null;
    result.queryStringParams = parts[1] ? queryString.parse(parts[1]) : null;
  } else {
    const parts = id.split('?');
    result.id = parts[0];
    result.queryStringParams = parts[1] ? queryString.parse(parts[1]) : null;
  }
  return result;
};

/**
 * parse router filter string into vars
 *
 * @param {string} filtersString
 */
export const parseFilters = (
  filtersString,
  activeRoute = {},
  currentLang = null
) => {
  const filterTable = _.get(activeRoute, ['filterTable', currentLang], {});
  let activeApiFilters = {};
  let activeFilters = {};
  if (filtersString) {
    const filterTypes = _.split(filtersString, 'param');
    const filtersArr = _.trimEnd(filterTypes[0], '/').split('/');
    if (_.head(filtersArr) === 'filter') {
      activeApiFilters = _.flow(
        _.drop,
        _.compact,
        arr => _.chunk(arr, 2),
        _.fromPairs,
        _.pickBy,
        obj =>
          _.mapValues(obj, value =>
            _.includes(value, ',')
              ? _.map(value.split(','), v => decodeURI(v))
              : decodeURI(value)
          )
      )(filtersArr);
      if (!_.isEmpty(filterTable)) {
        const labelOnlyFilter = {};
        _.foreach(filterTable, (value, key) => {
          if (!_.isObject(value)) {
            labelOnlyFilter[key] = value;
          }
        });
        const advancedFilters = {};
        _.foreach(filterTable, (value, key) => {
          if (_.isObject(value)) {
            advancedFilters[key] = value;
          }
        })
        const invertedFilterTable = _.invert(labelOnlyFilter);
        let advancedFilter = null;
        let invertedFilterValues = null;
        _.forEach(activeApiFilters, (values, key) => {
          if (!_.isEmpty(invertedFilterTable[key])) {
            delete activeApiFilters[key];
            activeApiFilters[invertedFilterTable[key]] = values;
          } else {
            _.forEach(advancedFilters, (filterData, filterName) => {
              if (_.get(filterData, 'label') === key) {
                advancedFilter = filterData;
                invertedFilterValues = _.invert(_.get(filterData, 'values'));
                activeApiFilters[filterName] = null;
                if (_.isString(values)) {
                  activeApiFilters[filterName] = _.get(invertedFilterValues, [values], values);
                } else {
                  _.forEach(values, value => {
                    activeApiFilters[filterName].push(_.get(invertedFilterValues, [value], value));
                  });
                };
                delete activeApiFilters[key];
              }
            })
          }
        });
      }
      activeFilters = { ...activeApiFilters };
    }
    if (filterTypes[1]) {
      const paramFiltersArr = _.trim(filterTypes[1], '/').split('/');
      const activeParamFilters = _.flow(
        _.compact,
        arr => _.chunk(arr, 2),
        _.fromPairs,
        _.pickBy,
        obj =>
          _.mapValues(obj, (value, key) => {
            const keyParts = _.split(key, '-');
            const paramId = _.parseInt(_.last(keyParts));
            if (!_.isNaN(paramId)) {
              return _.includes(value, ',')
                ? _.map(value.split(','), v => decodeURI(v))
                : [decodeURI(value)];
            }
            return null;
          }),
        _.pickBy
      )(paramFiltersArr);
      if (!_.isEmpty(activeParamFilters)) {
        const activeApiParamFilters = _.flow(
          obj =>
            _.mapKeys(obj, (value, key) => {
              const keyParts = _.split(key, '-');
              const paramId = _.parseInt(_.last(keyParts));
              return !_.isNaN(paramId) ? _.toNumber(paramId) : null;
            }),
          obj => _.omit(obj, null)
        )(activeParamFilters);
        activeApiFilters = {
          ...activeApiFilters,
          ...{ param: { ...activeApiParamFilters } }
        };
        activeFilters = {
          ...activeFilters,
          ...{ param: { ...activeParamFilters } }
        };
      }
    }
  }
  if (activeApiFilters.priceRange) {
    const { priceRange } = activeApiFilters;
    const priceRangeValues = _.split(priceRange, '-', 2);
    if (_.isArray(priceRangeValues) && priceRangeValues.length === 2) {
      delete activeApiFilters.priceRange;
      activeApiFilters.priceFrom = _.toNumber(priceRangeValues[0]);
      activeApiFilters.priceTo = _.toNumber(priceRangeValues[1]);
    }
  }
  return { activeFilters, activeApiFilters };
};

/**
 *
 * @param {*} data
 * @param {*} key
 * @param {*} config
 */
export const orderFilterData = (data = {}, config = {}) => {
  if (_.isEmpty(data)) {
    return {};
  }
  if (!_.isEmpty(config)) {
    const cols = _.get(config, 'orderBy.columns', ['name']);
    const orders = _.get(config, 'orderBy.orders', ['asc']);
    return _.orderBy(data, cols, orders);
  }
  return data;
};

/**
 *
 * @param {array} categories
 */
export const getTopCategories = categories =>
  _.map(categories, category => _.omit(category, 'children'));

/**
 * @param {string} data
 */
export const getProductsIdsToCompareFromCookies = data => {
  const productIdsToCompareJSON = data;
  if (!_.isEmpty(productIdsToCompareJSON)) {
    const productIdsToCompare = JSON.parse(productIdsToCompareJSON);
    if (!_.isEmpty(productIdsToCompare)) {
      return productIdsToCompare;
    }
  }
  return {};
};

/**
 * get title from api webinstance
 *
 * @param {} pageState
 * @param {} globalSettings
 */
export const getTitle = (pageState, webInstance) => {
  let title = _.get(pageState, 'meta.title', '');
  if (!title) {
    title = _.get(webInstance, 'title', '');
  }
  return title;
};

/**
 * get custom meta from api webinstance
 *
 * @param {} pageState
 * @param {} globalSettings
 */
export const getCustomMeta = (pageState, globalSettings) => {
  const customMeta = {};
  if (!_.isEmpty(globalSettings)) {
    if (!_.isEmpty(globalSettings['google_owner_verification_code'])) {
      _.set(
        customMeta,
        'google-site-verification',
        globalSettings['google_owner_verification_code']
      );
    }
  }
  if (pageState) {
    const robots = _.get(pageState, 'meta.robots', '');
    if (robots) {
      _.set(customMeta, 'robots', robots);
    }
    _.set(customMeta, 'keywords', _.get(pageState, 'meta.keywords', ''));
    _.set(customMeta, 'description', _.get(pageState, 'meta.description', ''));
    _.set(customMeta, 'og:title', _.get(pageState, 'meta.title', ''));
    _.set(customMeta, 'og:keywords', _.get(pageState, 'meta.keywords', ''));
    _.set(
      customMeta,
      'og:description',
      _.get(pageState, 'meta.description', '')
    );

    let ogimage = _.get(pageState, 'meta.image', false);
    if (ogimage) {
      _.set(customMeta, 'og:image', ogimage.path);
      _.set(customMeta, 'og:image:secure_url', ogimage.path);
      _.set(customMeta, 'og:image:height', _.get(ogimage, 'height', 600));
      _.set(customMeta, 'og:image:width', _.get(ogimage, 'width', 1080));
    }
  }
  return customMeta;
};

/**
 * get google fonts url
 * @param {string} activeTheme
 */
export const getGoogleFonts = activeTheme => {
  let googleFonts = [];
  switch (activeTheme) {
    case 'mobilonline':
      googleFonts.push(
        'https://fonts.googleapis.com/css?family=Quicksand:400,700'
      );
      break;
    default:
      googleFonts.push(
        'https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap&subset=latin-ext'
      );
      break;
  }
  return googleFonts;
};

export const generateSrcSet = (source = null, generatedDensity) => {
  const sourceSrcSet = [];
  _.each(generatedDensity, density => {
    let srcSetElement = source;
    if (density > 1) {
      srcSetElement = _.replace(
        srcSetElement,
        '.jpg',
        '@' + density + 'x.jpg ' + density + 'x'
      );
      srcSetElement = _.replace(
        srcSetElement,
        '.png',
        '@' + density + 'x.png ' + density + 'x'
      );
      srcSetElement = _.replace(
        srcSetElement,
        '.webp',
        '@' + density + 'x.webp ' + density + 'x'
      );
    } else {
      srcSetElement += ' 1x';
    }
    sourceSrcSet.push(srcSetElement);
  });

  return sourceSrcSet;
};

/**
 * PRODUCT
 */

export const isOnStock = store => {
  return _.find(store, storeEntry => _.get(storeEntry, 'count', 0) > 0)
    ? true
    : false;
};

export const isStockOnTheWay = store => {
  const stocks = getStockOnTheWayInfo(store);
  let stockCumulative = 0;
  _.forEach(stocks, stock => {
    if (stock.storeDeliveryCount) {
      stockCumulative = stockCumulative + parseInt(stock.storeDeliveryCount, 10);
    }
  });
  return stockCumulative > 0 ? true : false;
};

export const getStockOnTheWayDate = store => {
  const stocks = getStockOnTheWayInfo(store);
  let date = null;
  _.forEach(stocks, stock => {
    let actualDate = null;
    const storeDeliveryDate = _.get(stock, 'storeDeliveryDate');
    if (storeDeliveryDate) {
      actualDate = new Date(storeDeliveryDate.substr(0, 10));
    }
    if (date === null) {
      date = actualDate;
    } else if (date && actualDate > date) {
      date = actualDate;
    }
  });

  if (date === null) {
    return '';
  }
  return date.toLocaleDateString('sk-SK');
};

export const getStockOnTheWayInfo = store => {
  let stocks = [];
  _.forEach(store, storeEntry => {
    _.forEach(_.get(storeEntry, 'storeData', []), storeDataEntry => {
      stocks.push(storeDataEntry);
    });
  });

  return stocks;
};

export const isProductUnavailable = product => {
  const { active, availability, store = {} } = product;
  return !active || (!availability && !isStockOnTheWay(store) && !isOnStock(store));
}

export const isOnSupplierStoreOnly = (stores, t) => {
  if (stores.length === 1) {
    const store = _.first(stores);
    const storeData = _.get(store, 'storeData', null);
    return (
      !_.isEmpty(storeData) &&
      storeData.length === 1 &&
      _.get(_.first(storeData), 'storeName', null) === t(SUPPLIER_STORE)
    );
  }

  return false;
};

export const getFilterHashToValuePairs = filter => {
  if (_.isEmpty(filter)) {
    return []
  };
  const hashToValue = [];
  let paramKey = '';
  _.forEach(filter, (filterItem, key) => {
    if (_.isObject(filterItem)) {
      _.forEach(filterItem, item => {
        if (key === 'parameters') {
          _.forEach(filterItem, parameter => {
            _.forEach(_.get(parameter, 'values', []), value => {
              paramKey = sprintf('%1$s-%2$s-%3$s', _.get(parameter, 'v2-name'), _.get(parameter, 'id'), _.get(value, 'v2-value'));
              _.set(hashToValue, paramKey, _.get(value, 'v2-label'));
            })
          })
        } else {
          _.set(hashToValue, sprintf('%1$s-%2$s', _.get(item, 'v2-name'), _.get(item, 'v2-value')), _.get(item, 'v2-label'));
        }
      })
    }
  });

  return hashToValue;
}

export const createTitleFromActiveFilters = (activeFilters, filter, blacklist = []) => {
  const hashToValue = getFilterHashToValuePairs(filter);
  let filterKeys = [];
  if (!_.isEmpty(filter)) {
    _.forEach(filter, (item, key) => {
      if (key === 'parameters') {
        filterKeys.push(..._.map(item, param => {
          if (param.type === 'slider' || _.includes(blacklist, _.get(param, 'v2-name'))) {
            return null;
          }
          return sprintf('%1$s-%2$s', _.get(param, 'v2-name'), _.get(param, 'id'));
        }))
      } else {
        filterKeys.push(_.get(_.first(item), 'v2-name', key));
      }
    });
  }
  if (!_.isEmpty(blacklist)) {
    filterKeys = _.omitBy(filterKeys, key => {
      return _.includes(blacklist, key);
    })
  }
  if (_.isEmpty(filterKeys)) {
    filterKeys = _.orderBy(_.keys(_.get(activeFilters, 'param'), []));
  }
  const titleSuffixesArray = [];
  let activeFilterValues = '';
  _.forEach(filterKeys, filterKey => {
    activeFilterValues = _.get(activeFilters, ['param', filterKey], _.get(activeFilters, filterKey, null));
    if (!_.isEmpty(activeFilterValues) && _.isArray(activeFilterValues)) {
      activeFilterValues = _.map(activeFilterValues, activeItem => {
        return _.get(hashToValue, (filterKey + '-' + activeItem), _.get(hashToValue, activeItem, null));
      })
    }
    if (activeFilterValues) {
      titleSuffixesArray.push(_.isArray(activeFilterValues) ? activeFilterValues.join('/') : _.get(hashToValue, (filterKey + '-' + activeFilterValues), null));
    }
  })
  return titleSuffixesArray.join(' | ');
}

export const roundValueTo = (value, roundTo = 1000) => {
  return Math.floor(value/roundTo)*roundTo
}

/**
 * Sets provided data to existing object
 * @param {*} object
 * @param {*} valuesObject
 */
export const setToObject = (object = {}, valuesObject = {}) => {
  if (_.isEmpty(valuesObject)) {
    return object;
  }
  _.forEach(valuesObject, (value, key) => {
    _.set(object, key, value);
  });
  return object;
}

export const saveContactValuesToCookie = (values, countries) => {
  const orderValues = {};
  orderValues['name'] = _.get(values, 'name', null);
  orderValues['surname'] = _.get(values, 'surname', null);
  orderValues['city'] = _.get(values, 'city', null);
  orderValues['street'] = _.get(values, 'street', null);
  orderValues['streetNumber'] = _.get(values, 'streetNumber', null);
  orderValues['zip'] = _.get(values, 'zip', null);
  orderValues['phone'] = _.get(values, 'phone', null);
  orderValues['email'] = _.get(values, 'email', null);
  orderValues['company'] = _.get(values, 'company', false);
  orderValues['companyName'] = _.get(values, 'companyName', null);
  orderValues['ico'] = _.get(values, 'ico', null);
  orderValues['dic'] = _.get(values, 'dic', null);
  orderValues['icDPH'] = _.get(values, 'icDPH', null);
  orderValues['countryId'] = _.get(values, 'country');
  orderValues['name2'] = _.get(values, 'name2', null);
  orderValues['surname2'] = _.get(values, 'surname2', null);
  orderValues['street2'] = _.get(values, 'street2', null);
  orderValues['city2'] = _.get(values, 'city2', null);
  orderValues['zip2'] = _.get(values, 'zip2', null);
  orderValues['email2'] = _.get(values, 'email2', null);
  orderValues['phone2'] = _.get(values, 'phone2', null);
  const countryId = _.get(countries, '[0].id');
  if (!orderValues['countryId'] && countryId) {
    _.set(orderValues, 'countryId', countryId);
  }
  Cookies.set(COOKIE_ORDER_CONTACT_STEP, orderValues, { expires: 1 });
}

/**
 * return element height without paddings
 * @param {*} element
 */
export const getElementHeight = element => {
  if (canUseDOM() && element) {
    let height = 0;
    try {
      height = window.getComputedStyle(element, null).getPropertyValue('height');
    } catch(e) {
      height = element.currentStyle.height;
    }
    return parseInt(height);
  }
  return 0;
}
/**
 * return element height without paddings
 * @param {*} element
 */
export const getElementOffsetTop = element => {
  if (canUseDOM() && element) {
    let paddingTop = 0;
    try {
      paddingTop = window.getComputedStyle(element, null).getPropertyValue('padding-top');
    } catch(e) {
      paddingTop = element.currentStyle.paddingTop;
    }
    return element.offsetTop + parseInt(paddingTop);
  }
  return 0;
}
/***
 * resolveApp
 *
 * @param {string} relativePath
 */
// Make sure any symlinks in the project folder are resolved:
// https://github.com/facebookincubator/create-react-app/issues/637
// const appDirectory = fs.realpathSync(process.cwd());
// export const resolveApp = relativePath => path.resolve(appDirectory, relativePath);
