import _ from 'lodash';
import chroma from 'chroma-js';
import defaultTheme, {
  defaultThemeFormControl,
  defaultThemeBorder
} from './default';

export default (colors = {}) => {
  const executedDefaultTheme = defaultTheme(colors);
  const customColors = {
    // custom theme specific colors
    success: colors.primary,
    facebook: '#395995',

    // backgrounds
    backgroundDarker: '#f4f4f4',
    backgroundSecondary: '#f4f4f4'
  };
  const finalColors = _.merge({}, executedDefaultTheme.colors, customColors, colors);
  return {
    colors: finalColors,
    searchBar: {
      height: '44px',
      borderRadius: '50px',
      icon: {
        background: 'transparent',
        color: finalColors.primary
      },
      input: {
        background: finalColors.backgroundSecondary
      }
    },
    cartButton: {
      border: '0',
      borderRadius: '50px', //TODO
      bagBackground: finalColors.primary,
      bagColor: finalColors.white,
      customStyle: `
        box-shadow: none;
        & > div:first-child {
          border-radius: 50px;
          background: ${finalColors.primary};
          padding: 6px;
          .icon {
            font-size: 15px !important;
          }
        };
        & > div:nth-child(2) {
          margin: 0 25px;
        };

        & + button {
          display: none;
        }
        @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
          box-shadow: inset 0 0 0 1px ${finalColors.primary};
        }
      `
    },
    compareButton: {
      customStyle: `
        color: ${chroma(finalColors.primary).alpha(0.5)};
        &:hover {
          color: ${finalColors.primary};
        }
      `
    },
    wishlistButton: {
      customStyle: `
        color: ${chroma(finalColors.primary).alpha(0.5)};
        &:hover {
          color: ${finalColors.primary};
        }
      `
    },
    // topBar
    topBar: {
      height: '37px',
      customStyle: ``
    },
    // button
    button: {
      background: '#ffffff',
      color: '#095856',
      border: '1px solid #B6E3E0',
      borderRadius: '50px', //TODO
      transition: defaultThemeFormControl.transition,
      enableDefaultShadow: false,
      textDecoration: 'none',
      padding: '10px',
      height: {
        big: '52px',
        normal: '44px',
        small: 'auto'
      },
      padding: {
        big: '0 1.6em',
        normal: '0 1.2em',
        small: '2px 10px'
      },
      fontSize: {
        big: '1.1em',
        normal: '0.9em',
        small: '13px'
      },
      hover: {
        textDecoration: 'none',
        background: '#00B8B5',
        color: '#ffffff',
        border: '1px solid #00B8B5',
      }
    },
    // button outline
    buttonOutline: {
      enableDefaultShadow: false
    },
    //navigation
    pagenav: {
      background: '#DBDBDF',
      borderTop: 'none',
      borderBottom: `none`,
      item: {
        fontSize: '0.8em',
        fontWeight: 'bold',
        height: '50px',
        color: '#005250',
        padding: '0 15px 0 0',
        borderLeft: '1px solid rgba(0,0,0,0.2)',
        hover: {
          color: finalColors.primary,
          background: '#DBDBDF'
        },
        linkTitle: {
          color: finalColors.xdgray
        }
      },
      mobile: {
        background: finalColors.white,
        item: {
          borderBottom: `1px solid ${finalColors.lgray}`
        }
      }
    },
    // breadcrumbs
    breadcrumbs: {
      listItem: {
        margin: '0 5px 0 0'
      },
      separator: {
        fontSize: '0.85em',
        color: finalColors.primary,
        margin: '0 0 0 5px'
      },
      text: {
        fontSize: '0.8em',
        fontWeight: 'bolder',
        color: finalColors.dgray
      },
      link: {
        fontSize: '0.8em',
        fontWeight: 'normal',
        color: finalColors.primary
      }
    },
    // productCard
    productCard: {
      border:
        defaultThemeBorder.basic.width +
        ' ' +
        defaultThemeBorder.basic.style +
        ' ' +
        defaultThemeBorder.basic.color,
      background: finalColors.backgroundSecondary,
      hover: {
        border:
          defaultThemeBorder.basic.width +
          ' ' +
          defaultThemeBorder.basic.style +
          ' ' +
          finalColors.white,
        background: finalColors.white,
        boxShadow: '0 12px 16px 0 rgba(35,31,32,0.1)'
      },
      image: {
        background: finalColors.white
      },
      name: {
        fontSize: '0.9em',
        minHeight: '65px',
        height: '65px'
      }
    },
    // product
    product: {
      fontSize: '0.95em',
      brand: {
        customStyle: `
          background: #AFDFDC;
          color: #095856;
          font-size: .7em;
          padding: 3px 10px;
          border-radius: 3px;
          font-weight: bold;
        `
      },
      form: {
        wrapper: {
          customStyle: `
            border: none;
          `
        }
      },
      tab: {
        fontWeight: 'normal',
        fontColor: finalColors.gray,
        fontWeight: 'bold',
        fontSize: '0.9em',
        background: finalColors.white,
        marginRight: '20px',
        padding: '15px 70px',
        borderRadius: '3px 3px 0 0',
        borderBottom: '1px solid #ced4da',
        active: {
          background: finalColors.white,
          fontColor: finalColors.primary,
        },
        hover: {
          textDecoration: 'none',
          fontColor: finalColors.primary,
        }
      }
    },
    headline: {
      h1: {
        customStyle: `justify-content: flex-start;`
      },
      h2: {
        customStyle: `
          display: block;
          text-align:center;
          font-weight: bolder;
          font-size: 1.9rem;
          line-height: 2rem;
          margin: 0;
          padding: 20px 0;
          @media (min-width: ${_.get( executedDefaultTheme, 'breakpoints.md')}px) {
            box-sizing: border-box;
            display: table;
            white-space: nowrap;
            margin: 10px 0 50px 0;
            width: 100%;
            font-size: 2.25rem;
            line-height: 2.25rem;
            &:before, &:after {
              border-top: 1px solid ${finalColors.lgray};
              content: '';
              display: table-cell;
              position: relative;
              top: 0.5em;
              width: 45%;
            }
            &:before { right: 1.5%; }
            &:after { left: 1.5%; }
          }
        `
      },
      h3: {
        customStyle: `
          font-weight: bolder;
        `
      },
      h4: {
        customStyle: `
          font-weight: bold;
        `
      }
    },
    blog: {
      element: {
        customStyle: `
          background-color: #005250;
          color: white;
          @media (max-width: ${_.get(
            executedDefaultTheme,
            'breakpoints.md'
          )}px) {
            padding: 10px 0;
          }
        `
      },
      card: {
        element: {
          customStyle: `
            & section section {
              section {
                margin-top: 10px;
                @media (mmin-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
                  margin-top: 0;
                }
                & strong {
                  color: #afdfdc;
                  font-weight: bolder;
                  font-size: 1.2em;
                }
                & > a {
                  color: #afdfdc;
                  font-weight: bolder;
                  font-size: 1.2em;
                  &:hover {
                    color: white;
                  }
                }
              }
              h3 {
                font-size: 1.2em;
                margin-bottom: 10px;
                color: white;
                @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
                  font-size: 1.6em;
                  margin-bottom: 30px;
                }
              }
              h5 {
                color: white;
                font-weight: bolder;
                font-size: 1.1em;
                display: none;
                @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
                  display: block;
                }
              }
              section div {
                line-height: 1.5em;
                margin-bottom: 30px;
                color: white;
                @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
                  line-height: 1.7em;
                }
              }
              a {
                color: white;
                font-weight: bolder;
                &:hover {
                  color: white;
                  text-decoration: underline;
                }
              }
            }
          `
        }
      }
    },
    testimonial: {
      element: {
        customStyle: `
          background-color: ${finalColors.backgroundSecondary};
          color: white;
          @media (max-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
            padding: 20px 0 10px 0;
          }

          h3 {
            text-align: center;
            font-size: 2em;
            margin: 0;
            span {
              text-align: center;
              width: 100%;
              display: inline-block;
            }
          }
        `
      },
      card: {
        customStyle: `
          && {
            min-height: 200px;
            margin-top: 30px;
            flex-direction: column;
            align-items: center;
            @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
              margin-top: 60px;
              align-items: flex-start;
              flex-direction: row;
            }

            img {
              border-radius: 50% !important;
            }
            h4 {
              font-size: 1.25em;
              font-weight: bold;
              margin-bottom: 5px;
              text-align: center;
              span {
                width: 100%;
              }
              @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
                text-align: left;
              }
            }
            h5 {
              font-size: 0.9em;
              margin-bottom: 15px;
              font-weight: bold;
              color: ${finalColors.primary};
              text-align: center;
              span {
                width: 100%;
              }
              @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
                text-align: left;
              }
            }
            div {
              max-width: 260px;
              color: #4c4c4c;
              line-height: 1.7em;
              font-size: 0.89em;
              text-align: center;
              @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
                text-align: left;
              }
            }
          }
        `
      }
    },
    contact: {
      element: {
        customStyle: `
          form {
            max-width: 100%;

            @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
              max-width: 70%;
              margin: 0 auto;
            }
          }
          h3 {
            text-align: center;
            font-size: 2em;
            margin: 0 0 30px 0;
            span {
              text-align: center;
              width: 100%;
              display: inline-block;
            }
          }

          && {
            input[type="text"],
            input[type="email"],
            textarea {
              color: ${finalColors.black};
              border-radius: 25px;
              background: ${finalColors.backgroundSecondary};
              border-color: #e2deec;
              padding: 10px 20px;
            }
            textarea {
              padding: 17px 20px;
            }
          }
        `
      },
    },
    carousels: {
      arrows: {
        background: finalColors.white,
        color: finalColors.primary,
        customStyle: `
          border-radius: 50px;
          -webkit-box-shadow: 0px 22px 34px -7px rgba(0,0,0,0.11);
          -moz-box-shadow: 0px 22px 34px -7px rgba(0,0,0,0.11);
          box-shadow: 0px 22px 34px -7px rgba(0,0,0,0.11);
          transition: 0.3s linear;
          &:hover {
            -webkit-box-shadow: 0px 22px 34px -7px rgba(0,0,0,0.3);
            -moz-box-shadow: 0px 22px 34px -7px rgba(0,0,0,0.3);
            box-shadow: 0px 22px 34px -7px rgba(0,0,0,0.3);
          }
        `,
        small: {
          width: '46px',
          height: '46px',
        },
        normal: {
          width: '64px',
          height: '64px',
        }
      }
    },
    // gallery
    gallery: {
      width: {
        xs: '100%',
        md: '100%'
      },
      arrow: {
        color: finalColors.primary
      },
      thumbnail: {
        margin: '5px',
        padding: '15px 5px',
        background: 'transparent',
        border: {
          color: '#EBE8F2',
          style: 'solid',
          width: '1px',
          radius: '3px'
        }
      }
    },
    // numstepper
    numstepper: {
      prev: {
        width: '50px',
        fontSize: '12px',
        customStyle: `
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          border-right: none;
          font-weight: bold;
          color: ${finalColors.primary};
          height: 30px;
          &:hover {
            color: ${finalColors.black};
            background: ${finalColors.white};
          }
          .icon {
            font-weight: 600;
          }
          @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
            height: 42px;
          }
        `
      },
      next: {
        width: '50px',
        fontSize: '12px',
        customStyle: `
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
          border-left: none;
          font-weight: bold;
          color: ${finalColors.primary};
          height: 30px;
          &:hover {
            color: ${finalColors.black};
            background: ${finalColors.white};
          }
          .icon {
            font-weight: 600;
          }
          @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
            height: 42px;
          }
        `
      },
      input: {
        color: finalColors.text,
        customStyle: `
          && {
            background-color: ${finalColors.white};
            border: ${defaultThemeBorder.basic.width} ${defaultThemeBorder.basic.style} ${defaultThemeBorder.basic.color};
            width: 80px;
            border-radius: 0;
            border-left: none;
            border-right: none;
            font-size: 0.93m;
            height: 30px;
            @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
              height: 42px;
            }
          }
        `
      }
    },
    // carousels
    newArrivalsCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },
    favoritesCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 6,
    },

    // banners
    banners: {
      homepage: {
        main: {
          customStyle: `
            display: none;
            @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
              display: block;
            }
          `
        },
        secondary: {
          customStyle: `
            display: none;
            @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
              display: block;
            }
          `
        },
        third: {
          customStyle: `
            display: none;
            @media (min-width: ${_.get(executedDefaultTheme, 'breakpoints.md')}px) {
              display: block;
            }
          `
        }
      }
    },
    // labels
    labels: {
      discount: {
        background: '#ed1c24',
        color: '#ffdd00'
      },
      status: {
        border: '1px solid ' + colors.primary,
        color: colors.primary,
        background: '#fff'
      }
    },
    // footer
    footer: {
      categoryIndex: 0,
      customStyle: `
        font-size: 0.95em;
        font-weight: 600;

        ul {
          li {
            line-height: 1.8em;
          }
          &.categories > li {
            &::before {
              content: '';
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 5px 0 5px 5px;
              border-color: transparent transparent transparent ${finalColors.primary};
              display: inline-block;
              margin-right: 5px;
            }
          }
        }
      `
    },
    newsletter: {
      customStyle: `
        p {
          font-size: 0.95em;
          line-height: 1.6em;
        }
      `
    }
  };
};
