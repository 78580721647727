export const STATUS = {
  CODE_OK: 200,
  CODE_500: 500,
  CODE_403: 403,
  CODE_404: 404
};

export const HEADER = {
  WEBINSTANCE_TOKEN: 'WebInstance-Token',
  OWNER_TOKEN: 'Owner-Token',
  AUTHORIZARION: 'Authorization',
  URI: 'URI'
};

export const METHOD = {
  HEAD: 'HEAD',
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DEL',
  PUT: 'PUT',
  PATCH: 'PATCH'
};
