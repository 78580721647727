import { TOGGLE_INFO_MESSAGE } from 'common/actions/InfoActions';

const initialState = {
  message: {
    hidden: true
  }
};

const InfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_INFO_MESSAGE:
      return Object.assign({}, state, { message: { hidden: false } });
    default:
      return state;
  }
};

export default InfoReducer;
