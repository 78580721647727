import _ from 'lodash';
import Cookies from 'js-cookie';
import { message } from 'common/components/message/_default';
import { setLoading } from './PageActions';

import { METHOD } from '../constants/HttpConstants';
import apiFront from '../utils/apiFront';

import {
  COOKIE_LISTING_LAYOUT,
  COOKIE_EXPIRE_TIME,
  DEFAULT_LISTING_LAYOUT
} from 'common/constants/SiteConstants';

export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_PAGE_LISTING_LAYOUT = 'SET_PAGE_LISTING_LAYOUT';
export const TOGGLE_SIDEMENU = 'TOGGLE_SIDEMENU';
export const TOGGLE_USER_SIDEMENU = 'TOGGLE_USER_SIDEMENU';
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS';

const setAppData = (type, payload) => {
  return { type, payload };
};

export const toggleFilters = () => {
  return { type: TOGGLE_FILTERS };
};

export const toggleSideMenu = () => {
  return { type: TOGGLE_SIDEMENU };
};

export const toggleUserSideMenu = () => {
  return { type: TOGGLE_USER_SIDEMENU };
};

export const subscribeToNewsletter = email => async (dispatch, getState) => {
  message.info('newsletter', 'subscribing to newsletter list');
  const { app } = getState();
  const { webInstance } = app;
  if (webInstance) {
    const success = await dispatch(
      apiFront({
        endpoint: 'newsletter',
        method: METHOD.POST,
        options: { data: { email } },
        showToastr: true,
        toastrParts: {
          title: 'newsletter'
        },
        returnData: false
      })
    );
    if (success === true) {
      message.success('newsletter', 'successfully subscribed!');
    }
  }
};

export const changeListingLayout = (newListingLayout = DEFAULT_LISTING_LAYOUT) => (dispatch, getState) => {
  message.info('settings', 'changing product listing layout and saving as default');
  Cookies.set(COOKIE_LISTING_LAYOUT, newListingLayout, { expires: COOKIE_EXPIRE_TIME });
  dispatch(setAppData(SET_PAGE_LISTING_LAYOUT, { newListingLayout }));
};

export const setCountries = countries => {
  return {
    type: SET_COUNTRIES,
    payload: {
      countries
    }
  };
};

export const fetchCountries = () => async (dispatch, getState) => {
  const { app = {} } = getState();
  const { webInstance } = app;
  if (webInstance) {
    const countries = await dispatch(
      apiFront({
        endpoint: 'countries',
        method: METHOD.GET,
        cacheKey: 'countries'
      })
    );
    if (!_.isEmpty(countries)) {
      dispatch(setCountries(countries));
    }
  }
};

export const submitContactForm = (
  values = null,
  showLoading = false,
  onSuccessSubmitCallback = null
) => async (dispatch, getState) => {
  if (showLoading) {
    dispatch(setLoading(true));
  }
  const company = _.get(values, 'company', '');
  if (!_.isEmpty(values)) {
    const response = await dispatch(
      apiFront({
        method: METHOD.POST,
        endpoint: 'contact_form',
        options: {
          data: {
            name: _.get(values, 'name', '') + (company ? ' - ' + company : ''),
            email: _.get(values, 'email', ''),
            subject: _.get(values, 'subject', ''),
            text: _.get(values, 'message', ''),
            uri: _.get(values, 'uri', '')
          }
        },
      })
    );
    if (response) {
      const messageText = _.get(response, 'message', '');
      if (_.get(response, 'status', false)) {
        message.success('contact', messageText);
        if (onSuccessSubmitCallback && _.isFunction(onSuccessSubmitCallback)) {
          onSuccessSubmitCallback();
        }
      } else {
        message.error('contact', messageText);
      }
    } else {
      message.error(
        'contact',
        'unexpected error occured. Please try again later.'
      );
    }
  }
  if (showLoading) {
    dispatch(setLoading(false));
  }
};
