import { SET_SEARCH_RESULTS } from 'common/actions/SearchActions';

const initialState = {};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_RESULTS:
      return { ...state, ...{ searchResults: action.payload.results } };
    default:
      return state;
  }
};

export default SearchReducer;
