export default
  typeof window !== 'undefined'
    ? {
        // client
        sentryDNS: window.__ENV__.sentryDNS,
        reactiveVersion: window.__ENV__.reactiveVersion,
        reactiveEnvironment: window.__ENV__.reactiveEnvironment
      }
    : {
        // server
        sentryDNS: process.env.RAZZLE_SENTRY_DNS,
        reactiveVersion: process.env.npm_package_version,
        reactiveEnvironment: process.env.RAZZLE_REACTIVE_ENVIRONMENT
      };
