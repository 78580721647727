import { createSelector } from 'reselect';
import _ from 'lodash';

const getUser = state => {
  const { user } = state;
  return user;
};

export const getUserSelector = () =>
  createSelector(
    [getUser],
    user => user
  );

export const isUserLoggedSelector = () =>
  createSelector(
    [getUser],
    user => !_.isEmpty(user) && !_.isNil(user.accessToken)
  );

export const getWishlistItemsCountSelector = () =>
  createSelector(
    [getUser],
    user => _.get(user, 'wishlistItemsCount', 0)
  );

export const getWishlistsItemsSelector = () =>
  createSelector(
    [getUser],
    user => _.get(user, 'wishlist.items', 0)
  );
