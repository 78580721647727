import React from 'react';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { withCssUnit } from 'common/components/styleguide/utils/index';
import _ from 'lodash';

const StyledButton = styled(
  ({ component, children, fullWidth, buttonSize, buttonTextType, buttonType, enableBackground, margin, marginConfig, ...props }) => {
    const Component = component === 'button' && props.href ? 'a' : component;
    return <Component {...props}>{children}</Component>;
  }
)`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  transition: ${({ theme }) => _.get(theme, 'button.transition')};
  font-size: ${({ theme, buttonSize }) => _.get(theme, ['button', 'fontSize', buttonSize], 'initial')};
  font-weight: ${({ theme }) => _.get(theme, 'button.fontWeight', 'inherit')};
  text-decoration: ${({ theme }) => _.get(theme, 'button.textDecoration', 'none')};
  -webkit-appearance: none !important;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: ${({ theme, buttonSize }) =>
    _.get(theme, ['button', 'height', buttonSize], _.get(theme, ['button.height.normal']))};
  padding: ${({ theme, buttonSize }) =>
    _.get(theme, ['button', 'padding', buttonSize], _.get(theme, ['button.padding.normal']))};
  color: ${({ theme, buttonTextType, buttonType }) =>
    _.get(theme,['colors', buttonTextType], _.get(theme, ['colors', buttonType], _.get(theme, 'colors.primary')))};

  background: transparent;

  ${({ theme, buttonType, enableBackground }) => {
    const baseColor = chroma(_.get(theme, ['colors', buttonType], _.get(theme, 'colors.primary'))).hex();
    let result = !enableBackground ? `` : `background: ${chroma(baseColor).brighten(2.3).alpha(0.3)};`;

    if (_.get(theme, 'buttonOutline.enableDefaultShadow', false)) {
      const shadowColor = chroma(baseColor).brighten(0.5).alpha(0.3);
      result = result + `
        -webkit-box-shadow: 0px 2px 7px 0px ${shadowColor};
        -moz-box-shadow: 0px 2px 7px 0px ${shadowColor};
        box-shadow: 0px 2px 7px 0px ${shadowColor};`;
    }

    return result;
  }}

  border: ${({ theme }) =>
    _.get(theme, ['buttonOutline', 'border'], '1px')};
  border-color: ${({ theme, buttonType }) =>
    _.get(theme, ['colors', buttonType], _.get(theme, 'colors.primary'))};
  border-radius: ${({ theme }) =>
    _.get(theme, ['buttonOutline', 'borderRadius'], '0px')};

  margin: ${({ theme, margin, marginConfig }) => {
    return margin ? margin : ({theme, marginConfig}) => {
      let finalMargin = '';
      const defaultMargin = withCssUnit(_.get(theme, 'gutterWidth', 0));
      finalMargin = _.get(marginConfig, 'top', false) ? defaultMargin + ' ' : '0 ';
      finalMargin = finalMargin + (_.get(marginConfig, 'right', false) ? defaultMargin + ' ' : '0 ');
      finalMargin = finalMargin + (_.get(marginConfig, 'bottom', false) ? defaultMargin + ' ' : '0 ');
      finalMargin = finalMargin + (_.get(marginConfig, 'left', false) ? defaultMargin + ' ' : '0 ');
      return finalMargin;
    };
  }};
  &:focus {
    outline: none;
  }
  &:hover:not([disabled]) {
    text-decoration: ${({ theme }) => _.get(theme, 'buttonOutline.hover.textDecoration', 'none')};
    color: ${({ theme, buttonTextType, buttonType }) =>
      chroma(
        _.get(
          theme,
          ['colors', buttonTextType],
          _.get(
            theme,
            ['colors', buttonType],
            _.get(theme, 'colors.primary')
          )
        )

      )
      .darken(1)
      .hex()};
    border-color: ${({ theme, buttonType }) =>
    chroma(
      _.get(
        theme,
        ['colors', buttonType],
        _.get(theme, 'colors.primary')
      )
    )
    .darken(1)
    .hex()};
    ${({ theme, buttonType, enableBackground }) => {
      const baseColor = chroma(_.get(theme, ['colors', buttonType], _.get(theme, 'colors.primary'))).darken(1).hex();
      let result = !enableBackground ? `` : `background: ${chroma(baseColor).brighten(2.3).alpha(0.3)};`;
      if (_.get(theme, 'buttonOutline.enableDefaultShadow', false)) {
        const shadowColor = chroma(baseColor).alpha(0.5);
        result = result + `
          -webkit-box-shadow: 0px 2px 7px -2px ${shadowColor};
          -moz-box-shadow: 0px 2px 7px -2px ${shadowColor};
          box-shadow: 0px 2px 7px -2px ${shadowColor};`;
      }
      return result;
    }}
    .icon {
      text-decoration: none;
      &:after, &:before {
        text-decoration: none;
      }
    }
  }
  &:active:not([disabled]) {
    -webkit-box-shadow: 0px 0px 0px 5px ${({theme, buttonType}) => chroma(_.get(theme, ['colors', buttonType])).alpha(0.3)};
    -moz-box-shadow: 0px 0px 0px 5px ${({theme, buttonType}) => chroma(_.get(theme, ['colors', buttonType])).alpha(0.3)};
    box-shadow: 0px 0px 0 4px ${({theme, buttonType}) => chroma(_.get(theme, ['colors', buttonType])).alpha(0.3)};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const ButtonOutline = ({
  href,
  children,
  theme,
  component = 'button',
  buttonType = 'primary',
  buttonTextType = null,
  buttonSize = 'normal',
  type = 'button',
  fullWidth = false,
  className = '',
  onClick,
  rel,
  title = '',
  enableBackground = false,
  margin = false,
  marginConfig = {top:false, left: false, bottom: false, right: false}
}) => {
  return (
    <StyledButton
      href={href}
      type={type}
      theme={theme}
      component={component}
      buttonType={buttonType}
      buttonTextType={buttonTextType}
      buttonSize={buttonSize}
      fullWidth={fullWidth}
      className={className}
      onClick={onClick}
      rel={rel}
      title={title}
      enableBackground={enableBackground}
      margin={margin}
      marginConfig={marginConfig}
    >
      {children}
    </StyledButton>
  );
};

export default React.memo(ButtonOutline);
