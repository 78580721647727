import _ from 'lodash';

/**
 * Return unit with px if just number
 *
 * @param {number/string} number
 */
export const withCssUnit = number => {
  return _.isNumber(number) ? number + 'px' : number;
};
