import React from 'react';
import styled, { css } from 'styled-components';
import chroma from 'chroma-js';
import { withCssUnit } from 'common/components/styleguide/utils/index';
import _ from 'lodash';

const StyledButton = styled(
  ({ component, children, fullWidth, buttonSize, buttonType, margin, marginConfig, customStyle, ...props }) => {
    const Component = component === 'button' && _.get(props, 'href') ? 'a' : component;
    return <Component {...props}>{children}</Component>;
  }
)`
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  transition: ${({ theme }) => _.get(theme, 'button.transition')};
  font-size: ${({ theme, buttonSize }) => _.get(theme, ['button', 'fontSize', buttonSize], 'initial')};
  font-weight: ${({ theme }) => _.get(theme, 'button.fontWeight', 'inherit')};
  border: none;
  text-decoration: ${({ theme }) => _.get(theme, 'button.textDecoration', 'none')};
  -webkit-appearance: none !important;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: ${({ theme, buttonSize }) =>
    _.get(theme, ['button', 'height', buttonSize], _.get(theme, ['button.height.normal']))};
  padding: ${({ theme, buttonSize }) =>
    _.get(theme, ['button', 'padding', buttonSize], _.get(theme, ['button.padding.normal']))};
  color: ${({ theme, buttonType }) => {
    const contrast = chroma.contrast(
      _.get(theme, ['colors', buttonType], _.get(theme, 'colors.primary')),
      _.get(theme, 'colors.white')
    );
    return contrast >= 1.5 ? _.get(theme, 'colors.white') : _.get(theme, 'colors.text');
  }};

  ${({ theme, buttonType }) => {
    const baseColor = chroma(_.get(theme, ['colors', buttonType], _.get(theme, 'colors.primary'))).hex();
    let result = `background: ${baseColor};`;

    if (_.get(theme, 'button.enableDefaultShadow', false)) {
      const shadowColor = chroma(baseColor).brighten(0.5).alpha(0.5);
      result = result + `
        -webkit-box-shadow: 0px 2px 7px 0px ${shadowColor};
        -moz-box-shadow: 0px 2px 7px 0px ${shadowColor};
        box-shadow: 0px 2px 7px 0px ${shadowColor};`;
    }

    return result;
  }}
  border-radius: ${({ theme }) =>
    _.get(theme, ['button', 'borderRadius'], _.get(theme, 'border.default.radius', 0))};
  margin: ${({ theme, margin, marginConfig }) => {
    return margin ? margin : ({theme, marginConfig}) => {
      let finalMargin = '';
      const defaultMargin = withCssUnit(_.get(theme, 'gutterWidth', 0));
      finalMargin = _.get(marginConfig, 'top', false) ? defaultMargin + ' ' : '0 ';
      finalMargin = finalMargin + (_.get(marginConfig, 'right', false) ? defaultMargin + ' ' : '0 ');
      finalMargin = finalMargin + (_.get(marginConfig, 'bottom', false) ? defaultMargin + ' ' : '0 ');
      finalMargin = finalMargin + (_.get(marginConfig, 'left', false) ? defaultMargin + ' ' : '0 ');
      return finalMargin;
    };
  }};
  &:hover:not([disabled]) {
    text-decoration: ${({ theme }) => _.get(theme, 'button.hover.textDecoration', 'none')};
    ${({ theme, buttonType }) => {
      const baseColor = chroma(_.get(theme, ['colors', buttonType], _.get(theme, 'colors.primary'))).darken(0.3).hex();
      let result = `background: ${baseColor};`;
      if (_.get(theme, 'button.enableDefaultShadow', false)) {
        const shadowColor = chroma(baseColor).alpha(1);
        result = result + `
          -webkit-box-shadow: 0px 2px 7px -2px ${shadowColor};
          -moz-box-shadow: 0px 2px 7px -2px ${shadowColor};
          box-shadow: 0px 2px 7px -2px ${shadowColor};`;
      }
      return result;
    }}
    color: ${({ theme, buttonType }) => {
      const contrast = chroma.contrast(
        _.get(theme, ['colors', buttonType], _.get(theme, 'colors.primary')),
        _.get(theme, 'colors.white')
      );
      return contrast >= 1.5 ? _.get(theme, 'colors.white') : _.get(theme, 'colors.text');
    }};
  }

  &:active:not([disabled]) {
    -webkit-box-shadow: 0px 0px 0px 5px ${({theme, buttonType}) => chroma(_.get(theme, ['colors', buttonType])).alpha(0.3)};
    -moz-box-shadow: 0px 0px 0px 5px ${({theme, buttonType}) => chroma(_.get(theme, ['colors', buttonType])).alpha(0.3)};
    box-shadow: 0px 0px 0 4px ${({theme, buttonType}) => chroma(_.get(theme, ['colors', buttonType])).alpha(0.3)};
  }
  &:disabled {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  ${({ customStyle }) => css`
    ${customStyle}
  `}
`;

const Button = ({
  href,
  children,
  component = 'button',
  buttonType = 'primary',
  buttonSize = 'normal',
  type = 'button',
  fullWidth = false,
  className = '',
  onClick,
  margin = false,
  marginConfig = {top:false, left: false, bottom: false, right: false},
  customStyle,
  dataId = ''
}) => {
  return (
    <StyledButton
      href={href}
      type={type}
      component={component}
      buttonType={buttonType}
      buttonSize={buttonSize}
      fullWidth={fullWidth}
      className={className}
      onClick={onClick}
      margin={margin}
      marginConfig={marginConfig}
      customStyle={customStyle}
      data-id={dataId}
    >
      {children}
    </StyledButton>
  );
};

export default React.memo(Button);
