import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import appReducer from './AppReducer';
import userReducer from './UserReducer';
import infoReducer from './InfoReducer';
import menuReducer from './MenuReducer';
import pageReducer from './PageReducer';
import cartReducer from './CartReducer';
import searchReducer from './SearchReducer';
import compareReducer from './CompareReducer';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  toastr: toastrReducer,
  info: infoReducer,
  page: pageReducer,
  cart: cartReducer,
  menu: menuReducer,
  search: searchReducer,
  compare: compareReducer
});

export default rootReducer;
