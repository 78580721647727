import request from 'superagent';
import _ from 'lodash';
import { sprintf } from 'sprintf-js';

import { message } from 'common/components/message/_default';

import { prepareApiRequest, removeUser, log } from './index';

import { METHOD } from '../constants/HttpConstants';

//TODO: back!
//const cache = require('memory-cache');
const cache = null;

const DEFAULT_CACHE_TIME = parseInt(process.env.RAZZLE_CACHE_TIME_BACKEND_API_REQUEST, 10);

/**
 * Makes an REST api call to defined action using defined method (GET, POST, ...)
 * with defined options (data, headers, queryParams)
 *
 * During loading a application wide loader is dispatched.
 *
 * @param string endpoint
 * @param string method - defining HTTP method, see /src/javascript/constants/apiRoutes
 * @param string options - object defining "data" for POST, PUT, PATCH, DELETE methods, "headers" and, "queryParams"
 * @param boolean showToastr - show modal message
 * @returns {Promise.<T>}
 */
const apiFront = ({
  endpoint,
  method = METHOD.GET,
  options = {},
  toastrParts = {},
  endpointParams = {},
  showToastr = false,
  returnData = true,
  returnResponseMap = null,
  newUser = null,
  cacheKey = null,
  disableCache = false,
  cacheTime = DEFAULT_CACHE_TIME
}) => async (dispatch, getState) => {
  let { user } = getState();
  const { app } = getState();
  const { webInstance } = app;
  if (!_.isEmpty(newUser)) {
    user = newUser;
  }
  const reqPrep = prepareApiRequest({
    endpoint,
    method,
    options,
    webInstance,
    user,
    endpointParams
  });
  let fullCacheKey = null;
  if (!disableCache && returnData && method === METHOD.GET && cache) {
    fullCacheKey =
      sprintf(
        'webinstance-%1$s-%2$s-%3$s-%4$s-%5$s',
        webInstance.id,
        JSON.stringify(options),
        JSON.stringify(endpointParams),
        JSON.stringify(endpoint),
        JSON.stringify(_.get(user, 'accessToken', ''))
      ) + cacheKey;
    const result = cache.get(fullCacheKey);
    if (result) {
      log('From cache with key : ', fullCacheKey);
      return result;
    }
  }
  try {
    const res = await request[reqPrep.method](reqPrep.url)
      .set(reqPrep.headers)
      .send(reqPrep.noRequestBody ? undefined : reqPrep.data)
      .query(reqPrep.query);
    const responseUser = _.get(res, 'body.user', null);
    if (reqPrep.checkUser && _.isEmpty(responseUser)) {
      removeUser(dispatch);
    }
    if (res.body.success === true) {
      let result = true;
      if (returnData) {
        result = {};
        if (!_.isEmpty(returnResponseMap)) {
          _.each(returnResponseMap, (path, key) => {
            const value = _.get(res, path, null);
            if (value) {
              result[key] = value;
            }
          });
        } else {
          result = res.body.data;
        }
      }
      if (fullCacheKey) {
        cache.put(fullCacheKey, result, cacheTime);
      }
      return result;
    }
    return returnData ? null : false;
  } catch (err) {
    log(err);
    const messages = _.get(err, 'response.body.data.messages', null);
    const responseUser = _.get(err, 'response.body.user', null);
    if (reqPrep.checkUser && _.isEmpty(responseUser) && !_.isUndefined(err.status)) {
      removeUser(dispatch);
    }
    if (messages && showToastr) {
      const title = toastrParts.title ? toastrParts.title : 'unknown error';
      const body = _.map(messages, obj => {
        return obj.message;
      });
      message.error(title, body.join('<br />'));
    }
    return returnData ? null : false;
  }
};

export default apiFront;
