import _ from 'lodash'
import queryString from 'query-string';
import Cookies from 'js-cookie';
import ReactDOM from 'react-dom';

import { getPagePaginationSelector } from 'common/selectors/page';
import { getActiveRouteNameSelector, getThemeConfigSelector } from 'common/selectors/index';
import apiFront from 'common/utils/apiFront';
import { setPaginationFromHeaders } from 'common/utils/index';
import scrollIntoView from 'scroll-into-view-if-needed';
import { canUseDOM } from 'common/utils';

import { METHOD } from 'common/constants/HttpConstants';
import { COOKIE_LAST_SEEN } from 'common/constants/ProductConstants';
import { DEFAULT_PAGE, DEFAULT_PAGE_LIMIT, COOKIE_EXPIRE_TIME } from 'common/constants/SiteConstants';

export const SET_PAGE_DATA = 'SET_PAGE_DATA';
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';

export const setPageData = (data, pageName, propName = '') => {
  return { type: SET_PAGE_DATA, payload: { data, pageName, propName } };
};

export const setLoading = isLoading => {
  return { type: SET_PAGE_LOADING, payload: { isLoading } };
};

export const setPageOrderBy = (newOrderBy, history) => (dispatch, getState) => {
  const { app, page } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  const orderBy = _.get(page, [activePage, 'orderBy'], null);
  if (orderBy !== newOrderBy) {
    dispatch(setPageData(newOrderBy, activePage, 'orderBy'));
    dispatch(filterPageProducts({ history }));
  }
};

export const changePerPage = (perPage, history) => (dispatch, getState) => {
  const getPagePagination = getPagePaginationSelector();
  const getActiveRouteName = getActiveRouteNameSelector();
  const pagination = getPagePagination(getState());
  const activeRouteName = getActiveRouteName(getState());
  const newPagination = { ...pagination, ...{ perPage } };
  dispatch(setPageData(newPagination, activeRouteName, 'pagination'));
  dispatch(filterPageProducts({ resetPage: false, history }));
};

export const changePagePagination = (nextPage, history) => (dispatch, getState) => {
  const { app, page } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  const pagination = _.get(page, [activePage, 'pagination'], null);
  const currentPage = _.toNumber(_.get(pagination, 'currentPage', DEFAULT_PAGE));
  if (nextPage !== currentPage) {
    const newPagination = { ...pagination, ...{ currentPage: nextPage } };
    dispatch(setPageData(newPagination, activePage, 'pagination'));
    dispatch(filterPageProducts({ resetPage: false, history }));
  }
};

export const filterPageProducts = ({ newFilters = {}, resetPage = true, history = null }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { app, page } = state;
  const { webInstance, activeRoute, currentLanguage } = app;
  const activePage = _.get(activeRoute, 'name', null);
  const activeFilters = _.cloneDeep(_.get(page, [activePage, 'activeFilters'], {}));
  const filterTable = _.get(activeRoute, ['filterTable', currentLanguage], {});
  const pagination = _.get(page, [activePage, 'pagination'], null);
  const search = _.get(page, [activePage, 'data', 'search'], null);
  const categoryName = _.get(page, [activePage, 'data', 'urlName'], '');
  const orderBy = _.get(page, [activePage, 'orderBy'], null);
  const getThemeConfig = getThemeConfigSelector();
  const configPagination = _.get(getThemeConfig(state), 'pagination');
  const {
    currentPage = _.get(configPagination, 'page', DEFAULT_PAGE),
    perPage = _.get(configPagination, 'perPage', DEFAULT_PAGE_LIMIT)
  } = pagination;
  // active filters for store
  let newActiveFilters = {};
  newActiveFilters = _.pickBy({ ...activeFilters, ...newFilters });
  if (newFilters.param === null) {
    delete newActiveFilters.param;
  }
  // data for api
  let filterData = {};
  if (search) {
    filterData.search = search;
  } else {
    filterData.categoryName = categoryName;
  }
  let filterDataFilters = {};
  if (newActiveFilters.param) {
    const newParamFilters = {};
    _.each(newActiveFilters.param, (value, key) => {
      const keyParts = _.split(key, '-');
      const paramId = _.parseInt(_.last(keyParts));
      if (!_.isNaN(paramId)) {
        newParamFilters[paramId] = _.isArray(value) ? value : [value];
      }
    });
    if (!_.isEmpty(newParamFilters)) {
      filterDataFilters = { ...newActiveFilters, ...{ param: newParamFilters } };
    } else {
      filterDataFilters = { ...newActiveFilters };
    }
  } else {
    filterDataFilters = { ...newActiveFilters };
  }
  filterData = { ...filterData, ...filterDataFilters };
  if (orderBy) {
    filterData.orderBy = orderBy;
  }
  if (filterData.priceRange) {
    const { priceRange } = filterData;
    if (!_.isEmpty(priceRange)) {
      const { priceRange } = filterData;
      const priceRangeValues = _.split(priceRange, '-', 2);
      if (_.isArray(priceRangeValues) && priceRangeValues.length === 2) {
        delete filterData.priceRange;
        filterData.priceFrom = _.toNumber(priceRangeValues[0]);
        filterData.priceTo = _.toNumber(priceRangeValues[1]);
      }
    } else {
      delete filterData.priceRange;
      if (filterData.priceFrom) {
        delete filterData.priceFrom;
      }
      if (filterData.priceTo) {
        delete filterData.priceTo;
      }
    }
  }
  if (filterData.availability) {
      filterData.availability = true;
  } else {
    delete filterData.availability;
  }
  dispatch(setLoading(true));
  const response = await dispatch(
    apiFront({
      endpoint: 'products_filter',
      webInstance: webInstance,
      method: METHOD.POST,
      options: {
        data: filterData
      },
      endpointParams: {
        page: resetPage ? _.get(configPagination, 'page', DEFAULT_PAGE) : currentPage,
        limit: resetPage ? _.get(configPagination, 'perPage', DEFAULT_PAGE_LIMIT) : perPage
      },
      returnResponseMap: {
        data: 'body.data',
        headers: 'headers'
      }
    })
  );
  if (!_.isEmpty(response.data)) {
    const { data, headers } = response;
    const newPagination = setPaginationFromHeaders(headers);
    dispatch(setPageData(newActiveFilters, activePage, 'activeFilters'));
    dispatch(setPageData(data, activePage, 'products'));
    dispatch(setPageData(newPagination, activePage, 'pagination'));
    const filter = await dispatch(
      apiFront({
        endpoint: 'filter',
        webInstance: webInstance,
        method: METHOD.POST,
        options: {
          data: filterData
        }
      })
    );
    if (!_.isEmpty(filter)) {
      dispatch(setPageData(filter, activePage, 'filter'));
      dispatch(setLoading(false));
      if (history) {
        let newQueryString = { page: currentPage, limit: perPage };
        if (resetPage) {
          newQueryString = {};
          if (orderBy) {
            newQueryString.orderBy = orderBy;
          }
        }
        if (orderBy) {
          newQueryString.orderBy = orderBy;
        }
        setPathNameFiltersAndSearch(history, newActiveFilters, queryString.stringify(newQueryString), filterTable);
      }
    }
  } else {
    dispatch(setLoading(false));
  }
};

const setPathNameFiltersAndSearch = (history, allFilters, queryString = '', filterTable = {}) => {
  const { pathname } = history.location;
  const pathnameBeforeFilter = _.trimEnd(pathname.split('filter')[0], '/');
  let filters = _.cloneDeep(allFilters);
  let paramFilters = null;
  if (filters['param']) {
    paramFilters = _.cloneDeep(filters['param']);
    delete filters['param'];
  }
  const labelOnlyFilter = {};
    _.foreach(filterTable, (value, key) => {
      if (!_.isObject(value)) {
        labelOnlyFilter[key] = value;
      }
    });
  const advancedFilters = {};
    _.foreach(filterTable, (value, key) => {
      if (_.isObject(value)) {
        advancedFilters[key] = value;
      }
    })
  let filterPair = '';
  let filterValues = '';
  let filterPairs = _.map(filters, (value, name) => {
    filterPairs = '';
    filterValues = '';
    if (_.get(labelOnlyFilter, name)) {
      filterPair = [labelOnlyFilter[name] ? labelOnlyFilter[name] : name]
      filterValues = value;
    } else if (_.get(advancedFilters, name)) {
      filterPair = [_.get(advancedFilters, [name, 'label'], name)];
      filterValues = [];
      if (_.isString(value)) {
        filterValues.push(_.get(advancedFilters, [name, 'values', value], value));
      } else {
        _.forEach(value, filterValue => {
          filterValues.push(_.get(advancedFilters, [name, 'values', filterValue], filterValue));
        })
      }
    } else {
      filterPair = [name];
      filterValues = value;
    }
    if (_.isArray(filterValues)) {
      filterPair.push(filterValues.join(','));
    } else {
      filterPair.push(filterValues);
    }
    return filterPair.join('/');
  });
  if (paramFilters) {
    let filterParamPairs = _.map(paramFilters, (value, name) => {
      const filterPair = [name];
      if (_.isArray(value)) {
        filterPair.push(value.join(','));
      } else {
        filterPair.push(value);
      }
      return filterPair.join('/');
    });
    filterPairs.push('param');
    filterPairs = _.concat(filterPairs, filterParamPairs);
  }
  const newPathnameFilters = filterPairs.join('/');
  let newPathName = pathnameBeforeFilter;
  if (filterPairs.length > 0) {
    newPathName = [pathnameBeforeFilter, newPathnameFilters].join('/filter/');
  }
  history.push({
    pathname: newPathName,
    search: queryString
  });
};

export const removeAllActiveFilters = history => (dispatch, getState) => {
  const { app } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  dispatch(setPageData(null, activePage, 'activeFilters'));
  dispatch(filterPageProducts({ history }));
};

export const removePageActiveFilter = (name, value, isParamFilter = false, history, removeWhole = false) => (
  dispatch,
  getState
) => {
  const { app, page } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  const newActiveFilters = _.cloneDeep(_.get(page, [activePage, 'activeFilters'], {}));
  const filters = isParamFilter && newActiveFilters.param ? newActiveFilters.param : newActiveFilters;
  if (filters[name]) {
    if (_.isArray(filters[name]) && !removeWhole) {
      _.pull(filters[name], value);
      if (filters[name].length === 1) {
        filters[name] = filters[name][0];
      }
    } else {
      delete filters[name];
    }
    dispatch(setPageData(newActiveFilters, activePage, 'activeFilters'));
    dispatch(filterPageProducts({ history }));
  }
};

export const addPageFilterResolve = (name, resolveName) => (dispatch, getState) => {
  const { app, page } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  const filtersResolve = _.cloneDeep(_.get(page, [activePage, 'filtersResolve'], {}));
  const newFiltersResolve = { ...filtersResolve, ...{ [name]: resolveName } };
  dispatch(setPageData(newFiltersResolve, activePage, 'filtersResolve'));
};

export const changeBlogPagination = (nextPage, history, ref) => (dispatch, getState) => {
  const { app, page } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  const pagination = _.get(page, [activePage, 'pagination'], null);
  const currentPage = _.toNumber(_.get(pagination, 'currentPage', DEFAULT_PAGE));
  if (nextPage !== currentPage) {
    const newPagination = { ...pagination, ...{ currentPage: nextPage } };
    dispatch(setPageData(newPagination, activePage, 'pagination'));
    dispatch(fetchBlogPageArticles({ resetPage: false, history }));
    if (ref) {
        if (canUseDOM()) {
          const node = ReactDOM.findDOMNode(ref.current);
          if (node) {
            scrollIntoView(node, {
              behavior: actions =>
                actions.forEach(({ el, top, left }) => {
                  el.scrollTop = 0
                  el.scrollLeft = left
                }),
              block: 'start',
              inline: 'start',
            })
          }
        }
    }
  }
};

export const fetchBlogPageArticles = ({ resetPage = true, history = null }) => async (dispatch, getState) => {
  const state = getState();
  const { app, page } = state;
  const { webInstance, activeRoute } = app;
  const activePage = _.get(app, 'activeRoute.name', null);
  const pagination = _.get(page, [activePage, 'pagination'], null);
  const getThemeConfig = getThemeConfigSelector();
  const configPagination = _.get(getThemeConfig(state), 'pagination');
  const {
    currentPage = _.get(configPagination, 'page', DEFAULT_PAGE),
    perPage = _.get(configPagination, 'perPage', DEFAULT_PAGE_LIMIT)
  } = pagination;

  let queryStringParams = {};
  if (activeRoute.matchParams[0]) {
    const { query } = queryString.parseUrl(activeRoute.matchParams[0]);

    if (query.category) {
      queryStringParams.category = query.category;
    }

    if (query.tags) {
      queryStringParams.tags = JSON.stringify(query.tags);
    }
  }

  if (_.get(activeRoute,'queryParams.category')) {
    queryStringParams.communityCategoryId = _.get(activeRoute,'queryParams.category');
    queryStringParams.category = _.get(activeRoute,'queryParams.category');
  }

  dispatch(setLoading(true));
  const response = await dispatch(
    apiFront({
      endpoint: 'blogs',
      webInstance: webInstance,
      options: {
        query: {
          ...queryStringParams
        }
      },
      endpointParams: {
        page: resetPage ? _.get(configPagination, 'page', DEFAULT_PAGE) : currentPage,
        limit: resetPage ? _.get(configPagination, 'perPage', DEFAULT_PAGE_LIMIT) : perPage
      },
      returnResponseMap: {
        data: 'body.data',
        headers: 'headers'
      }
    })
  );
  if (!_.isEmpty(response.data)) {
    const { pathname } = history.location;
    const { data, headers } = response;
    const newPagination = setPaginationFromHeaders(headers);
    dispatch(setPageData(data, activePage, 'articles'));
    dispatch(setPageData(newPagination, activePage, 'pagination'));

    if (history) {
      let newQueryString = { page: currentPage, limit: perPage };

      if (queryStringParams) {
        newQueryString = _.merge(newQueryString, queryStringParams);
      }

      history.push({ pathname: pathname, search: queryString.stringify(newQueryString) });
    }
  } else {
    dispatch(setLoading(false));
  }
};

export const togglePageModal = (id, open) => (dispatch, getState) => {
  const { app } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  dispatch(setPageData(open, activePage, ['modal', id]));
};

export const setPageErrors = errors => (dispatch, getState) => {
  const { app } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  dispatch(setPageData(errors, activePage, 'errors'));
};

export const fetchBanners = (type, propertyId, storeAs = 'banners') => async (dispatch, getState) => {
  const { app } = getState();
  const { webInstance } = app;
  const activePage = _.get(app, 'activeRoute.name', null);
  if (webInstance) {
    const query = {};
    if (type) {
      query.type = type;
    }
    if (propertyId) {
      query.propertyId = propertyId;
    }
    const banners = await dispatch(
      apiFront({
        endpoint: 'banners',
        method: METHOD.GET,
        options: {
          query
        }
      })
    );
    if (_.isArray(banners) && banners.length > 0) {
      dispatch(setPageData(banners, activePage, storeAs));
    }
  }
};

export const fetchStaticBlocks = () => async (dispatch, getState) => {
  const { app = {}, page = {} } = getState();
  const { webInstance } = app;
  const activePage = _.get(app, 'activeRoute.name', null);
  if (webInstance && _.isEmpty(_.get(page, [activePage, 'staticBlocks'], {}))) {
    const staticBlocks = await dispatch(
      apiFront({
        endpoint: 'static_blocks',
        method: METHOD.GET,
        options: {
          query: {
            expand: 'customCss'
          }
        },
        cacheKey: 'static_blocks'
      })
    );
    if (!_.isEmpty(staticBlocks)) {
      dispatch(setPageData(staticBlocks, activePage, 'staticBlocks'));
    }
  }
};

export const addProductToLastSeen = product => (dispatch, getState) => {
  const { app } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  const productData = _.pick(product, ['id', 'name', 'finalPrice', 'photo', 'urlTitle']);
  const lastSeenJSON = Cookies.get(COOKIE_LAST_SEEN);
  if (!_.isEmpty(lastSeenJSON)) {
    const lastSeen = JSON.parse(lastSeenJSON);
    const founded = _.find(lastSeen, obj => obj.id === product.id);
    if (_.isEmpty(founded)) {
      const newLastSeen = _.concat([], [productData], _.slice(lastSeen, 0, 8));
      Cookies.set(COOKIE_LAST_SEEN, newLastSeen, { expires: COOKIE_EXPIRE_TIME });
      dispatch(setPageData(newLastSeen, activePage, 'lastSeenProducts'));
    }
  } else {
    const newLastSeen = [productData];
    Cookies.set(COOKIE_LAST_SEEN, newLastSeen, { expires: COOKIE_EXPIRE_TIME });
    dispatch(setPageData(newLastSeen, activePage, 'lastSeenProducts'));
  }
};

export const fetchLastSeenProducts = () => (dispatch, getState) => {
  const { app } = getState();
  const activePage = _.get(app, 'activeRoute.name', null);
  const lastSeenJSON = Cookies.get(COOKIE_LAST_SEEN);
  if (!_.isEmpty(lastSeenJSON)) {
    const lastSeen = JSON.parse(lastSeenJSON);
    dispatch(setPageData(lastSeen, activePage, 'lastSeenProducts'));
  }
};

export const fetchProducts = (limit = 10, stateSelector = 'fetchedProducts', query = {}) => async (
  dispatch,
  getState
) => {
  const { app } = getState();
  const { webInstance } = app;
  const activePage = _.get(app, 'activeRoute.name', null);
  const queryData = {
    'per-page': limit,
    ...query
  };
  const cacheKey = 'products-' + stateSelector + JSON.stringify(queryData);
  if (webInstance) {
    const products = await dispatch(
      apiFront({
        endpoint: 'products',
        method: METHOD.GET,
        options: {
          query: queryData
        },
        cacheKey: cacheKey
      })
    );
    if (!_.isEmpty(products)) {
      dispatch(setPageData(products, activePage, stateSelector));
    }
  }
};

export const fetchQuickFilterData = (categoryName, dataObject = {}) => async (dispatch, getState)  => {
  const { app } = getState();
  const { webInstance } = app;
  const activePage = _.get(app, 'activeRoute.name', null);
  if (webInstance) {
    const data = {};
    if (categoryName) {
      data.categoryName = categoryName;
    }
    if (_.get(dataObject, 'param', null)) {
      data.param = _.get(dataObject, 'param', null);
    }
    const filterData = await dispatch(
      apiFront({
        endpoint: 'filter',
        method: METHOD.POST,
        options: {
          data
        }
      })
    );
    if (!_.isEmpty(filterData)) {
      dispatch(setPageData(filterData, activePage, 'quickFilter'));
    }
  }
};

export const fetchOperationUnitsData = (conditions = {}, history = null) => async (dispatch, getState)  => {
  const { app } = getState();
  const { webInstance } = app;
  const activePage = _.get(app, 'activeRoute.name', null);
  if (webInstance) {
    const response = await dispatch(
      apiFront({
        endpoint: 'operation_units',
        method: METHOD.get,
        options: {
          query: {
            ...conditions
          }
        },
        endpointParams: {
          page: 1,
          limit: 999
        },
        returnResponseMap: {
          data: 'body.data',
          headers: 'headers'
        }
      })
    );
    if (!_.isEmpty(_.get(response, 'data', []))) {
      dispatch(setPageData(_.get(response, 'data', []), activePage, 'operationUnitsFiltered'));
      if (history) {
        history.push({ pathname: history.location.pathname, search: queryString.stringify(conditions) });
      }
    }
  }
};
