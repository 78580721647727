export const TOGGLE_INFO_MESSAGE = 'TOGGLE_INFO_MESSAGE';

export const toggleInfoMessage = hidden => {
  return {
    type: TOGGLE_INFO_MESSAGE,
    payload: {
      hidden
    }
  };
};
