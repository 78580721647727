import _ from 'lodash';

import styled from 'styled-components';
import Modal from 'react-modal';

import { withCssUnit } from 'common/components/styleguide/utils/index';
import Link from 'common/components/styleguide/Link';

export const customStyles = {
  overlay: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,.4)',
    overflow: 'scroll'
  },
  content: {}
};

export const StyledModal = styled(Modal)`
  border-radius: ${({ theme }) => _.get(theme, 'modal.borderRadius', 0)};
  border: ${({ theme }) => _.get(theme, 'modal.border', 0)};
  outline: none !important;
  background: #fff;
  width: 100%;
  overflow: auto;
  max-height: 95%;
  position: relative;
  @media (min-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    max-width: ${({ width }) => (!_.isNil(width) ? withCssUnit(width) : '600px')};
  }

  @media (max-width: ${({ theme }) => withCssUnit(theme.breakpoints.md)}) {
    width: 80%;
  }
`;

export const StyledClose = styled(Link)`
  font-size: 12px;
  display: block;
`;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eee;
  position: sticky;
  background: #fff;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
`;

export const StyledTitle = styled.h2`
  margin-top: 0;
  margin: 0;
  font-size: 1.1em;
`;

export const StyledContent = styled.section`
  max-height: 90%;
`;
