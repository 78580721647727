import {
  SET_COUNTRIES,
  SET_PAGE_LISTING_LAYOUT,
  TOGGLE_SIDEMENU,
  TOGGLE_USER_SIDEMENU,
  TOGGLE_FILTERS
} from '../actions/AppActions';

const initialState = {
  activeTheme: 'default',
  sideMenuIsOpen: false,
  userSideMenuIsOpen: false,
  filtersIsOpen: false,
  searchResults: null
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return Object.assign({}, state, { countries: action.payload.countries });
    case TOGGLE_SIDEMENU:
      const { sideMenuIsOpen } = state;
      return Object.assign({}, state, {
        userSideMenuIsOpen: false,
        sideMenuIsOpen: !sideMenuIsOpen
      });
    case TOGGLE_USER_SIDEMENU:
      const { userSideMenuIsOpen } = state;
      return Object.assign({}, state, {
        userSideMenuIsOpen: !userSideMenuIsOpen,
        sideMenuIsOpen: false
      });
    case TOGGLE_FILTERS:
      const { filtersIsOpen } = state;
      return Object.assign({}, state, {
        userSideMenuIsOpen: false,
        sideMenuIsOpen: false,
        filtersIsOpen: !filtersIsOpen
      });
    case SET_PAGE_LISTING_LAYOUT:
      return { ...state, ...{ listingLayout: action.payload.newListingLayout } };
    default:
      return state;
  }
};

export default AppReducer;
