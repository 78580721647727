import _ from 'lodash';
import Cookies from 'js-cookie';

import apiFront from 'common/utils/apiFront';
import { getProductsIdsToCompareFromCookies } from 'common/utils/index';

import { message } from 'common/components/message/_default';
import { setLoading, togglePageModal } from './PageActions';
import {
  getProductsToCompareSelector,
  getProductIdsToCompareSelector
} from 'common/selectors/compare';

import { METHOD } from 'common/constants/HttpConstants';
import { COOKIE_PRODUCTS_IDS_TO_COMPARE } from 'common/constants/SiteConstants';

export const SET_PRODUCT_IDS_TO_COMPARE = 'SET_PRODUCT_IDS_TO_COMPARE';
export const SET_PRODUCTS_TO_COMPARE = 'SET_PRODUCTS_TO_COMPARE';

export const setProductIdsToCompare = productIds => {
  return { type: SET_PRODUCT_IDS_TO_COMPARE, payload: { productIds } };
};

export const setProductsToCompare = products => {
  return { type: SET_PRODUCTS_TO_COMPARE, payload: { products } };
};

export const fetchProductIdsToCompareFromCookie = () => (dispatch, getState) => {
  const getProductIds = getProductIdsToCompareSelector();
  const productIds = getProductIds(getState());
  if (_.isEmpty(productIds)) {
    const productIdsToCompare = getProductsIdsToCompareFromCookies(
      Cookies.get(COOKIE_PRODUCTS_IDS_TO_COMPARE)
    );
    if (!_.isEmpty(productIdsToCompare)) {
      dispatch(setProductIdsToCompare(productIdsToCompare));
    }
  }
};

export const fetchProductToCompare = () => async (dispatch, getState) => {
  const getProductIds = getProductIdsToCompareSelector();
  const productIds = getProductIds(getState());
  dispatch(setLoading(true));
  if (!_.isEmpty(productIds)) {
    const productsToCompare = await dispatch(
      apiFront({
        method: METHOD.POST,
        endpoint: 'products_filter',
        options: {
          data: {
            productIds
          }
        },
        endpointParams: {
          page: 1,
          limit: 3
        }
      })
    );
    if (!_.isEmpty(productsToCompare)) {
      dispatch(setProductsToCompare(productsToCompare));
    }
  }
  dispatch(setLoading(false));
};

export const addProductToCompare = productId => dispatch => {
  dispatch(setLoading(true));
  const productIdsToCompareJSON = Cookies.get(COOKIE_PRODUCTS_IDS_TO_COMPARE);
  const expires = 7;
  if (!_.isEmpty(productIdsToCompareJSON)) {
    const productIdsToCompare = JSON.parse(productIdsToCompareJSON);
    if (productIdsToCompare.length < 3) {
      const founded = _.find(productIdsToCompare, pid => pid === productId);
      if (!founded) {
        productIdsToCompare.push(productId);
        dispatch(setProductIdsToCompare(productIdsToCompare));
        Cookies.set(COOKIE_PRODUCTS_IDS_TO_COMPARE, productIdsToCompare, { expires });
        message.success('products compare', 'product was added to compare!');
      } else {
        message.error('products compare', 'product is already in compare!');
      }
    } else {
      dispatch(togglePageModal('full_compare', true));
    }
  } else {
    const newProductIdsToCompare = [productId];
    dispatch(setProductIdsToCompare(newProductIdsToCompare));
    Cookies.set(COOKIE_PRODUCTS_IDS_TO_COMPARE, newProductIdsToCompare, { expires });
    message.success('products compare', 'product was added to compare!');
  }
  dispatch(setLoading(false));
};

export const removeProductFromCompare = productId => (dispatch, getState) => {
  dispatch(setLoading(true));
  const getProductIds = getProductIdsToCompareSelector();
  const getProductsToCompare = getProductsToCompareSelector();
  const productIds = getProductIds(getState());
  const productsToCompare = getProductsToCompare(getState());
  const newProductsIds = _.filter(productIds, pid => pid !== productId);
  if (!_.isEmpty(newProductsIds)) {
    const expires = 7;
    const newProductsToCompare = _.filter(productsToCompare, product => {
      return _.indexOf(newProductsIds, product.id) !== -1;
    });
    dispatch(setProductIdsToCompare(newProductsIds));
    dispatch(setProductsToCompare(newProductsToCompare));
    Cookies.set(COOKIE_PRODUCTS_IDS_TO_COMPARE, newProductsIds, { expires });
  } else {
    dispatch(setProductIdsToCompare(null));
    dispatch(setProductsToCompare(null));
    Cookies.remove(COOKIE_PRODUCTS_IDS_TO_COMPARE);
  }
  dispatch(setLoading(false));
};
