import ReduxToastr from 'react-redux-toastr';
import { get } from 'lodash';
import styled from 'styled-components';

export const ReduxToastrTheme = styled(ReduxToastr)`
  & > div {
    div[class^='top-'],
    div[class^='bottom-'] {
      width: ${({ theme }) => get(theme, 'toastr.width', '350px')};
      margin-top: ${({ theme }) => get(theme, 'toastr.marginTop', '0')};
      & > div {
        & > .toastr {
          ${({ theme }) =>
            get(theme, 'toastr.opacity', false)
              ? `opacity: ${get(theme, 'toastr.opacity')};`
              : ''};
          ${({ theme }) =>
            get(theme, 'toastr.borderRadius', false)
              ? `border-radius: ${get(theme, 'toastr.borderRadius')};`
              : ''};
          & > div {
            & .rrt-progress-container {
              height: ${({ theme }) =>
                get(theme, 'toastr.progressbar.height', '5px')};
            }
            .rrt-left-container {
              .rrt-holder {
                display: flex;
                align-items: center;
                vertical-align: middle;
                justify-content: center;
                color: ${({ theme }) =>
                  get(theme, 'toastr.holder.color', '#ffffff')};
              }
            }
            .rrt-left-container,
            .rrt-middle-container,
            .rrt-right-container {
              .rrt-title {
                color: ${({ theme }) =>
                  get(theme, 'toastr.title.color', '#ffffff')};
                font-size: ${({ theme }) =>
                  get(theme, 'toastr.title.fontSize', '16px')};
                font-weight: ${({ theme }) =>
                  get(theme, 'toastr.title.fontWeight', 'inherit')};
              }
              .rrt-text {
                color: ${({ theme }) =>
                  get(theme, 'toastr.text.textColor', '#ffffff')};
                font-size: ${({ theme }) =>
                  get(theme, 'toastr.text.fontSize', '15px')};
                font-weight: ${({ theme }) =>
                  get(theme, 'toastr.text.fontWeight', 'inherit')};
              }
            }
          }
          &.rrt-success {
            box-shadow: ${({ theme }) =>
              get(theme, 'toastr.success.boxShadow', 'none')};
            background-color: ${({ theme }) =>
              get(theme, 'toastr.success.background', 'green')};
            border: ${({ theme }) =>
              get(theme, 'toastr.success.border', 'none')};

            & .rrt-left-container {
              .rrt-holder {
                color: ${({ theme }) =>
                  get(theme, 'toastr.success.iconColor', '#ffffff')};
              }
            }
            & .rrt-middle-container {
              .rrt-title {
                color: ${({ theme }) =>
                  get(theme, 'toastr.success.titleColor', '#ffffff')};
              }
              .rrt-text {
                color: ${({ theme }) =>
                  get(theme, 'toastr.success.textColor', '#ffffff')};
              }
            }
            & .rrt-progressbar {
              background-color: ${({ theme }) =>
                get(theme, 'toastr.success.progressbarColor', 'none')};
            }

            & .rrt-right-container button {
              color: ${({ theme }) =>
                get(theme, 'toastr.success.closeButtonColor', 'none')};
            }
          }
          &.rrt-error {
            box-shadow: ${({ theme }) =>
              get(theme, 'toastr.error.boxShadow', 'none')};
            background-color: ${({ theme }) =>
              get(theme, 'toastr.error.background', 'red')};
            border: ${({ theme }) => get(theme, 'toastr.error.border', 'none')};
            & .rrt-progressbar {
              background-color: ${({ theme }) =>
                get(theme, 'toastr.error.progressbarColor', 'none')};
            }
            & .rrt-left-container {
              .rrt-holder {
                color: ${({ theme }) =>
                  get(theme, 'toastr.error.iconColor', '#ffffff')};
              }
            }
            & .rrt-middle-container {
              .rrt-title {
                color: ${({ theme }) =>
                  get(theme, 'toastr.error.titleColor', '#ffffff')};
              }
              .rrt-text {
                color: ${({ theme }) =>
                  get(theme, 'toastr.error.textColor', '#ffffff')};
              }
            }
            & .rrt-right-container button {
              color: ${({ theme }) =>
                get(theme, 'toastr.error.closeButtonColor', 'none')};
            }
          }
          &.rrt-info {
            box-shadow: ${({ theme }) => get(theme, 'toastr.info.boxShadow')};
            background-color: ${({ theme }) =>
              get(theme, 'toastr.info.background')};
            border: ${({ theme }) => get(theme, 'toastr.info.border', 'none')};
            & .rrt-progressbar {
              background-color: ${({ theme }) =>
                get(theme, 'toastr.info.progressbarColor', 'none')};
            }
            & .rrt-left-container {
              .rrt-holder {
                color: ${({ theme }) =>
                  get(theme, 'toastr.info.iconColor', '#ffffff')};
              }
            }
            & .rrt-middle-container {
              .rrt-title {
                color: ${({ theme }) =>
                  get(theme, 'toastr.info.titleColor', '#ffffff')};
              }
              .rrt-text {
                color: ${({ theme }) =>
                  get(theme, 'toastr.info.textColor', '#ffffff')};
              }
            }
            & .rrt-right-container button {
              color: ${({ theme }) =>
                get(theme, 'toastr.info.closeButtonColor', 'none')};
            }
          }
        }
      }
    }
  }
`;
