//LAYOUT
export const ICON_LISTING_GRID = 'grandus grandus-layout-grid';
export const ICON_LISTING_ROWS = 'grandus grandus-layout-row';

//GENERAL
export const ICON_ADD = 'grandus grandus-add';
export const ICON_REMOVE = 'grandus grandus-remove';
export const ICON_CANCEL = 'grandus grandus-cancel';
export const ICON_DELETE = 'grandus grandus-delete';
export const ICON_OK = 'grandus grandus-check';

export const ICON_NOTIF_OK = 'grandus grandus-c-check';
export const ICON_NOTIF_NOK = 'grandus grandus-c-remove';
export const ICON_NOTIF_WARNING = 'grandus grandus-c-warning';

export const ICON_CHEVRON_DOWN = 'grandus grandus-ctrl-down';
export const ICON_CHEVRON_UP = 'grandus grandus-ctrl-up';
export const ICON_CHEVRON_RIGHT = 'grandus grandus-ctrl-up';

// CART
export const ICON_CART = 'grandus grandus-bag';
export const ICON_CART_ADD = 'grandus grandus-bag-add';
export const ICON_CART_REMOVE = 'grandus grandus-bag-remove';
export const ICON_CART_EDIT = 'grandus grandus-bag-edit';
export const ICON_CART_ORDER = 'grandus grandus-send';

// COMPARE
export const ICON_COMPARE = 'grandus grandus-compare';
export const ICON_COMPARE_ADD = 'grandus grandus-compare';
export const ICON_COMPARE_REMOVE = 'grandus grandus-compare';

//FAVOURITE
export const ICON_WISHLIST = 'grandus grandus-favourite';
export const ICON_WISHLIST_ADD = 'grandus grandus-favourite-add';
export const ICON_WISHLIST_REMOVE = 'grandus grandus-favourite-remove';

// UI
export const ICON_MENU = 'grandus grandus-menu';
export const ICON_USER = 'grandus grandus-users';
export const ICON_SEARCH = 'grandus grandus-search';
export const ICON_FILTER = 'grandus grandus-filter';
export const ICON_MAIL = 'grandus grandus-mail';
export const ICON_PHONE = 'grandus grandus-phone';
export const ICON_LOCATION = 'grandus grandus-location';
export const ICON_INFO_CIRCLE = 'grandus grandus-c-info';

//SOCIAL
export const ICON_LOGO_FACEBOOK = 'grandus grandus-logo-facebook';
export const ICON_LOGO_GOOGLE = 'grandus grandus-logo-google';
export const ICON_LOGO_INSTAGRAM = 'grandus grandus-logo-instagram';
export const ICON_LOGO_YOUTUBE = 'grandus grandus-logo-youtube';

//LOADING
export const ICON_LOADING = 'grandus grandus-is-spinning grandus-loader';
