import _ from 'lodash';
import merge from 'deepmerge';

import allThemes from '../themes';

export default (activeTheme, colors = {}) => {

  if (activeTheme !== 'default' && allThemes[activeTheme]) {

    const mergedTheme = merge(allThemes.default(colors), allThemes[activeTheme](colors));

    return mergedTheme;
  }
  return allThemes['default'](colors);
};
