const filterFilterTable = {
  sk: {
    brand: 'znacka',
    storeLocation: 'predajna',
    priceRange: 'cena'
  }
};

const routes = {
  home: {
    name: 'home',
    paths: { sk: '/', en: '/en' },
    exact: true,
    cache: { route: true, markup: true },
    page: 'home/foodfactory',
    layout: 'foodfactory'
  },
  category: {
    name: 'category',
    path: {
      sk: '/kategoria/:id/:filters(.*)?',
      en: '/en/categories/:id/:filters(.*)?'
    },
    exact: true,
    cache: { route: true, markup: true },
    filterTable: filterFilterTable,
    // component: Category,
    page: 'category/foodfactory',
    layout: 'foodfactory'
  },
};

export default routes;
