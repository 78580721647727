import {
  SET_PRODUCT_IDS_TO_COMPARE,
  SET_PRODUCTS_TO_COMPARE
} from '../actions/CompareActions';

const initialState = {};

const CompareReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_IDS_TO_COMPARE:
      return Object.assign({}, state, { productIdsToCompare: action.payload.productIds });
    case SET_PRODUCTS_TO_COMPARE:
      return Object.assign({}, state, { productsToCompare: action.payload.products });
    default:
      return state;
  }
};

export default CompareReducer;
