import testTheme from './test';
import defaultTheme from './default';
import mobilonlineTheme from './mobilonline';
import muratTheme from './murat';
import freshboxTheme from './freshbox';

export default {
  default: defaultTheme,
  test: testTheme,
  mobilonline: mobilonlineTheme,
  murat: muratTheme,
  freshbox: freshboxTheme,
  'freshbox-expres': freshboxTheme
};
