import React from 'react';

const Icon = ({ className = '', size = false, dataId = '' }) => {
  const classNames = ['icon', 'nc-icon'];
  if (className) {
    classNames.push(className);
  }
  let inlineStyles = {}
  if (size !== false) {
    inlineStyles.fontSize = size;
  }

  return <i className={classNames.join(' ')} style={inlineStyles} data-id={dataId} />;
};

export default React.memo(Icon);
