import _ from 'lodash';
import Cookies from 'js-cookie';

import * as GTM from 'common/constants/GTMConstants';
import request from 'superagent';

const productsProcessor = (
  products,
  list = null,
  additionalData = {},
  chunkIndex = 0
) => {
  const productsObject = [];
  if (_.isEmpty(products)) {
    return productsObject;
  }
  let productsArray = products;
  const fixedPosition = _.get(additionalData, 'position', null);
  const position = chunkIndex * GTM.CHUNK_SIZE;
  _.each(productsArray, (product, index) => {
    const categories = _.get(product, 'categories', {});
    let category = _.first(categories);
    if (!_.get(category, 'name', null)) {
      category = _.first(category);
    }
    const productData = {
      id: product.id,
      name: product.name,
      category: _.get(category, 'name', ''),
      position: fixedPosition ? fixedPosition : position + (index + 1)
    };
    if (list) {
      productData.list = list;
    }
    productsObject.push(productData);
  });
  return productsObject;
};

const productsProcessorCart = (items = [], additionalData = {}) => {
  const productsObject = [];
  if (_.isEmpty(items)) {
    return productsObject;
  }
  _.each(items, item => {
    const { product, count } = item;
    const categories = _.get(product, 'categories.0', {});

    let quantity = _.get(additionalData, 'count', null);
    if (!quantity) {
      quantity = count ? count : 1;
    }
    const productData = {
      id: _.get(product, 'id', null),
      name: _.get(product, 'name', null),
      category: _.get(_.first(categories), 'name', ''),
      quantity: quantity
    };
    productsObject.push(productData);
  });
  return productsObject;
};

const productDetailFbProcessor = (products, additionalData) => {
  const productsObject = [];
  if (_.isEmpty(products)) {
    return productsObject;
  }
  let productsArray = products;
  let productCategories = [];
  let categories = [];
  _.each(productsArray, product => {
    categories = [];
    productCategories = _.get(product, 'categories', []);
    _.forEach(productCategories, categoryTree => {
      categories.push(_.map(categoryTree, category => _.get(category, 'name', '')).join(' / '))
    });
    const productData = {
      content_ids: [_.get(product, 'id', product.id)],
      content_name: product.name,
      content_type: GTM.FBDATA.CONTENT_TYPE_PRODUCT,
      currency: _.get(product, 'finalPriceData.currency', 'EUR'),
      value: _.get(product, 'finalPriceData.price', null),
      contents: [
        {
          id: _.get(product, 'sku', product.id),
          quantity: _.get(additionalData, 'quantity', 1),
          item_price: _.get(product, 'finalPriceData.price', null),
          name: _.get(product, 'name', null),
          brand: _.get(product, 'brand.name', null),
          categories
        }
      ]
    };
    productsObject.push(productData);
  });
  return productsObject;
};

const productCartFbProcessor = (items = [], additionalData) => {
  const productsObject = [];
  if (_.isEmpty(items)) {
    return productsObject;
  }
  let productCategories = [];
  let categories = [];
  _.each(items, item => {
    const { product, count } = item;
    let quantity = _.get(additionalData, 'count', null);
    if (!quantity) {
      quantity = count ? count : 1;
    }
    categories = [];
    productCategories = _.get(product, 'categories', []);
    _.forEach(productCategories, categoryTree => {
      categories.push(_.map(categoryTree, category => _.get(category, 'name', '')).join(' / '))
    });
    const productData = {
      content_ids: [_.get(product, 'id', product.id)],
      content_name: product.name,
      content_type: GTM.FBDATA.CONTENT_TYPE_PRODUCT,
      currency: _.get(product, 'finalPriceData.currency', 'EUR'),
      value: _.get(product, 'finalPriceData.price', null),
      contents: [
        {
          id: _.get(product, 'sku', product.id),
          quantity: quantity,
          item_price: _.get(product, 'finalPriceData.price', null),
          name: _.get(product, 'name', null),
          brand: _.get(product, 'brand.name', null),
          categories
        }
      ]
    };
    productsObject.push(productData);
  });
  return productsObject;
};

const productsCheckoutFbProcessor = cart => {
  if (_.isEmpty(cart)) {
    return [];
  }
  const productsIds = [];
  const contents = [];
  const { items } = cart;
  let productCategories = [];
  let categories = [];
  _.each(items, item => {
    const { product } = item;
    if (product) {
      categories = [];
      productCategories = _.get(product, 'categories', []);
      _.forEach(productCategories, categoryTree => {
        categories.push(_.map(categoryTree, category => _.get(category, 'name', '')).join(' / '))
      });
      productsIds.push(_.get(product, 'id', product.id));
      contents.push({
        id: _.get(product, 'id', product.id),
        quantity: _.get(item, 'count', 1),
        item_price: _.get(product, 'finalPriceData.price', null),
        name: _.get(product, 'name', null),
        brand: _.get(product, 'brand.name', null),
        categories
      });
    }
  });
  const cartObject = {
    content_ids: productsIds,
    currency: _.get(cart, 'currency', 'EUR'),
    value: _.get(cart, 'sumTotal', null),
    contents: contents
  };
  return cartObject;
};

const productsFbProcessor = products => {
  if (_.isEmpty(products)) {
    return [];
  }
  const currency = _.get(_.first(products), 'finalPriceData.currency', 'EUR');
  let sumTotal = 0;
  const productsIds = [];
  const contents = [];
  _.each(products, product => {
    const itemPrice = _.get(product, 'finalPrice', 0);
    const productIdentifier = _.get(product, 'id', product.id);
    productsIds.push(productIdentifier);
    contents.push({
      id: productIdentifier,
      quantity: 1,
      item_price: itemPrice,
      name: _.get(product, 'name', null),
      brand: _.get(product, 'brand.name', null)
    });
    sumTotal += itemPrice;
  });

  const productsObject = {
    content_ids: productsIds,
    currency: currency,
    value: sumTotal,
    contents: contents
  };

  return productsObject;
};

const productsPurchaseFbProcessor = order => {
  if (_.isEmpty(order)) {
    return [];
  }
  const productsIds = [];
  const contents = [];
  const { orderItems } = order;
  let productCategories = [];
  let categories = [];
  _.each(orderItems, item => {
    const { product } = item;
    if (product) {
      categories = [];
      productCategories = _.get(product, 'categories', []);
      _.forEach(productCategories, categoryTree => {
        categories.push(_.map(categoryTree, category => _.get(category, 'name', '')).join(' / '))
      });
      productsIds.push(_.get(item, 'productId', _.get(product, 'id')));
      contents.push({
        id: _.get(product, 'id', product.id),
        quantity: _.get(item, 'quantity', 1),
        item_price: _.get(product, 'finalPriceData.price', null),
        name: _.get(product, 'name', null),
        brand: _.get(product, 'brand.name', null),
        categories
      });
    }
  });

  const orderObject = {
    content_ids: productsIds,
    content_type: GTM.FBDATA.CONTENT_TYPE_PRODUCT,
    currency: _.get(order, 'currency', 'EUR'),
    value: _.get(order, 'totalSum', null),
    num_items: orderItems.length,
    contents: contents
  };

  return orderObject;
};

const processRetargetingCategories = categories => {
  const categoriesObject = {};
  if (_.isEmpty(categories)) {
    return categoriesObject;
  }

  _.each(categories, (category, index) => {
    categoriesObject[index + 1] = _.get(category, 'name', null);
  });

  return categoriesObject;
};

export const preparePushData = (
  event,
  data,
  list,
  additionalData = {},
  chunkIndex = 0
) => {
  const pushData = {};
  switch (event) {
    case GTM.PRODUCT_VIEW:
      pushData.event = 'eec.impressionView';
      pushData.ecommerce = {};
      pushData.ecommerce.impressions = productsProcessor(
        data,
        list,
        additionalData,
        chunkIndex
      );
      pushData.fbdata = productsFbProcessor(data);
      break;
    case GTM.PRODUCT_DETAIL:
      pushData.event = 'eec.detail';
      pushData.ecommerce = {};
      pushData.ecommerce.detail = {
        products: productsProcessor(data)
      };
      pushData.ecommerce.fbdata = productDetailFbProcessor(data);
      break;
    case GTM.PRODUCT_RESERVATION:
      const reservation = _.first(data);
      pushData.event = 'evnt.reserve';
      pushData.value = reservation.value;
      break;
    case GTM.PRODUCT_CLICK:
      pushData.event = 'eec.impressionClick';
      pushData.ecommerce = {};
      pushData.ecommerce.click = {
        actionField: list
      };
      pushData.ecommerce.products = productsProcessor(
        data,
        list,
        additionalData
      );
      break;
    case GTM.CART_ADD:
      pushData.event = 'eec.add';
      pushData.ecommerce = {};
      pushData.ecommerce.add = {
        products: productsProcessorCart(data, additionalData)
      };
      pushData.ecommerce.fbdata = productCartFbProcessor(data, additionalData);
      break;
    case GTM.CART_REMOVE:
      pushData.event = 'eec.remove';
      pushData.ecommerce = {};
      pushData.ecommerce.remove = {
        products: productsProcessorCart(data, additionalData)
      };
      break;
    case GTM.CHECKOUT:
      const cart = _.first(data);
      pushData.event = 'eec.checkout';
      pushData.ecommerce = {};
      pushData.ecommerce.checkout = {
        actionField: {
          step: _.get(additionalData, 'step')
        }
      };
      if (!_.isEmpty(cart)) {
        pushData.ecommerce.checkout.products = productsProcessorCart(
          _.get(cart, 'items', []),
          additionalData
        );
        pushData.ecommerce.checkout.fbdata = productsCheckoutFbProcessor(cart);
      }
      break;
    case GTM.FB_PURCHASE:
      const order = _.first(data);
      pushData.event = 'fb.purchase';
      pushData.fbdata = productsPurchaseFbProcessor(order);
      break;
    case GTM.RETARGETING_CATEGORIES:
      pushData.event = 'retargeting.categories';
      pushData.page = {
        Category: {}
      };
      pushData.page.Category = processRetargetingCategories(data);
      break;
    default:
      pushData.event = GTM.EVENTS[event];
      return pushData;
  }
  return pushData;
};

export const getClientIdFromCookie = () => {
  let clientId = '0000000.0000000';
  const gacookie = Cookies.get('_ga');
  if (gacookie) {
    const gacookieParts = _.drop(_.split(gacookie, '.'), 2);
    clientId = _.join(gacookieParts, '.');
  }
  return clientId;
};

export const GATransaction = (UACode, order = {}, clientId) => {
  if (_.isEmpty(order)) {
    return null;
  }
  const itemsMapping = [
    {
      name: 'id',
      value: 'product.id',
      default: null
    },
    {
      name: 'nm',
      value: item => {
        const { product } = item;
        return _.get(product, 'name', null);
      },
      default: null
    },
    {
      name: 'ca',
      value: item => {
        const { product } = item;
        const categories = _.get(product, 'categories.0', {});
        return _.get(_.first(categories), 'name', null);
      },
      default: null
    },
    {
      name: 'pr',
      value: 'product.finalPriceData.price',
      default: null
    },
    {
      name: 'qt',
      value: 'count',
      default: null
    },
    {
      name: 'cm',
      value: 'none',
      default: 0.0
    }
  ];
  const transactionId = _.get(order, 'orderNumber');
  const transactionData = {
    ti: transactionId, // order number
    tr: _.get(order, 'totalSumData.price'), // order price
    tt: _.get(order, 'vatValue'), // VAT
    ts: _.get(order, 'deliveryPriceData.price'), // delivery price
    cu: _.get(order, 'totalSumData.currency'), // currency
    pa: 'purchase'
  };
  const transactionProducts = [];
  _.each(order.orderItems, (item, index) => {
    const newIndex = index + 1;
    _.each(itemsMapping, field => {
      const fieldName = 'pr' + newIndex + field.name;

      if (_.isFunction(field.value)) {
        const fieldValueFunction = field.value;
        const fieldValue = fieldValueFunction(item);
        transactionProducts[fieldName] = fieldValue
          ? fieldValue
          : field.default;
      } else {
        transactionProducts[fieldName] = _.get(
          item,
          field.value,
          field.default
        );
      }
    });
  });
  const url = `https://www.google-analytics.com/collect?v=1&t=event&tid=
    ${UACode}
    &cid=
    ${clientId}
    &ec=Ecommerce&ea=Purchase&el=
    ${encodeURIComponent('Transaction ID: ' + transactionId)}
    &${Object.keys(transactionData)
      .map(
        k =>
          encodeURIComponent(k) + '=' + encodeURIComponent(transactionData[k])
      )
      .join('&')}
    &${Object.keys(transactionProducts)
      .map(
        k =>
          encodeURIComponent(k) +
          '=' +
          encodeURIComponent(transactionProducts[k])
      )
      .join('&')}
    `;
  request.post(url);
};
