import React from 'react';
import styled, { css } from 'styled-components';
import chroma from 'chroma-js';
import { withCssUnit } from 'common/components/styleguide/utils/index';
import _ from 'lodash';

const StyledLink = styled(
  ({ theme, linkType, isActive = false, customStyle, margin, marginConfig, fullWidth, ...props }) => {
    return <a {...props}>{props.children}</a>;
  }
)`
  display: inline-flex;
  cursor: pointer;
  align-items: left;
  justify-content: flex-start;
  box-sizing: border-box;
  text-decoration: ${({ theme }) => _.get(theme, 'link.textDecoration', 'none')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  color: ${({ theme, linkType }) => _.get(theme, ['colors', linkType], _.get(theme, 'colors.primary'))};
  margin: ${({ theme, margin, marginConfig }) => {
    return margin ? margin : ({theme, marginConfig}) => {
      let finalMargin = '';
      const defaultMargin = withCssUnit(_.get(theme, 'gutterWidth', 0));
      finalMargin = _.get(marginConfig, 'top', false) ? defaultMargin + ' ' : '0 ';
      finalMargin = finalMargin + (_.get(marginConfig, 'right', false) ? defaultMargin + ' ' : '0 ');
      finalMargin = finalMargin + (_.get(marginConfig, 'bottom', false) ? defaultMargin + ' ' : '0 ');
      finalMargin = finalMargin + (_.get(marginConfig, 'left', false) ? defaultMargin + ' ' : '0 ');
      return finalMargin;
    };
  }};
  &:hover {
    text-decoration: none;
    color: ${({ theme, linkType }) =>
      chroma(
        _.get(
          theme,
          ['colors', linkType],
          _.get(theme, 'colors.primary')
        )
      )
      .darken(1.8)
      .hex()};
  }
  &:active {
    ${({ theme, linkType }) => {
      const baseColor = chroma(_.get(theme, ['colors', linkType], _.get(theme, 'colors.primary'))).hex();
      let result = `background-color: ${chroma(baseColor).brighten(2.3).alpha(_.get(theme, 'link.active.alpha', 0.3))};`;
      return result;
    }}
  }
  &:focus {
    ${({ theme, linkType }) => {
      const baseColor = chroma(_.get(theme, ['colors', linkType], _.get(theme, 'colors.primary'))).hex();
      let result = `background-color: ${chroma(baseColor).brighten(2.3).alpha(_.get(theme, 'link.focus.alpha', 0.3))};`;
      return result;
    }}
  }
  ${({ isActive }) =>
    isActive &&
    css`
      text-decoration:underline;
      color: ${({ theme, linkType }) =>
        chroma(
          _.get(
            theme,
            ['colors', linkType],
            _.get(theme, 'colors.primary')
          )
        )
        .darken(1)
        .hex()};
    `};
  ${({ customStyle }) => css`
    ${customStyle}
  `}
`;

const Link = ({
  href,
  children,
  theme,
  linkType = 'primary',
  className = '',
  title = '',
  onClick,
  target,
  customStyle,
  isActive,
  rel,
  fullWidth = false,
  margin = false,
  marginConfig = {top:false, left: false, bottom: false, right: false}
}) => {
  return (
    <StyledLink
      href={href}
      theme={theme}
      linkType={linkType}
      className={className}
      onClick={onClick}
      target={target}
      customStyle={customStyle}
      rel={rel}
      title={title}
      isActive={isActive}
      fullWidth={fullWidth}
      margin={margin}
      marginConfig={marginConfig}
    >
      {children}
    </StyledLink>
  );
};

export default React.memo(Link);
