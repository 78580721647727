const apiEndpoints = {
  webinstance: '/api/web-instance',
  categories: '/api/v2/categories',
  category: '/api/v2/categories/:categoryId',
  sub_categories: '/api/category/get-childs',
  home_page_products: '/api/product/homepage-products',
  breadcrumb: '/api/category/breadcrumbs',
  add_specific_payment_type: '/api/cart/add-specific-payment-type',
  carts: '/api/v2/carts',
  cart: '/api/v2/carts/:accessToken',
  cart_items: '/api/v2/carts/:accessToken/items',
  cart_item: '/api/v2/carts/:accessToken/items/:itemId',
  cart_add_items: '/api/cart/add-items',
  cart_add_delivery: '/api/cart/add-delivery',
  cart_add_payment: '/api/cart/add-payment',
  cart_coupon: '/api/v2/carts/:accessToken/coupon',
  cart_redeem_coupon_add: '/api/cart/redeem-coupon',
  cart_redeem_coupon_remove: '/api/cart/remove-coupon',
  cart_read: '/api/cart/read',
  cart_remove_item: '/api/cart/remove-item',
  cart_change_count: '/api/cart/change-count',
  order_create: '/api/order/create',
  orders: '/api/v2/orders',
  order: '/api/v2/orders/:accessToken',
  quatro_calculator: '/api/web-instance/get-quatro-script',
  newsletter: '/api/v2/newsletter/subscribe',
  banners: '/api/v2/banners',
  products: '/api/v2/products',
  product: '/api/v2/products/:id',
  filter: '/api/v2/filters',
  products_filter: '/api/v2/products/filter?page=:page&per-page=:limit',
  sign_in: '/api/v2/users/login',
  user: '/api/v2/users/:userId',
  user_b2b_orders: '/api/v2/users/:userId/orders',
  user_b2b_order: '/api/v2/users/:userId/orders/:orderId',
  user_b2b_invoices: '/api/v2/users/:userId/invoices',
  user_b2b_invoice: '/api/v2/users/:userId/invoices/:invoiceId',
  user_b2b_delivery_notes: '/api/v2/users/:userId/delivery-notes',
  user_b2b_documents: '/api/v2/users/:userId/documents/:documentId',
  users: '/api/v2/users',
  logout: '/api/v2/users/logout',
  forgot_password: '/api/v2/users/forgot-password',
  change_password: '/api/v2/users/change-password',
  countries: '/api/v2/countries',
  towns: '/api/v2/towns',
  autosuggest: '/api/v2/autosuggest',
  blogs: '/api/v2/blogs?page=:page&per-page=:limit',
  blog: '/api/v2/blogs/:id',
  blog_categories: '/api/v2/blogs/categories',
  static_pages: '/api/v2/pages',
  static_page: '/api/v2/pages/:urlTitle',
  ratings: '/api/v2/ratings',
  brands: '/api/v2/brands',
  review: '/api/v2/review',
  wishlist: '/api/v2/users/:userId/wishlist',
  wishlist_item_delete: '/api/v2/users/:userId/wishlist/:wishlistItemId',
  deliveries: '/api/v2/deliveries',
  possible_deliveries: '/api/v2/deliveries/possible-by-items',
  reservations: '/api/v2/reservations',
  facebook_login: '/api/v2/auth/facebook',
  google_login: '/api/v2/auth/google',
  contact_form: '/api/v2/contact-forms',
  operation_units: '/api/v2/operation-units?page=:page&per-page=:limit',
  operation_unit: '/api/v2/operation-units/:id',
  operation_unit_opening_hours: '/api/v2/operation-units/:operationUnitId/opening-hours?date=:date',
  operation_unit_opening_hours_next: '/api/v2/operation-units/:operationUnitId/opening-hours/next-days?daysCount=:daysCount',
  operation_unit_disabled_dates: '/api/v2/operation-units/:operationUnitId/disabled-dates',
  operation_unit_slots: '/api/v2/operation-units/:operationUnitId/available-slots',
  static_blocks: '/api/v2/static-blocks',
};

export default apiEndpoints;
