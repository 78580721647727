import { METHOD } from 'common/constants/HttpConstants';
import apiFront from 'common/utils/apiFront';

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';

export const setSearchResults = results => {
  return { type: SET_SEARCH_RESULTS, payload: { results } };
};

export const fetchSearchSuggestions = search => async (dispatch, getState) => {
  const { app = {}, user = {} } = getState();
  const { webInstance } = app;
  if (webInstance) {
    if (search) {
      const results = await dispatch(
        apiFront({
          user,
          endpoint: 'autosuggest',
          method: METHOD.GET,
          options: {
            query: {
              search
            }
          }
        })
      );
      dispatch(setSearchResults(results));
    } else {
      dispatch(setSearchResults(null));
    }
  }
};

export const emptySearchSuggestions = () => async (dispatch, getState) => {
  const { app = {} } = getState();
  const { webInstance } = app;
  if (webInstance) {
    dispatch(setSearchResults(null));
  }
};
