import {
  SET_USER,
  SET_USER_COUNTRIES,
  SET_USER_WISHLIST_COUNT,
  SET_USER_WISHLIST
} from '../actions/UserActions';

const initialState = null;

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload.user };
    case SET_USER_COUNTRIES:
      return { ...state, ...{ countries: action.payload.countries } };
    case SET_USER_WISHLIST_COUNT:
      return { ...state, ...{ wishlistItemsCount: action.payload.count } };
    case SET_USER_WISHLIST:
      return { ...state, ...{ wishlist: action.payload.wishlist } };
    default:
      return state;
  }
};

export default AppReducer;
