import {createSelector} from 'reselect';
import _ from 'lodash';

import {
  DEFAULT_LANG,
  ESHOP_TYPE_B2C,
  ESHOP_TYPE_B2B,
  ESHOP_TYPE_B2B_LOCKED,
  ESHOP_TYPE_MIXED
} from 'common/constants/SiteConstants';
import { setRouteParams } from '../utils/index';

const getApp = state => {
  const { app = {} } = state;
  return app;
};

const getUser = state => {
  const { user = {} } = state;
  return user;
};

export const getActiveRouteSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'activeRoute', null)
  );

export const getActiveRouteNameSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'activeRoute.name', null)
  );

export const getQueryParamsSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'activeRoute.queryParams', null)
  );

export const getThemeConfigSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'config', null)
  );

export const getLanguageSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'currentLanguage', DEFAULT_LANG)
  );

export const getLogoSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.logo', null)
  );

export const getImagePlaceholderSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.placeholder', null)
  );

export const getDomainSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.domain', '')
  );

export const getAnalyticsSelector = () =>
  createSelector(
    [getApp],
    app => {
      const {
        google_analytics_code = null,
        google_tag_manager_code = null,
        facebook_remarketing_id = null
      } = _.get(app, 'webInstance.globalSettings', null);
      return {
        googleAnalytics: {
          active: google_analytics_code ? true : false,
          code: google_analytics_code
        },
        googleTagManager: {
          active: google_tag_manager_code ? true : false,
          code: google_tag_manager_code
        },
        facebookPixel: {
          active: facebook_remarketing_id ? true : false,
          code: facebook_remarketing_id
        }
      };
    }
  );

export const getQuatroSelector = () =>
  createSelector(
    [getApp],
    app => {
      const {
        quatro_active = 0,
        quatro_id = '',
        quatro_available_from_price = 0,
        quatro_available_to_price = Infinity
      } = _.get(app, 'webInstance.globalSettings', null);
      return {
        active: _.parseInt(quatro_active) ? true : false,
        id: quatro_id,
        availableFrom: _.toNumber(quatro_available_from_price),
        availableTo: _.toNumber(quatro_available_to_price)
      };
    }
  );

  export const getAhojSelector = () =>
  createSelector(
    [getApp],
    app => {
      const {
        ahoj_active = 0,
        ahoj_splatky_available_from_price = 0,
        ahoj_splatky_available_to_price = Infinity
      } = _.get(app, 'webInstance.globalSettings', null);
      return {
        active: ahoj_active,
        availableFrom: _.toNumber(ahoj_splatky_available_from_price),
        availableTo: _.toNumber(ahoj_splatky_available_to_price)
      };
    }
  );

export const getCardPaymentReturnUrlSelector = state =>
  createSelector(
    [getApp],
    app => {
      const domain = _.get(app, 'webInstance.domain', null);
      return domain +
        setRouteParams(
          'thanks',
          [],
          _.get(app, 'currentLanguage'),
          _.get(app, 'activeTheme', 'default')
        );
    }
  );

export const getWebInstanceEmailsSelector = () =>
  createSelector(
    [getApp],
    app => {
      return {
        adminEmail: _.get(app, 'webInstance.adminEmail', null),
        orderEmail: _.get(app, 'webInstance.orderEmail', null),
        forumEmail: _.get(app, 'webInstance.forumEmail', null)
      };
    }
  );

export const getWebInstanceSettingsSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.globalSettings', null)
  );
export const getWebInstanceSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance', null)
  );

export const getSideMenuIsOpensSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'sideMenuIsOpen', false)
  );

export const getUserSelectorSideMenuIsOpen = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'userSideMenuIsOpen', false)
  );

export const getFiltersMenuIsOpenSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'filtersIsOpen', false)
  );

export const getCurrencySymbolSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.currencySymbol', null)
  );

export const getActiveRouteWithParamsSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'activeRoute.withParams', null)
  );

export const getActiveThemeSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'activeTheme', 'default')
  );

export const getWebInstancePagesSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.pages', null)
  );

export const getWebInstanceEshopTypeSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.globalSettings.type_of_shop', ESHOP_TYPE_B2C)
  );

export const getIsB2BSelector = () => {
  return createSelector(
    [getApp, getUser],
    (app, user) =>
      _.toNumber(
        _.get(app, 'webInstance.globalSettings.type_of_shop', ESHOP_TYPE_B2C)
      ) === ESHOP_TYPE_B2B ||
      _.toNumber(
        _.get(app, 'webInstance.globalSettings.type_of_shop', ESHOP_TYPE_B2C)
      ) === ESHOP_TYPE_B2B_LOCKED ||
      _.toNumber(
        _.get(app, 'webInstance.globalSettings.type_of_shop', ESHOP_TYPE_B2C)
      ) === ESHOP_TYPE_MIXED && _.get(user, 'company.vatNumber', false)
  );
};

export const getIsB2BLockedSelector = () => {
  return createSelector(
    [getApp],
    app =>
      _.toNumber(
        _.get(app, 'webInstance.globalSettings.type_of_shop', ESHOP_TYPE_B2C)
      ) === ESHOP_TYPE_B2B_LOCKED
  );
};

export const isFacebookLoginEnabledSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.globalSettings.facebook_login_enabled', 0)
  );

export const isGoogleLoginEnabledSelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.globalSettings.google_login_enabled', 0)
  );

export const getGoogleApiKeySelector = () =>
  createSelector(
    [getApp],
    app => _.get(app, 'webInstance.globalSettings.google_api_key', '')
  );

export const getHeurekaSelector = () =>
  createSelector(
    [getApp],
    app => {
      const {
        heureka_remarketing_id = '',
        heureka_token_conversions = '',
        heureka_verified_icon = '',
        heureka_xml_review_feed_url = ''
      } = _.get(app, 'webInstance.globalSettings', null);
      return {
        remaketingId: heureka_remarketing_id,
        conversionsToken: heureka_token_conversions,
        verifiedIcon: heureka_verified_icon,
        xmlReviewFeedUrl: heureka_xml_review_feed_url
      };
    }
  );

export const getCartMaxPiecesCountSelector = () =>
  createSelector(
    [getApp],
    app => _.toInteger(_.get(app, 'webInstance.globalSettings.cart_max_pieces_count', 0))
  );
