import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '../styleguide/Button';
import { getActiveThemeSelector, getLanguageSelector } from '../../selectors/index';
import { setRouteParams } from '../../utils/index';
import ButtonOutline from '../styleguide/ButtonOutline';

class ButtonRouteLink extends Component {
  render() {
    const {
      children,
      activeTheme,
      route = '',
      params = {},
      currentLang = '',
      outline = false,
      toPathOptions = {},
      ...rest
    } = this.props;
    const newHref = setRouteParams(route, params, currentLang, activeTheme, toPathOptions);
    if (outline) {
      return (
        <ButtonOutline href={newHref} {...rest}>
          {children}
        </ButtonOutline>
      );
    }
    return (
      <Button href={newHref} {...rest}>
        {children}
      </Button>
    );
  }
}

const makeMapStateToProps = () => {
  const getLanguage = getLanguageSelector();
  const getActiveTheme = getActiveThemeSelector();
  return (state, props) => {
    return {
      currentLang: getLanguage(state, props),
      activeTheme: getActiveTheme(state, props)
    };
  };
};

export default connect(makeMapStateToProps)(ButtonRouteLink);
