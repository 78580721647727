import {
  SET_CART,
  SET_CART_DELIVERY,
  SET_CART_PAYMENT,
  UNSET_CART,
  SET_CART_LOADING,
  SET_CART_COUPON_RESULT
} from '../actions/CartActions';

const initialState = {};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_LOADING:
      return { ...state, ...{ isLoading: action.payload.isLoading } };
    case SET_CART_DELIVERY:
      return { ...state, ...{ delivery: action.payload.deliveryId } };
    case SET_CART_PAYMENT:
      return { ...state, ...{ payment: action.payload.paymentId } };
    case SET_CART_COUPON_RESULT:
      return { ...state, ...{ couponResult: action.payload.couponResult } };
    case SET_CART:
      return { ...state, ...{ ...action.payload.cart } };
    case UNSET_CART:
      return {};
    default:
      return state;
  }
};

export default CartReducer;
