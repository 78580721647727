import _ from 'lodash';
import Cookies from 'js-cookie';

import apiFront from 'common/utils/apiFront';
import { METHOD } from 'common/constants/HttpConstants';
import { message } from 'common/components/message/_default';
import { COOKIE_USER, COOKIE_USER_EXPIRES } from 'common/constants/UserConstants';
import { getUserSelector } from 'common/selectors/user';
import { setLoading } from './PageActions';

export const SET_USER = 'SET_USER';
export const SET_USER_COUNTRIES = 'SET_USER_COUNTRIES';
export const SET_USER_WISHLIST = 'SET_USER_WISHLIST';
export const SET_USER_WISHLIST_COUNT = 'SET_USER_WISHLIST_COUNT';

export const setUser = user => {
  return {
    type: SET_USER,
    payload: {
      user
    }
  };
};

export const setUserCountries = countries => {
  return {
    type: SET_USER_COUNTRIES,
    payload: {
      countries
    }
  };
};

export const setUserWishlistCount = count => {
  return { type: SET_USER_WISHLIST_COUNT, payload: { count } };
};

export const setUserWishlist = wishlist => {
  return { type: SET_USER_WISHLIST, payload: { wishlist } };
};

export const updateUser = values => async (dispatch, getState) => {
  const userAttrs = _.cloneDeep(values);
  const country = userAttrs.countryId;
  if (country && country.value) {
    userAttrs.countryId = _.toInteger(country.value);
    delete userAttrs.country;
  }
  const { user = {} } = getState();
  if (user) {
    _.each(userAttrs, (value, key) => {
      if (!_.isEmpty(value) && _.isString(value)) {
        userAttrs[key] = _.trim(value);
      }
    })
    if (_.get(userAttrs, 'firstname', '')) {
      _.set(userAttrs, 'name', _.get(userAttrs, 'firstname'));
    }
    const newUser = await dispatch(
      apiFront({
        endpoint: 'user',
        endpointParams: {
          userId: user.id
        },
        options: {
          data: {
            user: userAttrs
          }
        },
        method: METHOD.PUT
      })
    );
    if (!_.isEmpty(newUser)) {
      dispatch(setUser(newUser));
      const userString = JSON.stringify(newUser);
      Cookies.set(COOKIE_USER, userString, { expires: 1 });
      message.success('update user', 'information was succefuly updated!');
    } else {
      message.error('update user', 'unexpected error occured. Please try again later.');
    }
  }
};

export const addProductToWishlist = productId => async (dispatch, getState) => {
  const getUser = getUserSelector();
  const user = getUser(getState());
  if (!_.isEmpty(user)) {
    dispatch(setLoading(true));
    const response = await dispatch(
      apiFront({
        method: METHOD.POST,
        endpoint: 'wishlist',
        options: {
          data: {
            item: {
              productId
            }
          }
        },
        endpointParams: {
          userId: user.id
        },
        toastrParts: {
          title: 'wishlist'
        },
        returnResponseMap: {
          user: 'body.user'
        },
        showToastr: true
      })
    );
    const expires = _.get(user, 'expires', COOKIE_USER_EXPIRES);
    const updatedUser = _.get(response, 'user', null);
    if (!_.isEmpty(updatedUser)) {
      dispatch(setUser(updatedUser));
      const userString = JSON.stringify(updatedUser);
      Cookies.set(COOKIE_USER, userString, { expires });
      message.success('wishlist', 'product was added to wishlist!');
    }
    dispatch(setLoading(false));
  }
};

export const removeProductFromWishlist = wishlistItemId => async (dispatch, getState) => {
  const getUser = getUserSelector();
  const user = getUser(getState());
  if (!_.isEmpty(user)) {
    dispatch(setLoading(true));
    const response = await dispatch(
      apiFront({
        method: METHOD.DELETE,
        endpoint: 'wishlist_item_delete',
        endpointParams: {
          userId: user.id,
          wishlistItemId
        },
        toastrParts: {
          title: 'wishlist'
        },
        returnResponseMap: {
          wishlist: 'body.data',
          user: 'body.user'
        },
        showToastr: true
      })
    );
    const expires = _.get(user, 'expires', COOKIE_USER_EXPIRES);
    const updatedUser = _.get(response, 'user', null);
    const newWishlist = _.get(response, 'wishlist', null);
    if (!_.isEmpty(updatedUser)) {
      const userString = JSON.stringify(updatedUser);
      Cookies.set(COOKIE_USER, userString, { expires });
      const updatedUserWithWistlist = { ...updatedUser, ...{ wishlist: newWishlist } };
      dispatch(setUser(updatedUserWithWistlist));
      message.success('wishlist', 'product was removed from wishlist!');
    } else {
      message.error('wishlist', 'unexpected error occured. Please try again later.');
    }
    dispatch(setLoading(false));
  }
};
