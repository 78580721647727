import chroma from 'chroma-js';
import _ from 'lodash';

const BREAKPOINT_XS = 0;
const BREAKPOINT_SM = 480;
const BREAKPOINT_MD = 780;
const BREAKPOINT_LG = 1280;
const BREAKPOINT_XL = 1440;

const FONT_FAMILY = "'Roboto', sans-serif";
const FONT_FAMILY_HELVETICA = "'Helvetica Neue', Helvetica, sans-serif";

export const defaultColors = {
  //text
  text: '#444',
  textInverted: '#ffffff',
  opposite: '#ffffff',
  offwhite: '#efefef',

  // colors - main
  primary: '#66cdf7',
  secondary: '#535b6b',

  white: '#ffffff',
  black: '#111111',

  //colors - system
  danger: '#dc3545',
  warning: '#ffc107',
  info: '#17a2b8',
  success: '#28a745',
  disabled: '#cfcfcf',

  //colors
  navy: '#001f3f',
  blue: '#0074D9',
  aqua: '#7FDBFF',
  teal: '#39CCCC',
  olive: '#3D9970',
  green: '#2ECC40',
  lime: '#01FF70',
  yellow: '#FFDC00',
  orange: '#FF851B',
  red: '#FF4136',
  maroon: '#85144b',
  fuchsia: '#F012BE',
  purple: '#B10DC9',

  gray: '#aaa',
  dgray: '#999',
  xdray: '#222',
  lgray: '#c5c5c5',
  xlgray: '#cfcfcf',

  //social media / companies
  facebook: '#1877f2',
  google: '#db4437',
  twitter: '#1da1f2',
  instagram: '#c32aa3',
  grandus: '#267df4',
  forbestclients: 'red',

  //backgrounds
  background: '#ffffff',
  backgroundDarker: '#f9f9f9',
  backgroundSecondary: '#f9f9f9',
  backgroundInverted: '#222'
};

export const defaultThemeFormControl = {
  transition: '0.3s linear',
  state: {
    focus: originalColor => {
      return `border-color: ${originalColor};
        -webkit-box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        -moz-box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        outline: none !important;`;
    },
    hover: originalColor => {
      return `border-color: ${originalColor};
        outline: none !important;`;
    },
    active: originalColor => {
      return `border-color: ${originalColor};
        -webkit-box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        -moz-box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        box-shadow: 0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)};
        outline: none !important;`;
    },
    focusObject: originalColor => {
      return {
        borderColor: originalColor,
        boxShadow: `0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)}`,
        outline: 'none !important'
      }
    },
    hoverObject: originalColor => {
      return {
        borderColor: originalColor,
        outline: 'none !important'
      }
    },
    activeObject: originalColor => {
      return {
        borderColor: originalColor,
        boxShadow: `0px 0px 0px 4px ${chroma(originalColor).alpha(0.3)}`,
        outline: 'none !important'
      }
    }
  }
};

export const defaultThemeBorder = {
  basic: {
    radius: 0,
    color: '#ced4da',
    style: 'solid',
    width: '1px'
  },
  panel: {
    radius: '3px',
    color: '#ced4da',
    style: 'solid',
    width: '1px'
  },
  interactive: {
    radius: '4px',
    color: '#ced4da',
    style: 'solid',
    width: '1px'
  }
};

export const defaultThemeShadow = {
  basic: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)'
};

export default (colors = {}, importShadow = null, importBorder = null, importFormControl = null) => {

  const defaultShadow = importShadow === null ? defaultThemeShadow : importShadow;
  const defaultBorder = importBorder === null ? defaultThemeBorder : importBorder;
  const defaultFormControl = importFormControl === null ? defaultThemeFormControl : importFormControl;

  const finalColors = _.merge({}, defaultColors, colors);
  return {
    fontFamily: FONT_FAMILY,
    fallbackFontFamily: FONT_FAMILY_HELVETICA,
    borderRadius: defaultBorder.basic.radius,
    maxWidth: BREAKPOINT_XL,
    headerTopBarHeight: 35,
    // grid
    gutterWidth: 20,
    breakpoints: {
      xs: BREAKPOINT_XS,
      sm: BREAKPOINT_SM,
      md: BREAKPOINT_MD,
      lg: BREAKPOINT_LG,
      xl: BREAKPOINT_XL
    },
    containerWidth: {
      sm: BREAKPOINT_SM,
      md: BREAKPOINT_MD,
      lg: BREAKPOINT_LG,
      xl: BREAKPOINT_XL
    },
    // colors
    colors: finalColors,
    //form control states
    formControlState: {
      focusObject: defaultFormControl.state.focusObject(finalColors.primary),
      hoverObject: defaultFormControl.state.hoverObject(finalColors.primary),
      activeObject: defaultFormControl.state.activeObject(finalColors.primary)
    },
    // border / line
    border: {
      default: defaultBorder.basic,
      components: defaultBorder.interactive,
      panels: defaultBorder.panel
    },
    shadow: defaultShadow,
    // z-index
    zIndex: {
      overlay: 9000,
      modal: 8000,
      cartDropdown: 7500,
      megamenu: 7000,
      autoComplete: 6000,
      filters: 5000,
      dropdown: 4000,
      header: 10,
      sticker: 1
    },
    // header
    header: {
      height: '105px',
      background: finalColors.white
    },
    // topBar
    topBar: {
      background: finalColors.backgroundSecondary,
      height: '35px',
      borderBottom: 'none',
      link: {
        color: finalColors.white,
        hover: {
          color: finalColors.lgray
        },
        icon: {
          color: finalColors.white
        }
      },
      listItemSeparator: '1px solid rgba(255,255,255,.4)'
    },
    // button
    button: {
      borderRadius: defaultBorder.interactive.radius,
      transition: defaultFormControl.transition,
      enableDefaultShadow: true,
      textDecoration: 'none',
      height: {
        big: '52px',
        normal: '44px',
        small: '32px'
      },
      padding: {
        big: '0 1.6em',
        normal: '0 1.2em',
        small: '0 0.6em'
      },
      fontSize: {
        big: '1.1em',
        normal: '0.9em',
        small: '0.7em'
      },
      hover: {
        textDecoration: 'underline'
      }
    },
    // button outline
    buttonOutline: {
      borderRadius: defaultBorder.interactive.radius,
      border: defaultBorder.basic.width + ' ' + defaultBorder.basic.style,
      enableDefaultShadow: true,
      textDecoration: 'none',
      hover: {
        textDecoration: 'none'
      }
    },
    headline: {
      h1: {
        customStyle: `justify-content: center;`
      },
      h2: {
        customStyle: `justify-content: center;`
      },
      h3: {
        customStyle: `justify-content: center;`
      },
      h4: {
        customStyle: `justify-content: center;`
      }
    },
    // dropdown
    dropdown: {
      border:
        defaultBorder.basic.width +
        ' ' +
        defaultBorder.basic.style +
        ' ' +
        defaultBorder.basic.color,
      shadow: defaultShadow.basic,
      borderRadius: defaultBorder.interactive.radius
    },
    // input
    input: {
      transition: defaultFormControl.transition,
      state: {
        hover: defaultFormControl.state.hover(finalColors.primary),
        active: defaultFormControl.state.active(finalColors.primary),
        focus: defaultFormControl.state.focus(finalColors.primary)
      },
      padding: {
        small: '0.35em 0.8em',
        normal: '0.45em 0.8em',
        big: '0.65em 1.2em'
      },
      fontSize: {
        small: '0.85em',
        normal: '1em',
        big: '1.15em'
      },
      color: finalColors.text,
      border:
        defaultBorder.interactive.width + ' ' + defaultBorder.interactive.style,
      borderColor: `${defaultBorder.basic.color} !important`,
      borderRadius: defaultBorder.interactive.radius
    },
    // switcher
    switcher: {
      color: finalColors.text,
      border:
        defaultBorder.interactive.width + ' ' + defaultBorder.interactive.style,
      borderColor: `${defaultBorder.basic.color} !important`,
      borderRadius: defaultBorder.interactive.radius,
      hover: {
        background: finalColors.primary,
        color: finalColors.white
      }
    },
    //checkbox
    checkbox: {
      borderRadius: defaultBorder.interactive.radius,
      indicatorColor: finalColors.primary,
      transition: defaultFormControl.transition,
      state: {
        hover: defaultFormControl.state.active(finalColors.primary),
        active: defaultFormControl.state.active(finalColors.primary),
        focus: defaultFormControl.state.focus(finalColors.primary)
      }
    },
    //radio
    radio: {
      indicatorColor: finalColors.primary,
      transition: defaultFormControl.transition,
      state: {
        hover: defaultFormControl.state.active(finalColors.primary),
        active: defaultFormControl.state.active(finalColors.primary),
        focus: defaultFormControl.state.focus(finalColors.primary)
      }
    },
    //selectbox
    selectbox: {
      borderRadius: defaultBorder.interactive.radius,
      transition: defaultFormControl.transition,
      state: {
        hover: defaultFormControl.state.hover(finalColors.primary),
        active: defaultFormControl.state.active(finalColors.primary),
        focus: defaultFormControl.state.focus(finalColors.primary)
      },
      option: {
        active: {
          backgroundColor: `${chroma(finalColors.primary).alpha(0.1)}`
        }
      }
    },
    //searchbar
    searchBar: {
      height: '44px',
      borderRadius: defaultBorder.interactive.radius,
      boxShadowFocus: defaultShadow.basic,
      icon: {
        background: finalColors.backgroundDarker,
        color: finalColors.text,
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color,
        fontSize: '22px'
      },
      input: {
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color
      },
      button: {
        fontSize: '1em',
        fontWeight: 600,
        background: finalColors.primary,
        color: finalColors.white,
        hover: {
          background: chroma(finalColors.primary)
            .darken(0.5)
            .hex(),
          color: finalColors.white
        }
      }
    },
    //userDropdown
    userDropdown: {
      iconColor: finalColors.text,
      iconFontSize: '1.6em',
      textTop: {
        color: finalColors.text,
        fontSize: '0.9em',
        fontWeight: '400'
      },
      textBottom: {
        color: finalColors.text,
        fontSize: '0.7em',
        fontWeight: '400'
      },
      dropdown: {
        background: finalColors.background,
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color,
        boxShadow: defaultShadow.basic
      }
    },
    //cartButton
    cartButton: {
      border:
        defaultBorder.basic.width +
        ' ' +
        defaultBorder.basic.style +
        ' ' +
        defaultBorder.basic.color,
      background: finalColors.white,
      totalColor: finalColors.black,
      totalFontWeight: 'bold',
      totalFontFamily: FONT_FAMILY,
      borderRadius: defaultBorder.interactive.radius,
      bagSize: '45px',
      bagBackground: finalColors.backgroundDarker,
      bagColor: finalColors.text,
      bagIconSize: '25px',
      countBackground: finalColors.primary,
      countColor: finalColors.textInverted,
      dropdownBackground: finalColors.white,
      dropdownShadow: defaultShadow.basic,
      transition: defaultFormControl.transition,
      hover: defaultFormControl.state.active(finalColors.primary),
      customStyle: ''
    },
    //navigation
    pagenav: {
      mobile: {
        background: finalColors.backgroundDarker,
        shadow: defaultShadow.basic
      },
      background: finalColors.white,
      shadow: 'none',
      borderTop:
        defaultBorder.basic.width +
        ' ' +
        defaultBorder.basic.style +
        ' ' +
        defaultBorder.basic.color,
      borderBottom:
        defaultBorder.basic.width +
        ' ' +
        defaultBorder.basic.style +
        ' ' +
        defaultBorder.basic.color,
      item: {
        fontSize: '0.8em',
        height: '50px',
        color: finalColors.text,
        hover: {
          color: finalColors.primary,
          background: finalColors.backgroundDarker
        },
        categoriesColor: finalColors.white,
        categoriesBackground: finalColors.primary,
        categoriesHoverColor: finalColors.white,
        categoriesHoverBackground: chroma(finalColors.primary)
          .darken(0.5)
          .hex(),

        linkTitle: {
          color: finalColors.secondary,
          fontSize: '1rem'
        }
      },
      dropdown: {
        background: finalColors.background,
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color,
        shadow: defaultShadow.basic
      },
      btnShowSideMenu: {
        color: finalColors.black,
        background: 'transparent',
        hover: {
          color: finalColors.black,
          background: 'transparent'
        }
      }
    },
    // panel
    panel: {
      background: finalColors.background,
      border:
        defaultBorder.basic.width +
        ' ' +
        defaultBorder.basic.style +
        ' ' +
        defaultBorder.basic.color,
      borderRadius: defaultBorder.basic.radius,
      boxShadow: defaultShadow.basic,
      margin: '0 0 20px 0',
      padding: '20px',
      title: {
        fontSize: '1em',
        color: finalColors.text,
        margin: '0 0 10px 0'
      }
    },
    // pagination
    pagination: {
      basic: {
        radius: defaultBorder.basic.radius,
        color: finalColors.text,
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color,
        background: finalColors.background
      },
      hover: {
        radius: defaultBorder.basic.radius,
        color: finalColors.text,
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color,
        background: finalColors.backgroundDarker
      },
      active: {
        radius: defaultBorder.basic.radius,
        color: finalColors.primary,
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          finalColors.primary,
        background: finalColors.background
      },
      activeHover: {
        radius: defaultBorder.basic.radius,
        color: finalColors.primary,
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          finalColors.primary,
        background: finalColors.backgroundDarker
      }
    },
    // banners
    banner: {
      homepage: {
        padding: '20px 0',
        margin: '0',
        background: finalColors.backgroundDarker
      }
    },
    // page
    page: {
      background: finalColors.background,
      backgroundSecondary: finalColors.backgroundDarker,
      padding: '20px 0',
      overlay: {
        position: 'fixed',
        background: 'rgba(255, 255, 255, 0.8)'
      },
      empty: {
        icon: {
          fontSize: '100px',
          color: finalColors.text
        },
        text: {
          margin: '30px 0',
          fontSize: '2em',
          fontWeight: 'normal'
        }
      }
    },
    // cart
    cart: {
      background: finalColors.backgroundDarker,
      title: {
        fontSize: '1em',
        color: finalColors.text
      },
      section: {
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color,
        background: finalColors.background,
        borderRadius: defaultBorder.basic.radius,
        boxShadow: defaultShadow.basic
      }
    },
    // productCard
    productCard: {
      borderRadius: defaultBorder.panel.radius,
      border:
        defaultBorder.basic.width +
        ' ' +
        defaultBorder.basic.style +
        ' ' +
        defaultBorder.basic.color,
      background: finalColors.white,
      transition: defaultFormControl.transition,
      width: '100%',
      name: {
        color: finalColors.text
      },
      brand: {
        color: defaultBorder.basic.color,
        borderColor: defaultBorder.basic.color
      },
      hover: {
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color,
        boxShadow: defaultShadow.basic
      },
      image: {
        background: 'transparent'
      }
    },
    // labels
    labels: {
      new: {
        background: finalColors.purple
      },
      favourite: {
        background: finalColors.primary
      },
      status: {
        background: finalColors.orange
      },
      discount: {
        background: finalColors.red
      }
    },
    // price
    price: {
      finalPrice: {
        color: finalColors.text,
        fontFamily: FONT_FAMILY
      },
      finalPriceWithoutVAT: {
        color: finalColors.gray,
        fontFamily: FONT_FAMILY
      },
      discount: {
        color: finalColors.red,
        fontFamily: FONT_FAMILY
      },
      price: {
        fontFamily: FONT_FAMILY
      }
    },
    // rating
    rating: {
      fullStarColor: finalColors.yellow
    },
    // carousels
    newArrivalsCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 1,
      initialSlide: 0,
      dots: true,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_XL
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    },
    favoritesCarouselSettings: {
      slidesToShow: 6,
      slidesToScroll: 1,
      initialSlide: 0,
      dots: true,
      infinite: false,
      responsive: [
        {
          breakpoint: BREAKPOINT_XL
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    },
    crosssaleCarouselSettings: {
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      dots: false,
      infinite: false, // NEPOUZIVAT true BUG v react-slick https://github.com/akiran/react-slick/issues/1171
      responsive: [
        {
          breakpoint: BREAKPOINT_XL,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
    },
    relatedProductsCarouselSettings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      dots: false,
      infinite: false, // NEPOUZIVAT true BUG v react-slick https://github.com/akiran/react-slick/issues/1171
      autoplay: true,
      responsive: [
        {
          breakpoint: BREAKPOINT_XL,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    },
    testimonialCarouselSettings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      dots: false,
      infinite: false,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: BREAKPOINT_XL,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_LG,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: BREAKPOINT_MD,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false
          }
        },
        {
          breakpoint: BREAKPOINT_SM,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    },
    carousels: {
      arrows: {
        color: finalColors.text,
        border: 'none',
        background: 'transparent',
        width: '36px',
        height: '36px',
        fontSize: '22px',
        hover: {
          color: finalColors.primary,
          border: 'none',
          background: 'transparent'
        },
        md: {
          color: finalColors.text,
          border: 'none',
          background: 'transparent',
          width: '36px',
          height: '36px',
          fontSize: '22px',
          hover: {
            color: finalColors.primary,
            border: 'none',
            background: 'transparent'
          }
        },
        customStyle: ``
      },
      dots: {
        width: '5px',
        height: '5px',
        background: defaultBorder.basic.color,
        borderRadius: '100%',
        isActive: {
          background: finalColors.primary
        }
      }
    },
    productCarrousels: {
      marginBottom: '90px',
      arrows: {
        color: finalColors.text,
        border: 'none',
        background: 'transparent',
        margin: '-20px 0 0 0',
        width: '40px',
        height: '40px',
        fontSize: '36px',
        borderRadius: defaultBorder.basic.radius,
        top: '50%',
        hover: {
          color: finalColors.primary,
          border: 'none',
          background: 'transparent'
        },
        md: {
          color: finalColors.text,
          border:
            defaultBorder.basic.width +
            ' ' +
            defaultBorder.basic.style +
            ' ' +
            defaultBorder.basic.color,
          background: finalColors.white,
          margin: '0',
          width: '36px',
          height: '36px',
          fontSize: '22px',
          borderRadius: '0',
          top: '-52px',
          hover: {
            color: finalColors.primary,
            border:
              defaultBorder.basic.width +
              ' ' +
              defaultBorder.basic.style +
              ' ' +
              defaultBorder.basic.color,
            background: finalColors.white
          }
        }
      },
      dots: {
        width: '10px',
        height: '10px',
        background: defaultBorder.basic.color,
        borderRadius: '100%',
        isActive: {
          background: 'transparent'
        }
      }
    },
    // cart
    cart: {
      background: finalColors.backgroundDarker,
      title: {
        fontSize: '1.1em',
        fontWeight: 400,
        color: '#969999'
      },
      section: {
        border:
          defaultBorder.basic.width +
          ' ' +
          defaultBorder.basic.style +
          ' ' +
          defaultBorder.basic.color,
        background: finalColors.white
      }
    },
    //section
    section: {
      background: finalColors.white,
      backgroundSecond: finalColors.backgroundDarker,
      title: {
        fontSize: '1.375em',
        fontWeight: 400,
        color: '#969999'
      },
      subtitle: {
        fontSize: '0.9em',
        color: '#c7c7c7'
      }
    },
    //label
    label: {
      color: finalColors.text
    },
    rangeCarousel: {
      rail: {
        height: '7px'
      },
      track: {
        backgroundColor: finalColors.white,
        height: '7px'
      },
      handle: {
        backgroundColor: finalColors.secondary,
        width: '17px',
        height: '17px'
      },
      mobile: {
        rail: {
          height: '7px'
        },
        track: {
          backgroundColor: finalColors.gray,
          height: '7px'
        },
        handle: {
          backgroundColor: finalColors.secondary,
          width: '17px',
          height: '17px'
        }
      }
    },
    footer: {
      background: finalColors.white,
      borderTop:
        defaultBorder.basic.width +
        ' ' +
        defaultBorder.basic.style +
        ' ' +
        defaultBorder.basic.color,
      title: {
        fontSize: '1em',
        color: '#737373'
      },
      text: {
        color: '#b4b4b4'
      },
      bottomBar: {
        background: finalColors.backgroundInverted,
        color: finalColors.white
      },
      link: {
        color: finalColors.primary,
        hover: {
          color: chroma(finalColors.primary)
            .darken(0.5)
            .hex()
        }
      }
    },
    //filters
    filters: {
      padding: '20px',
      paddingMD: '5px'
    },
    // active filter
    activeFilter: {
      margin: '0 10px 10px 0',
      border:
        defaultBorder.basic.width +
        ' ' +
        defaultBorder.basic.style +
        ' ' +
        defaultBorder.basic.color,
      remove: {
        color: finalColors.text,
        background: finalColors.backgroundDarker,
        hover: {
          color: finalColors.textInverted,
          background: finalColors.danger
        }
      }
    },
    // breadcrumbs
    breadcrumbs: {
      listItem: {
        margin: '0 8px 0 0'
      },
      separator: {
        fontSize: '0.8em',
        color: finalColors.lgray,
        margin: '0 0 0 8px'
      },
      text: {
        fontSize: '0.7em',
        fontWeight: 'normal',
        color: finalColors.xlgray
      }
    },
    // gallery
    gallery: {
      width: {
        xs: '100%',
        md: '100%'
      },
      padding: '0 25px 0 0',
      border: {
        color: 'transparent',
        style: 'solid',
        width: '0'
      },
      mainImage: {
        background: 'transparent',
        border: 'none',
        padding: '0',
        border: {}
      },
      thumbnail: {
        margin: '4px',
        background: 'transparent',
        border: {
          color: 'transparent',
          style: 'solid',
          width: '0'
        }
      }
    },
    // numstepper
    numstepper: {
      height: '42px',
      backgroundColor: finalColors.background,
      prev: {
        width: '28px',
        fontSize: '14px',
        border: {
          radius: 0,
          color: defaultBorder.basic.color,
          style: defaultBorder.basic.style,
          width: defaultBorder.basic.width
        }
      },
      next: {
        width: '28px',
        fontSize: '14px',
        border: {
          radius: 0,
          color: defaultBorder.basic.color,
          style: defaultBorder.basic.style,
          width: defaultBorder.basic.width
        }
      },
      input: {
        background: finalColors.backgroundDarker,
        width: '45px',
        color: finalColors.text,
        border: {
          radius: 0,
          width: 0
        }
      }
    },
    //toastr
    toastr: {
      position: 'top-center',
      transitionIn: 'bounceInDown',
      transitionOut: 'fadeOut',
      marginTop: '0',
      opacity: 1,
      borderRadius: defaultBorder.interactive.radius,
      holder: {
        color: finalColors.lgray
      },
      title: {
        color: finalColors.text,
        fontSize: '1.2em',
        fontWeight: 'normal'
      },
      text: {
        colorDefault: '',
        fontSize: '0.8em',
        fontWeight: 'lighter',
        textColor: finalColors.lgray
      },
      progressbar: {
        height: '1px'
      },
      success: {
        background: 'white',
        boxShadow: defaultShadow.basic,
        border: 'none',
        progressbarColor: finalColors.success,
        iconColor: finalColors.success,
        titleColor: finalColors.gray,
        textColor: finalColors.success,
        closeButtonColor: finalColors.gray
      },
      error: {
        background: 'white',
        boxShadow: defaultShadow.basic,
        border: 'none',
        progressbarColor: finalColors.danger,
        iconColor: finalColors.danger,
        titleColor: finalColors.gray,
        textColor: finalColors.danger,
        closeButtonColor: finalColors.gray
      },
      info: {
        background: 'white',
        boxShadow: defaultShadow.basic,
        border: 'none',
        progressbarColor: finalColors.info,
        iconColor: finalColors.info,
        titleColor: finalColors.gray,
        textColor: finalColors.info,
        closeButtonColor: finalColors.gray
      }
    },
    cookieConsent: {
      body: {
        width: 'auto',
        maxWidth: '500px',
        display: 'block',
        left: 'auto',
        right: '0',
        margin: '20px',
        boxSizing: 'border-box',
        background: finalColors.backgroundSecondary,
        color: finalColors.gray,
        fontSize: '0.75em',
        lineHeight: '1.4em',
        textAlign: 'justify',
        boxShadow: defaultShadow.basic,
        borderRadius: defaultBorder.interactive.radius,
        border:
          defaultBorder.interactive.width +
          ' ' +
          defaultBorder.interactive.style +
          ' ' +
          defaultBorder.interactive.color
      }
    },
    blocks: {
      functional: {
        cartButton: {

        }
      }
    }
  };
};
