import { createSelector } from 'reselect';
import _ from 'lodash';

const getCompare = state => {
  const { compare = {} } = state;
  return compare;
};

export const getProductIdsToCompareSelector = () =>
  createSelector(
    [getCompare],
    compare => _.get(compare, 'productIdsToCompare', null)
  );

export const getProductsToCompareSelector = () =>
  createSelector(
    [getCompare],
    compare => _.get(compare, 'productsToCompare', null)
  );